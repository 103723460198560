const appMessages = {
  LogInSucessMessage: "Successfully logged in",
  STRONG_PASSWORD_HINT:
    "Password is Weak! (Hint: yourPassword@123 (Minimum 8 Characters. (Numbers,Upper Case, Lower Case and Special Characters, Atleas One Each)))",
  OTHER_MESSAGE: "",
  LogInInutsError: "Please Enter Valid Email/Phone & Password",
  notificationTitle: "Notification Disclaimer",
  notificationMsg:
    "If you disable notifications, you will no longer receive order updates or any other alerts. Are you sure you want to proceed?",
  subcribtionDeleteTitle: "Unsubscribe?",
  subscribtionDeleteContent:
    "Are you sure you want to cancel your subscription?",
  depositAddressError: "Please fill fields to proceed",
  depositRefundToast:"You do not have enough products to proceed with the refund",
  depositReturnToast:"You Don't have enough products to return",
  depositReplaceToast:"You Don't have enough products to replace",
  upiIdErrorMessage:"Please verify your upi id to proceed"
};
export default appMessages;
