/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Child Component for check out page
 *
 */

import React from "react";

import BookingAddressCard from "../../Components/Cards/SkuCards/BookingAddressCard";
import BookingSelectDateCard from "../../Components/Cards/SkuCards/BookingSelectDate";
import SkuOrderDetailsCard from "../../Components/Cards/SkuCards/SkuOrderDetailsCard";
import PromoSelectionCard from "../../Components/Cards/SkuCards/PromoSelectionCard";
import SpecialRequestCard from "../../Components/Cards/SkuCards/SpecialRequestCard";
import { useLocation } from "react-router-dom";
import SubscriptionSchedule from "../../Components/Cards/SkuCards/SubscriptionDateCard";
import PriceBreakUpCardSkuWeb from "../../Components/Cards/SkuCards/PriceBreakUpCardWeb";
import WeeklyAndMonthlyPriceCard from "../../Components/Cards/SkuCards/weeklyAndMonthlyPayCard";
import DateAndTimeSelectionCard from "../../Components/DialogBoxes/SkuDialogs/dateSelectionDialog";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockedDateAction,
  SelectedProductaction,
} from "../../Redux/actions/skuBookingAction";
import InformationDialog from "../../Components/DialogBoxes/info/infoDialogBox";
import OTPLessDisclaimer from "../../Components/DialogBoxes/SkuDialogs/Booking/OTPLessDisclaimer";
import appColors from "../../Assets/AppTheme/appColors";
import {
  GetSubscriptinOrderDetailsSku,
  StopPauseSubscriptionOrder,
} from "../../Redux/actions/skuSubscribeAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SubScriptionPromoSelectionCard from "../../Components/Cards/SkuCards/subscribePromoCard";
import { ResetUpdateOrderError } from "../../Redux/actions/checkOutAction";
import DeleteProductExitBooking from "../../Components/DialogBoxes/info/deleteProduct";
import CustomConsole from "../../coustomConsole";
import NotificationInfoDialog from "../../Components/DialogBoxes/info/notificationDialog";
import appMessages from "../../Assets/AppMeta/appMessages";
import { Button, Grid } from "@mui/material";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import appTheme from "../../Assets/AppTheme/appTheme";
export default function CheckOutOverView({
  primaryAddress,
  setchooseAddressDialog,
  products,
  setProducts,
  currentDate,
  setCurrentDate,
  promoCode,
  setpromoCode,
  setEnteredPayAmount,
  minimumWalletAmount,
  setSubscribeObject,
  samedayDelivery,
  setSameDayDelivery,
  deliveryCharges,
  invalidProducts,
  setUpdatePaymentClick
}) {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const pageName = new URLSearchParams(search).get("pageName");
  const dispatch = useDispatch();
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  const [openOtpDialog, setOpenOtpDialog] = React.useState(false);
  const SkuSelectedProducts = useSelector((state) => state.skuBookDetails);
  const appMeta = useSelector((state) => state.appMetaDataDetails);
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const updateError = useSelector((state) => state.checkOutDetails);
  const [subscribeDate, setSubscribeDate] = React.useState(false);
  const [otpLessDiss, setOtpLessDiss] = React.useState("");
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [specialReq, setSpecialReq] = React.useState(products?.is_otp_less);
  const [deliveryType, setDeliveryType] = React.useState();
  const [openInstaAlertDialog, setOpenInstaAlertDialog] = React.useState(false);
  const [openOtpFlag, setOpenOtpFlag] = React.useState(false);
  const [calenderOpen, setcalenderOpen] = React.useState(false);
  const [toastFlag, setToastFlag] = React.useState(false);
  const [toastMsg, setTostMsg] = React.useState("");
  const [openExitDialog, setOpenExitDialog] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [openDeleteInfoDialog, setOpenDeleteInfoDialog] = React.useState(false);
  const [infoDialogText, setInfoDialogText] = React.useState("");
  const [subscribeData, setSubscribeData] = React.useState({});
  const [blockedDates, setBlockedDates] = React.useState([]);
  const [extraCharges, setExtraCharges] = React.useState(0);
  // To Control the Page Heading on Click of Back & froward Button
  React.useLayoutEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    const name = new URLSearchParams(search).get("pageName");
    if (
      name === "subscribe" ||
      (pageName && pageName.includes("editSubscribe"))
    ) {
      setSubscribeDate(false);
    } else {
      setSubscribeDate(true);
    }
  }, [pathname, search]);

  //for updating product details
  React.useEffect(() => {
    if (
      updateError?.updateError === false &&
      checkOutDetails !== undefined &&
      checkOutDetails?.valid_products?.length > 0
    ) {
      // Your product details logic from the reducer
      const updatedValidProducts = (checkOutDetails?.valid_products || [])
        .map((item) => {
          // Check if quantity is defined and has changed
          if (
            (item?.quantity !== undefined &&
              item?.quantity.existing_max_dist_stock >=
                item?.quantity.selected) ||
            (item?.quantity !== undefined &&
              pageName &&
              pageName === "subscribe") ||
            (item?.quantity !== undefined &&
              pageName &&
              pageName.includes("editSubscribe"))
          ) {
            return {
              quantity: item?.quantity.selected || 0,
              return: item?.return?.is_valid ? item?.return.selected || 0 : 0,
              sku_qty: item?.sku_qty || 0,
              booking_type: item?.booking_type || 0,
              capacity: item?.capacity,
              material: item?.material,
            };
          }
          return undefined; // Return undefined for items that don't meet the condition
        })
        .filter(Boolean); // Filter out undefined items

      const updatedInvalidProducts = (
        checkOutDetails?.invalid_products || []
      ).map((item) => {
        return {
          quantity: item?.quantity || 0,
          return: item?.return || 0,
          sku_qty: item?.sku_qty || 0,
          booking_type: item?.booking_type || 1,
          capacity: item?.capacity,
          material: item?.material,
        };
      });

      CustomConsole(updatedValidProducts);
      CustomConsole(updatedInvalidProducts);
      setProducts((prevProducts) => ({
        ...prevProducts,
        products: [...updatedValidProducts, ...updatedInvalidProducts],
      }));
      dispatch(ResetUpdateOrderError());
    }
  }, [checkOutDetails, updateError?.updateError]);

  //function for updating product details
  React.useEffect(() => {
    if (
      products?.products?.length > 0 &&
      JSON.stringify(products) !==
        JSON.stringify(SkuSelectedProducts.selectedProduct)
    ) {
      dispatch(SelectedProductaction(products));
    }
  }, [products]);

  React.useEffect(() => {
    const currentDateMoment = moment(currentDate);
    const formattedDate = moment(products.delivery_date, "YYYY-MM-DD");
    if (!formattedDate.isSame(currentDateMoment, "day")) {
      setProducts((prev) => ({
        ...prev,
        delivery_date: currentDateMoment.format("YYYY-MM-DD"),
      }));
    }
  }, [currentDate]);

  //For opening info dialog box
  const handelInfoIcon = (text) => {
    setOpenInfoDialog(true);
    setInfoDialogText(text);
  };

  //useEffect for getting otp less disclimar text details
  React.useEffect(() => {
    let otpless = appMeta?.appMetaData?.find(
      (item) => item.key === "otp_less_delivery_disclaimer"
    );
    //  CustomConsole(otpless.value[0].v);
    setOtpLessDiss(otpless?.value[0]?.v);
  }, [appMeta]);

  //For handling OTP and OTP less delivery
  const handelOtpDelivery = (e) => {
    setSpecialReq(e.target.checked);
    if (e.target.checked) {
      setOpenOtpDialog(true);
    } else {
      setProducts((prev) => ({
        ...prev,
        is_otp_less: e.target.checked,
      }));
    }
  };

  //For handling text in Otp less Disclimer
  const handelOtpDelivery1 = (data) => {
    CustomConsole(data);
    setProducts((prev) => ({
      ...prev,
      is_otp_less: data,
    }));
  };

  // //function for updating product details when date changes
  React.useEffect(() => {
    if (
      promoCode !== products.coupon_code &&
      pageName &&
      !pageName.includes("editSubscribe") &&
      pageName !== "subscribe"
    ) {
      setProducts((prev) => ({
        ...prev,
        coupon_code: promoCode,
        is_auto_apply_coupon: false,
      }));
    }
  }, [promoCode]);

  //function for updating Address details when Address changes
  React.useEffect(() => {
    if (products.address_id !== SkuSelectedProducts.selectedAddressId) {
      setProducts((prev) => ({
        ...prev,
        address_id: SkuSelectedProducts.selectedAddressId,
      }));
    }
  }, [SkuSelectedProducts.selectedAddressId]);

  //function to delete product
  const handelDeleteProduct = (data) => {
    CustomConsole(data);
    CustomConsole(products);
    const capacity = data.capacity;
    const material = data.material;
    const productIndex = products?.products?.findIndex(
      (currentProduct) =>
        currentProduct.capacity === capacity &&
        currentProduct.material === material
    );
    CustomConsole(`Product Index : ${productIndex}`);
    if (productIndex !== -1 && products?.products?.length > 1) {
      // Ensure the product index is found
      let deletedProduct = [...products.products]; // Create a copy of the products array
      deletedProduct.splice(productIndex, 1); // Remove the product at the found inde
      CustomConsole(deletedProduct);
      setProducts((prev) => ({
        ...prev,
        products: deletedProduct,
      }));
    } else if (products?.products?.length === 1) {
      setOpenExitDialog(true);
    }
  };

  //function for updating subscription
  const handelUpdateSubscribe = () => {
    if (subscribeData?.subscription_details?.is_pause === false) {
      const reqObj = {
        subscription_update_state: 200,
        subscription_ids: [
          subscribeData?.subscription_details?.subscription_id,
        ],
      };
      dispatch(StopPauseSubscriptionOrder(reqObj));
    } else {
      const reqObj = {
        subscription_update_state: 100,
        subscription_ids: [
          subscribeData?.subscription_details?.subscription_id,
        ],
      };
      dispatch(StopPauseSubscriptionOrder(reqObj));
    }
  };

  //function to get filtered data
  React.useEffect(() => {
    const subscribeNoMatch = pageName ? pageName.match(/\d+/) : null;
    if (subscribeNoMatch) {
      const subscribeNo = subscribeNoMatch[0];
      CustomConsole(subscribeNo);
      let filteredData =
        skuSubscribeOrderDetails?.subscribeOrderDetails?.filter((item) => {
          return item.subscription_details.subscription_id == subscribeNo;
        });
      if (filteredData && filteredData.length > 0) {
        setSubscribeData(filteredData[0]);
      }
    }
  }, [pageName, skuSubscribeOrderDetails?.subscribeOrderDetails]);

  //function for updating subscription
  const handelStopSubscribe = () => {
    const reqObj = {
      subscription_update_state: 300,
      subscription_ids: [subscribeData?.subscription_details?.subscription_id],
    };
    dispatch(StopPauseSubscriptionOrder(reqObj));
    CustomConsole(reqObj);
    setOpenDeleteInfoDialog(false);
  };

  // //function for updating product details when date changes
  React.useEffect(() => {
    if (
      pageName &&
      pageName.includes("editSubscribe") &&
      skuSubscribeOrderDetails?.subscribeOrderDetails?.length === 0
    ) {
      dispatch(GetSubscriptinOrderDetailsSku());
    }
  }, []);

  //useEffect for redirecting if user updates subscription
  React.useEffect(() => {
    if (
      !skuSubscribeOrderDetails?.updateOrderError &&
      pageName &&
      pageName.includes("editSubscribe")
    ) {
      skuSubscribeOrderDetails.updateOrderError = true;
      history.push("/dashboard/subscribe");
    }
  }, [skuSubscribeOrderDetails?.updateOrderError]);

  //useEffect for handling isToday variable as true or false
  React.useEffect(() => {
    const isToday = moment(currentDate).isSame(moment(), "day");
    if (isToday) {
      setSameDayDelivery(isToday);
      setDeliveryType(0);
    } else {
      setSameDayDelivery(isToday);
      setDeliveryType(1);
    }
  }, [currentDate]);

  //useEffect to get blocked dates and block booking
  React.useEffect(() => {
    let validDates = checkOutDetails?.valid_dates?.valid_dates;
    CustomConsole(checkOutDetails+"checkoutdetails");

    if (
      validDates !== undefined &&
      validDates.length > 0 &&
      pageName === "booking"
    ) {
      setBlockedDates(validDates);
      // Function to generate an array of dates for the next 7 days
      const getNext7Days = () => {
        const dates = [];
        let currentDate = moment();
        for (let i = 0; i < 7; i++) {
          dates.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "days");
        }
        return dates;
      };
      // Get the next 7 days
      const next7Days = getNext7Days();
      // Filter out the dates that are not in the valid dates array
      const invalidDates = next7Days.filter(
        (date) => !validDates.includes(date)
      );
      dispatch(BlockedDateAction(invalidDates));
    } else if (
      checkOutDetails?.valid_dates?.valid_dates.length === 0 &&
      pageName === "booking"
    ) {
      setCurrentDate("")
      dispatch(
        ToastMsgCotainer({
          error: true,
          containerFlag: true,
          msg: checkOutDetails?.valid_dates?.msg,
        })
      );
    }
  }, [checkOutDetails]);

  //useeffect for getting extra charges per product
  React.useEffect(() => {
    if (checkOutDetails?.insta_charges_per_product !== undefined) {
      setExtraCharges(checkOutDetails?.insta_charges_per_product);
    }
  }, [checkOutDetails]);
  //function to handel on off notification
  const handelInfoDialogClose = (flag) => {
    setOpenDeleteInfoDialog(flag);
  };
  return (
    <>
      <Grid container spacing={2}>
        {pageName && pageName.includes("editSubscribe") ? (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              size="small"
              variant="outlined"
              sx={{
                border: `1.5px solid ${appColors.walletCard}`,
                color: appColors.secondaryText,
                fontSize: appTheme.fontSizeSmall,
              }}
              onClick={handelUpdateSubscribe}
            >
              {subscribeData?.subscription_details?.is_pause === false
                ? "Pause Subscription"
                : "Resume Subscription"}
            </Button>
            <Button
              size="small"
              variant="outlined"
              sx={{
                border: `1.5px solid ${appColors.walletCard}`,
                color: appColors.secondaryText,
                fontSize: appTheme.fontSizeSmall,
              }}
              onClick={() => setOpenDeleteInfoDialog(true)}
            >
              Stop Subscription
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12} md={12}>
          <Grid
            container
            justify="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <BookingAddressCard
                primaryAddress={primaryAddress}
                setchooseAddressDialog={setchooseAddressDialog}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {subscribeDate ? (
                <BookingSelectDateCard
                  samedayDelivery={samedayDelivery}
                  currentDate={currentDate}
                  setcalenderOpen={setcalenderOpen}
                  deliveryCharges={deliveryCharges}
                  extraCharges={extraCharges}
                />
              ) : (
                <SubscriptionSchedule
                  setCurrentDate={setCurrentDate}
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <SkuOrderDetailsCard
                handelDeleteProduct={handelDeleteProduct}
                invalidProducts={invalidProducts}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {subscribeDate ? (
                    <PromoSelectionCard
                      promoCode={promoCode}
                      setpromoCode={setpromoCode}
                      setProducts={setProducts}
                      products={products}
                    />
                  ) : (
                    <SubScriptionPromoSelectionCard
                      setProducts={setProducts}
                      setSubscribeObject={setSubscribeObject}
                      setpromoCode={setpromoCode}
                    />
                  )}
                </Grid>
                <Grid item>
                  <SpecialRequestCard
                    handelOtpDelivery={handelOtpDelivery}
                    specialReq={specialReq}
                  />
                </Grid>
                {!subscribeDate && (
                  <Grid item>
                    <WeeklyAndMonthlyPriceCard
                      setEnteredPayAmount={setEnteredPayAmount}
                      minimumWalletAmount={minimumWalletAmount}
                      setUpdatePaymentClick={setUpdatePaymentClick}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {calenderOpen && (
        <DateAndTimeSelectionCard
          setCurrentDate={setCurrentDate}
          currentDate={currentDate}
          setSameDayDelivery={setSameDayDelivery}
          setDeliveryType={setDeliveryType}
          setOpenInstaAlertDialog={setOpenInstaAlertDialog}
          deliveryType={deliveryType}
          samedayDelivery={samedayDelivery}
          openInstaAlertDialog={openInstaAlertDialog}
          openOtpFlag={openOtpFlag}
          setOpenOtpFlag={setOpenOtpFlag}
          calenderOpen={calenderOpen}
          setcalenderOpen={setcalenderOpen}
          setToastFlag={setToastFlag}
          setTostMsg={setTostMsg}
          extraCharges={extraCharges}
        />
      )}
      <InformationDialog
        setOpenInfoDialog={setOpenInfoDialog}
        otpLessDiss={otpLessDiss}
        openInfoDialog={openInfoDialog}
        infoDialogText={infoDialogText}
        setInfoDialogText={setInfoDialogText}
      />
      <OTPLessDisclaimer
        otpLessDiss={otpLessDiss}
        openOtpDialog={openOtpDialog}
        setOpenOtpDialog={setOpenOtpDialog}
        setSpecialReq={setSpecialReq}
        handelOtpDelivery={handelOtpDelivery1}
      />
      <DeleteProductExitBooking
        open={openExitDialog}
        onClose={setOpenExitDialog}
      />
      <NotificationInfoDialog
        setOpenInfoDialog={setOpenDeleteInfoDialog}
        openInfoDialog={openDeleteInfoDialog}
        infoDialogTextHeading={appMessages.subcribtionDeleteTitle}
        infoDialogTextMain={appMessages.subscribtionDeleteContent}
        handelProceedAction={handelStopSubscribe}
        handelInfoDialogClose={handelInfoDialogClose}
      />
    </>
  );
}
