/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios";
import actionType from "./../actions/actionTypes";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";

export const GetDepositHistoryAction = (req) => {
  let api = useAxios();
  return async (dispatch) => {
    var queryParams = `?page_number=${req.page_number}&date=${req.date}&payment_status=${req.payment_status}`;
    try {
      let response = await api.get(`/OM/getUserDepositHistory` + queryParams);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DEPOSIT_DETAILS,
          payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const GetUserStocksAction = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/getUserStockDetails`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_STOCK,
          payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
