/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import WaterDropApplyButton from "../../../SkuButtons/waterDropButton";
import { Box } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useDispatch } from "react-redux";
import { EditOrderActionSku } from "../../../../Redux/actions/skuOrderPlacingAction";
import ToastMsgCotainer from "../../../ToastMsg/toastMsg";
import { ToastMsgState } from "../../../../Redux/actions/toastMsg";
import { toast } from "react-toastify";
import { GetWalletDetails } from "../../../../Redux/actions/paymentMethodAction";
import appTheme from "../../../../Assets/AppTheme/appTheme";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditOrderDialogSku({
  open,
  onClose,
  editOrderData,
  editOrderType,
  orderId,
  ApiCall,
  setOpenOrderDetailsDialog,
}) {
  const [orderQty, setOrderQty] = React.useState(0);
  const [returnQty, setReturnQty] = React.useState(0);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    onClose();
  };
  const handleClose = () => {
    onClose();
  };

  //useeffect for setting orderqty and returnqty
  React.useEffect(() => {
    if (editOrderType === "20Liters") {
      setOrderQty(editOrderData?.twentyltr_capacity_qty);

      setReturnQty(editOrderData?.empty_container_qty);
      setCapacity(editOrderData?.selected_container_capacity);
      setMaterial(editOrderData?.selected_container_material);
    } else if (editOrderType === "sku") {
      setOrderQty(editOrderData?.quantity);

      setReturnQty(editOrderData?.return);
      setCapacity(editOrderData?.capacity);
      setMaterial(editOrderData?.material);
    } else {
    }
  }, [editOrderData]);

  //function for adding product qty
  const handelAddQuantity = () => {
    if (
      editOrderType === "20Liters" &&
      orderQty < editOrderData?.twentyltr_capacity_qty
    ) {
      setOrderQty(orderQty + 1);
    } else if (editOrderType === "sku" && orderQty < editOrderData?.quantity) {
      setOrderQty(orderQty + 1);
    } else {
      toast.dark("Cannot edit more than ordered!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  //function for redusing orderqty
  const handelReduseQuantity = () => {
    if (editOrderType === "20Liters" && orderQty > 1) {
      setOrderQty(orderQty - 1);
      if (parseInt(returnQty) === orderQty) {
        setReturnQty(returnQty - editOrderData?.sku_qty);
      }
    } else if (editOrderType === "sku" && orderQty > 1) {
      setOrderQty(orderQty - 1);
      if (parseInt(returnQty / editOrderData?.sku_qty) == parseInt(orderQty)) {
        setReturnQty(returnQty - editOrderData?.sku_qty);
      }
    } else {
      toast.dark("Cannot edit less than minimum quantity!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  //function for adding product qty
  const handelAddReturnQuantity = () => {
    if (
      editOrderType === "20Liters" &&
      returnQty < editOrderData?.empty_container_qty
    ) {
      setReturnQty(returnQty + 1);
    } else if (editOrderType === "sku" && returnQty < editOrderData?.return) {
      setReturnQty(returnQty + 1);
    } else {
      toast.dark("Cannot edit more than ordered!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  //function for redusing orderqty
  const handelReduseReturnQuantity = () => {
    if (editOrderType === "20Liters" && returnQty > 0) {
      setReturnQty(returnQty - 1);
    } else if (editOrderType === "sku" && returnQty > 0) {
      setReturnQty(returnQty - 1);
    } else {
    }
  };

  //const Handel Submit
  const handelSubmitOrder = () => {
    const reqObj = {
      order_id: orderId,
      products: [
        {
          capacity: capacity,
          material: material,
          quantity: orderQty,
          return: returnQty,
          is_crate: false,
          force_update_order: false,
        },
      ],
    };
    dispatch(EditOrderActionSku(reqObj)).then((resp) => {
      console.log(resp);
      if (resp?.data?.error && resp?.data?.deposit_details === undefined) {
        setOpenOrderDetailsDialog(false);
        handleClose();
      }
    });
    // setOpenOrderDetailsDialog(false);
    // handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            bgcolor: appColors.secondaryCard,
            color: appColors.headerTextColor,
            fontSize: appTheme.fontSizeLarge,
          }}
          id="customized-dialog-title"
        >
          Edit Order ({editOrderData?.name})
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ overflow: "hidden", bgcolor: appColors.secondaryCard }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "15rem" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: appColors.darkGray,
              }}
            >
              <Typography>Order Qty</Typography>
              <WaterDropApplyButton
                handelAddQuantity={handelAddQuantity}
                handelReduseQuantity={handelReduseQuantity}
                quantity={orderQty}
              />
            </Box>
            {editOrderData?.booking_type !== 2 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                }}
              >
                <Typography>Return Qty</Typography>
                <WaterDropApplyButton
                  handelAddQuantity={handelAddReturnQuantity}
                  handelReduseQuantity={handelReduseReturnQuantity}
                  quantity={returnQty}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ bgcolor: appColors.secondaryCard }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{ backgroundColor: appColors.Failed }}
          >
            close
          </Button>
          <Button variant="contained" size="small" onClick={handelSubmitOrder}>
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
