/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { transactionType } from "../../Assets/Constants/AppMetaData";
import { TransactionDetails } from "../../Redux/actions/referAndEarnAction";
import { Typography, Box } from "@mui/material";
import appColors from "../../Assets/AppTheme/appColors";
import waterDrop from "../../Assets/newMobileIcon/waterDrops 1.svg";
import PageNavigation from "../../Components/CommonComponents/PageNavigationDetails";
export default function WaterWalletTransactionCardNew({
  referalHistory,
  pageNumber,
  setPageNumber,
}) {
  const [transaction, setTransaction] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    // console.log(referalHistory);
    if (referalHistory.transactionDetails) {
      setTransaction(referalHistory.transactionDetails);
    }
    if (
      referalHistory.transactionDetails &&
      referalHistory.transactionDetails.length === 0
    ) {
      // console.log("---Changing---");
    }
    if (
      referalHistory.transactionDetails &&
      referalHistory.transactionDetails.length > 0
    ) {
    }
  }, [referalHistory]);

  React.useEffect(() => {
    // console.log("----triggered Transaction Page----");
    dispatch(TransactionDetails(pageNumber - 1));
  }, [pageNumber]);

  const pageForward = () => {
    setPageNumber(pageNumber + 1);
  };

  const pageBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD MMM YYYY");
    return momentEndDate;
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: ".5rem",
        }}
      >
        <PageNavigation
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          pageList={referalHistory.pageStatus}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          "&::-webkit-scrollbar": {
            display: "none", // Webkit browsers
          },
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          gap: 2,
          flexDirection: "row",
        }}
      >
        {transaction && transaction.length > 0 ? (
          transaction.map((val, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Card
                  sx={{
                    width: 250,
                    borderRadius: ".5rem",
                    boxShadow: 5,
                    margin: "0 2rem 2rem 2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      background: appColors.homePageCard,

                      position: "relative",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <img
                        src={waterDrop}
                        style={{ height: "4rem", width: "3rem" }}
                        alt="Bookwater"
                      />
                    </Box>
                    <Box
                      sx={{
                        height: 40,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ color: "var(--black)", fontSize: ".8rem" }}
                      >
                        {val.transaction_status ===
                        transactionType.WATER_DROP_PDW_ORDER_CONSUMER_PAID
                          ? "Water Drops Redeemed on"
                          : val.transaction_status ===
                              transactionType.ADMIN_ADDED_WATER_DROPS ||
                            val.transaction_status ===
                              transactionType.WATER_DROP_REFERRER_REWARDED ||
                            val.transaction_status ===
                              transactionType.WATER_DROP_REFERREE_REWARDED ||
                            val.transaction_status ===
                              transactionType.WATER_DROP_PROMO_DISCOUNT
                          ? "Water Drops Rewarded on"
                          : val.transaction_status === 100
                          ? "Water Drops Rewarded on"
                          : val.transaction_status ===
                            transactionType.WATERDROPS_REDEMED
                          ? "Water Drops Redeemed on"
                          : "Water Drops Refunded on"}
                      </Typography>
                      <Typography
                        sx={{ color: "var(--black)", fontSize: ".9rem" }}
                      >
                        {endDateConverter(val.created_at)}
                        <span></span>
                      </Typography>
                    </Box>
                  </Box>

                  <CardContent
                    sx={{ bgcolor: appColors.appBrColor, height: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: ".5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          color: appColors.headerTextColor,
                          fontSize: ".8rem",
                          fontWeight: "bold",
                        }}
                      >
                        Reason:
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontSize: ".8rem",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          {val.transaction_status ===
                          transactionType.WATER_DROP_PDW_ORDER_CONSUMER_PAID
                            ? `Redeemed against  ${val.order_id}`
                            : val.transaction_status ===
                              transactionType.WATER_DROP_REFERRER_REWARDED
                            ? `Rewarded for referring ${val.consumer_name}`
                            : val.transaction_status ===
                              transactionType.WATER_DROP_REFERREE_REWARDED
                            ? `Referree Reward from ${val.order_id}`
                            : val.transaction_status ===
                              transactionType.WATER_DROP_CASHBACK_REWARDING
                            ? `Rewarded for ${val.order_id}`
                            : val.transaction_status ===
                              transactionType.WATER_DROP_REFUND_ORDER_CANCEL
                            ? `Refund on  ${val.order_id} edit/cancel`
                            : val.transaction_status ===
                              transactionType.ADMIN_ADDED_WATER_DROPS
                            ? `Rewarded by BookWater `
                            : val.transaction_status ===
                              transactionType.CAMPAIGN_ADDED_WATER_DROPS
                            ? `Rewarded Via Campaigns`
                            : val.transaction_status === 100
                            ? `Rewards for signing up through a referral!`
                            : val.transaction_status ===
                              transactionType.WATERDROPS_REDEMED
                            ? `Water Drops Redeemed`
                            : val.reason}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: ".5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: appColors.headerTextColor,
                          fontSize: ".8rem",
                          fontWeight: "bold",
                        }}
                      >
                        Water Drops:
                      </Typography>
                      <Typography
                        sx={{
                          color: appColors.darkGray,
                          fontSize: ".8rem",
                          marginLeft: ".3rem",
                          fontWeight: "500",
                        }}
                      >
                        {val.transaction_status ===
                          transactionType.WATER_DROP_PDW_ORDER_CONSUMER_PAID ||
                        val.transaction_status ===
                          transactionType.WATERDROPS_REDEMED
                          ? "-"
                          : "+"}
                        {val.water_drops}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/bwLogo2.png"}
              style={{ objectFit: "contain", height: "50px" }}
            />
            <Typography sx={{ color: appColors.darkGray }}>
              {" "}
              No Waterdrop Transaction Available!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
