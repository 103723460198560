/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import CustomConsole from "../../coustomConsole";
export const GetPromoCodesUser = () => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/consumer/referralCode/createReferralId`
      );
      CustomConsole("---promocode api----");
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REFERRAL_CODES,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GetWaterDropsUser = () => {
  let api = useAxiosWithoutLoading();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/consumerAdmin/referralCode/getwaterDrops`
      );
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WATER_DROPS,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const RequestingForInfluencer = (reqObj) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/consumer/referralCode/requestInfluencer`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REQUEST_INFLUENCER,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const TransactionDetails = (pgNo) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/consumer/waterDrops/getWaterDropTransactions?page_number=${pgNo}`
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WATER_DROP_TRANSACTION,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const InfluencerAutoPayAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/consumer/waterDrops/enableDisableAutoPay`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INFLUENCER_AUTO_PAY,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(GetWaterDropsUser());
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const SettlementRequests = (reqObj) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/settlements/requestSettlement`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.WATER_DROP_SETTLEMENT,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
