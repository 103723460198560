// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: MobileViewcss.js
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../Assets/AppTheme/appColors";

//Main view css
export const MobStyles = {
  bannserStyles: {
    width: "100%",
    padding: "0 .5rem",
  },
  gridMainContainer: {
    overflowY: "scroll",
  },
  gridItemHeading: {

    display: "flex",
    flexDirection: "column",
  },
  welcomeText: {
    fontWeight: "bold",
    fontSize: "1.4rem",
    color: appColors.lightGreen,
    marginLeft: "1rem",
  },
  DeliveryOtpText: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    color: appColors.darkBlue,
    marginLeft: "1.4rem",
  },
  coustomerId: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "#58595B",
    marginLeft: "1rem",
  },
  quickText: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#152057",
    marginLeft: "1rem",
  },
  gridMenu: {
    marginTop: "1rem",
    marginLeft: ".8rem",
    marginRight: ".8rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconBox: {},
  iconText: {
    marginTop: ".5rem",
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  iconStyles: { height: "3rem", elevation: 8 },
  gridMenu2: {
    marginTop: ".9rem",
    marginLeft: ".8rem",
    marginRight: ".8rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  gridMenu3: {
    marginTop: ".9rem",
    marginLeft: ".8rem",
    marginRight: ".8rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
};

//Footer Styles
export const FooterStyles = {
  footerMain: {
    backgroundColor: "transparent",
    top: "auto",
    bottom: 0,
    height: "3.8rem",
    width: "100%",
    position: "fixed",
    boxShadow:5,
  },
  checkOut: {
    background:appColors.checkOutCard,
    top: "auto",
    bottom: 0,
    height: "4.5rem",
    width: "100%",
    position: "fixed",
    display:"flex",
    justifyContent:"center"
  },
  footerIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  waterCan: {},
  footerText: {
    marginTop: ".4rem",
    fontSize: ".8rem",
    color: appColors.textColorNew,
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  orderStyle: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
  },
};

//App Bar Css
export const AppBarStyle = {
  appBarMain: {
    backgroundColor: appColors.appBrColor,
    position: "relative",
    // height: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  bookWaterLogo: {
    width: "7rem",
    height: "3rem",
    objectFit: "contain",

    position: "relative",
    marginTop: ".6rem",
  },
  AddressStyle: {
    bookWaterLogo: { width: "1rem", height: "1.5rem", marginRight: ".5rem" },
  },
  waterWallet: {
    fontWeight: "bold",
    marginLeft: "5px",
    color: "white",
    alignContent: "center",
  },
  appBarImage: {
    position: "absolute",
    height: "100%",
    width: "100vw",
    objectFit: "cover",
    zIndex: -1,
  },
};

//Menu Dialog Bar Css
export const MenuStyle = {
  homeText: {
    color: appColors.darkGray,
    fontWeight: "600",
    fontSize: "16px",
  },
  chipCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textDetails: {
    fontSize: ".8rem",
    color: appColors.darkGray,
    fontWeight: "bold",
    marginTop: ".3rem",
  },
  WallettextDetails: {
    fontSize: ".8rem",
    color: appColors.darkGray,
    fontWeight: "bold",
    marginTop: ".3rem",
  },
  footerIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",
  },
  PaymenttextDetails: {
    fontSize: ".8rem",
    color: appColors.darkGray,
    fontWeight: "bold",
    marginTop: ".3rem",
  },
};
