/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Booking.jsx is the parent file for the booking page
 * @date 04/09/23
 * @version 0.0.58 -  Release
 */

import React from "react";
import BiconLoader from "../../Assets/Animations/preloader.gif";
import OrderDetailsCard from "../../Components/Cards/ordersCard/orderDetailsCard";
import { Backdrop, Box, Grid, Typography } from "@mui/material";
import NavigationButtons from "../../Components/CommonComponents/pageNavigation";
import * as orderActions from "../../Redux/actions/ordersAction";
import OrderDetailsDialog from "../../Components/DialogBoxes/SkuDialogs/OrderDetailsDialog/orderDetailsDialog";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FilterDialogOrders from "../../Components/DialogBoxes/FilterDialogBox/FilterDialogBox";
import MyWallet from "../../Components/PaymentComponent/mywallet/myWallet";
import ChoosePaymentDialogSKU from "../../Components/DialogBoxes/SkuDialogs/ChoosePaymentDialog";
import CancelOrderDialog from "../../Components/DialogBoxes/PdwOrderDetails/CancelOrderDialog";
import EditCancelReasonDialog from "../../Components/DialogBoxes/PdwOrderDetails/EditCancelReasonDialog";
import {
  GetNewOrderId,
  ReOrderDetailsAction,
  ResetOrderState,
} from "../../Redux/actions/skuOrderPlacingAction";
import {
  SelectedProductaction,
  SetSelectedAddress,
} from "../../Redux/actions/skuBookingAction";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Check } from "@material-ui/icons";
import {
  CheckOutActionLoading,
  ClearPendingOrderData,
} from "../../Redux/actions/checkOutAction";
import EditOrderDialogSku from "../../Components/DialogBoxes/SkuDialogs/OrderDetailsDialog/editOrderDialog";
import appColors from "../../Assets/AppTheme/appColors";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction";
import PendingOrderDialogBox from "../../Components/DialogBoxes/BookNow/pendingOrderDialog";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction";
import CustomConsole from "../../coustomConsole";
import { ExtraDepositAction } from "../../Redux/actions/DepositDetailsAction";
import ExtraDepositPayDialog from "../../Components/DialogBoxes/SkuDialogs/extraDepositPayDialog";
import ExtraDepositDialog from "../../Components/DialogBoxes/SkuDialogs/DepositDialoges/extraDepositDialog";

import { GetWaterDropsUser } from "../../Redux/actions/referAndEarnAction";

export const styles = {
  ordersMain: {
    marginTop: "4rem",
    marginBottom: { lg: "0", md: "0", sm: "4rem", xs: "4rem" },
  },
};
//Option object for selecting orders type
const options = {
  CANCELLED: "CA",
  PAYMENT_PENDING: "PP",
  WATER_SEWAGE_TANKERS: "WST",
  PACKAGED_DRINKING_WATER: "PDW",
  ONGOING_TRIPS: "OT",
  COMPLETED_TRIPS: "CT",
  DELIVERED: "DO",
};
export default function OrderManager() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("orderId");
  const depositData = useSelector((state) => state.depositData);
  const waterDropDetails = useSelector((state) => state.referAndEarnDetails);
  const selectedEditOrderDetails = useSelector(
    (state) => state.ordersDetails.singleOrder
  );
  const orderDetails = useSelector((state) => state.ordersDetails);
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const SkuDetails = useSelector((state) => state.skuBookDetails);
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  /////////////////
  const SkuReOrderDetails = useSelector((state) => state.skuOrderIdDetails);
  //settingsAction
  const paymentMethod = useSelector((state) => state.paymentMethods);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [promoCode, setpromoCode] = React.useState("");
  const [openVbDialog, setOpenVbDialog] = React.useState(false);
  const [paymentFlag, setpaymentFlag] = React.useState(false);
  const [upiId, setUpiId] = React.useState("");
  const [razorPayFlag, setrazorPayFlag] = React.useState(false);
  const [orderPaymentMethod, setOrderPaymentMethod] = React.useState("");
  const [amountEntered, setEnteredPayAmount] = React.useState(0);
  const [upiIdVPAObj, setUpiIdVPAObj] = React.useState({});
  const address = useSelector((state) => state.settings);
  const [verifyUpiClicked, setVerifyUpiIdClicked] = React.useState(false);
  const [checkOutButtonClick, setCheckOutButtonClick] = React.useState(false);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = React.useState(paymentTypeWallet.RAZORPAY);
  const [orderId, setOrderId] = React.useState("");
  const [upiUserOption, setUpiUserOption] = React.useState("");

  const [upiPayUserOption, setUpiPayUserOption] = React.useState("");
  const [payType, setPayType] = React.useState(0);
  const [verifyBtn, setVerifyBtn] = React.useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = React.useState(false);
  const [opean, setOpean] = React.useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = React.useState(false);
  const [payFor, setPayFor] = React.useState("");
  const [payOptionCheck, setPayOptionCheck] = React.useState(false);
  const [choosenPartial, setChoosenPartial] = React.useState(false);
  const [bookingpaymentFlag, setBookingPaymentFlag] = React.useState(false);
  const [extraDepositDialogOpen, setExtraDepositDialogOpen] =
    React.useState(false);
  const [upiPay, setUpiPay] = React.useState(false);
  //state for storing address ID
  const [paymentType, setPaymentType] = React.useState("");
  ////////////////////
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [filterDialog, setfilterDialog] = React.useState(false);
  const [pendingFlag, setPendingFlag] = React.useState(false);
  const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] =
    React.useState(false);
  const [reqObj, setreqobj] = React.useState({
    delivery_from_date: moment().subtract(180, "days").format("YYYY-MM-DD"),
    delivery_to_date: moment().add(7, "days").format("YYYY-MM-DD"),
    page_size: 10,
    order_status: "",
    delivery_status: "",
    page_number: pageNumber,
    sort_by: [{ key: "order_id", value: "DESC" }],
  });
  const [orderCancelMsg, setOrderCancelMsg] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cancelOrderClicked, setCancelOrderClicked] = React.useState(false);
  const [cancelOrderReasonsValues, setCancelOrderReasonsValues] =
    React.useState([]);
  const [isOrderInPaymentPending, setIsOrderPaymentPending] =
    React.useState(false);
  const [cancelReason, setCancelReason] = React.useState("");
  const [cancelReasonStr, setCancelReasonStr] = React.useState("");
  const [cancelOrderId, setCancelOrderId] = React.useState("");
  const [openEditOrderDialog, setOpenEditOrderDialog] = React.useState(false);
  const [editOrderData, setEditOrderData] = React.useState({});
  const [editOrderType, setEditOrderType] = React.useState("");
  const [orderLoading, setOrderLoading] = React.useState(true);
  const [newOrderId, setNewOrderId] = React.useState("");
  const paymentBlocked =
    SkuDetails?.productDetails?.admin_settings?.payment_methods.map(
      (method) => method.type
    );
  const BlocketPayments = new Set(paymentBlocked);
  const [validPaymetMethod, setValidPaymentMethod] = React.useState("");
  const [useWalletBalancePayFlag, setUseWalletBalancePayFlag] =
    React.useState(true);
  const [
    useWalletBalancePayFlagforDeposit,
    setUseWalletBalancePayFlagforDeposit,
  ] = React.useState(true);
  const [finalPrice, setFinalPrice] = React.useState(0);
  const [multiplePaymentFlag, setMultiplePayment] = React.useState(false);
  const [updatePaymentClick, setUpdatePaymentClick] = React.useState(false);
  //useEffect for getting product details
  React.useEffect(() => {
    if (pageName) {
      const orderObj = {
        delivery_from_date: moment().subtract(90, "days").format("YYYY-MM-DD"),
        delivery_to_date: moment().add(7, "days").format("YYYY-MM-DD"),
        page_size: 10,
        order_status: "",
        order_ids: [pageName],
        delivery_status: "",
        page_number: pageNumber,
        sort_by: [{ key: "order_id", value: "DESC" }],
      };
      dispatch(orderActions.GetPdwAllOrders(orderObj));
      dispatch(orderActions.GetSingleOrderDetails(pageName));
      setOpenOrderDetailsDialog(true);
    } else {
      dispatch(orderActions.GetPdwAllOrders(reqObj));
    }
  }, [reqObj.page_number]);
  const [cancelOtherReason, setCancelOtherReason] = React.useState("");
  //state to handel order details
  const [selectedOrderDetails, setSelectedOrderDetails] = React.useState("");
  //function for doing api call
  function ApiCall() {
    dispatch(orderActions.GetPdwAllOrders(reqObj));
  }
  CustomConsole(pageName);
  //function for handling payment action
  const handelPaymentFlag = (data) => {
    CustomConsole(parseInt(walletDetails.walletAmount) >= 1);
    CustomConsole(useWalletBalancePayFlag);
    CustomConsole(
      parseInt(data?.final_price) > parseInt(walletDetails.walletAmount)
    );
    CustomConsole(
      parseFloat(data?.final_price) - walletDetails?.walletAmount >= 1
    );
    setSelectedOrderDetails(data);
    if (data?.payment_method !== "PAYSHARP") {
      if (
        parseInt(walletDetails.walletAmount) >= 1 &&
        useWalletBalancePayFlag &&
        parseInt(data?.final_price) > parseInt(walletDetails.walletAmount) &&
        parseFloat(data?.final_price) - walletDetails?.walletAmount >= 1
      ) {
        setEnteredPayAmount(
          parseFloat(data?.final_price - walletDetails.walletAmount).toFixed(2)
        );
        setMultiplePayment(true);
      } else {
        setEnteredPayAmount(data?.final_price);
        if (parseInt(data?.packaged_drinking_water?.balance_to_pay) === 0) {
          setMultiplePayment(false);
        }
      }
      setFinalPrice(data?.final_price);
      dispatch(GetNewOrderId({ order_id: data?.order_id }));
      setBookingPaymentFlag(true);
      setOrderId(data?.order_id);
      setOrderPaymentMethod(data?.payment_method);
      setOpenOrderDetailsDialog(false);
    } else {
      dispatch(GetNewOrderId({ order_id: data?.order_id }));
      if (
        parseInt(walletDetails.walletAmount) >= 1 &&
        useWalletBalancePayFlag &&
        parseInt(data?.final_price) > parseInt(walletDetails.walletAmount) &&
        parseFloat(data?.finalPrice) - walletDetails?.walletAmount >= 1
      ) {
        setEnteredPayAmount(
          parseFloat(data?.final_price - walletDetails.walletAmount).toFixed(2)
        );
        setMultiplePayment(true);
      } else {
        setEnteredPayAmount(data?.final_price);
        if (parseInt(data?.packaged_drinking_water?.balance_to_pay) === 0) {
          setMultiplePayment(false);
        }
      }
      setFinalPrice(data?.final_price);
      setBookingPaymentFlag(true);
      setOrderId(data?.order_id);
      setOrderPaymentMethod(data?.payment_method);
      setOpenOrderDetailsDialog(false);
    }
  };

  //useeffect to empty selected order details
  React.useEffect(() => {
    if (!bookingpaymentFlag) {
      setSelectedOrderDetails("");
    }
  }, [bookingpaymentFlag]);

  //useeffect to handel order multiple payment option
  React.useEffect(() => {
    if (selectedOrderDetails !== "") {
      handelPaymentFlag(selectedOrderDetails);
    }
  }, [useWalletBalancePayFlag]);
  //useEffect for seting order id if paysharppayment
  React.useEffect(() => {
    if (
      !SkuReOrderDetails.newOrderIdError &&
      SkuReOrderDetails.newOrderId !== undefined &&
      SkuReOrderDetails.newOrderId?.new_order_id !== undefined
    ) {
      setNewOrderId(SkuReOrderDetails.newOrderId?.new_order_id);
      SkuReOrderDetails.newOrderIdError = true;
    }
  }, [SkuReOrderDetails.newOrderId]);
  //useEffect for getting product details
  React.useEffect(() => {
    if (paymentType !== "PAYSHARP") {
      if (paymentType !== "" && upiUserOption !== "3") {
        setpaymentFlag(true);
        setPaymentType("");
      } else if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
        setPaymentType("");
      }
    } else if (
      orderPaymentMethod === "PAYSHARP" &&
      paymentType === "PAYSHARP"
    ) {
      if (selectedOrderDetails !== "") {
        setOrderId(newOrderId);
      }
      if (paymentType !== "" && upiUserOption !== "3") {
        setpaymentFlag(true);
        setPaymentType("");
      } else if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
        setPaymentType("");
      }
    } else if (
      orderPaymentMethod !== "PAYSHARP" &&
      paymentType === "PAYSHARP"
    ) {
      if (selectedOrderDetails !== "") {
        setOrderId(newOrderId);
      }
      if (paymentType !== "" && upiUserOption !== "3") {
        setpaymentFlag(true);
        setPaymentType("");
      } else if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
        setPaymentType("");
      }
    }
  }, [paymentType, isUpiIdValid]);
  CustomConsole(orderPaymentMethod);
  CustomConsole(paymentType);
  CustomConsole(orderId);

  //For Refreshing order details if payment method changes
  React.useEffect(() => {
    if (paymentMethod.isPaymentUpdated || paymentMethod.paymentStatus !== "") {
      if (paymentMethod?.paymentStatus?.data?.status !== "PENDING") {
        ApiCall();
      }
      paymentMethod.isPaymentUpdated = false;
    }
  }, [paymentMethod.isPaymentUpdated, paymentMethod.paymentStatus]);

  //function for handling cancel order
  const handleCancelOrder = (data) => {
    setCancelOrderClicked(true);
    setCancelReason("");
    setCancelOtherReason("");
    setCancelOrderId(data?.order_id);
    setOrderCancelMsg(false);
    setLoading(false);
  };

  //function for cancel order function
  const handleCancelYes = () => {
    const reqObj = {
      order_id: cancelOrderId,
      reason: cancelReasonStr,
    };
    dispatch(orderActions.CancelOrder(reqObj));
    setOrderCancelMsg(true);
    setLoading(true);
    setIsCancelOrderDialogOpen(false);
  };

  //useeffect for getting cancel order details
  React.useEffect(() => {
    setUpConfigData.metaData.map((data) => {
      CustomConsole(data);
      if (data.key === "pdw_consumer_order_cancel") {
        setCancelOrderReasonsValues(data.value);
      }
    });
  }, [setUpConfigData]);

  //useeffect for getting cancel order details
  React.useEffect(() => {
    if (!orderDetails.cancelOrderError) {
      ApiCall();
      // dispatch(AllOrderOtpPaymentDetails());
      setOpenOrderDetailsDialog(false);
      dispatch(orderActions.CancelOrderError());
    }
    if (openOrderDetailsDialog) {
      dispatch(orderActions.GetSingleOrderDetails(pageName));
    }
    setLoading(false);
    setIsCancelOrderDialogOpen(false);
  }, [orderDetails.cancelOrderError]);

  //function for redirecting to checkout page if order details found
  const handelReorderData = (data) => {
    if (!pendingFlag) {
      const reqObj = {
        order_id: data?.order_id,
      };
      dispatch(ReOrderDetailsAction(reqObj));
      setOpenOrderDetailsDialog(false);
    } else {
      setIsOrderPaymentPending(true);
    }
  };

  //useEffect for redirecting page for reorder details
  React.useEffect(() => {
    if (!SkuReOrderDetails.reOrderError) {
      if (
        SkuReOrderDetails?.reOrderDetails?.payment_method ===
        "PAID THROUGH RAZORPAY"
      ) {
        SkuReOrderDetails.reOrderDetails.payment_method = validPaymetMethod;
        SkuReOrderDetails.reOrderDetails.is_otp_less = true;
        SkuReOrderDetails.reOrderDetails.address_id =
          SkuDetails?.selectedAddressId;
        SkuReOrderDetails.reOrderDetails.is_auto_apply_water_drops =
          waterDropDetails?.allData?.auto_pay;
        SkuReOrderDetails.reOrderDetails.water_drops = 0;
        dispatch(SelectedProductaction(SkuReOrderDetails?.reOrderDetails));
        dispatch(CheckOutActionLoading(SkuReOrderDetails?.reOrderDetails));
        setCheckOutButtonClick(true);
        SkuReOrderDetails.reOrderError = true;
      } else {
        SkuReOrderDetails.reOrderDetails.address_id =
          SkuDetails?.selectedAddressId;
        SkuReOrderDetails.reOrderDetails.is_otp_less = true;
        SkuReOrderDetails.reOrderDetails.payment_method = validPaymetMethod;
        SkuReOrderDetails.reOrderDetails.is_auto_apply_water_drops =
          waterDropDetails?.allData?.auto_pay;
        SkuReOrderDetails.reOrderDetails.water_drops = 0;
        dispatch(SelectedProductaction(SkuReOrderDetails?.reOrderDetails));
        dispatch(CheckOutActionLoading(SkuReOrderDetails?.reOrderDetails));
        setCheckOutButtonClick(true);
        SkuReOrderDetails.reOrderError = true;
      }
    }
  }, [SkuReOrderDetails.reOrderError]);

  //useeffect for reorder page details
  React.useEffect(() => {
    if (
      checkOutDetails?.loading === false &&
      checkOutDetails?.checkOutDetails !== undefined &&
      checkOutDetails?.checkOutDetails?.valid_products?.length > 0 &&
      checkOutButtonClick
    ) {
      history.push(`/dashboard/checkout?pageName=booking`);
      setCheckOutButtonClick(false);
    }
  }, [checkOutDetails?.loading]);

  //function for handling edit order dialog
  const handelEditOrderClose = () => {
    setOpenEditOrderDialog(false);
    setEditOrderData({});
    setEditOrderType("");
    setCancelOrderId("");
    setUpiId("");
  };

  //function for handling edit order details
  const handelOpenEditOrderDetails = (data, type, orderId) => {
    setOpenEditOrderDialog(true);
    CustomConsole(data);
    setEditOrderData(data);
    setEditOrderType(type);
    setCancelOrderId(orderId);
  };

  //function for loading icon and clering selected products after orders page
  React.useEffect(() => {
    setTimeout(() => {
      setOrderLoading(false);
    }, 1500);
    return () => {
      SkuDetails.selectedProduct = {};
      dispatch(orderActions.ResetOrderData());
    };
  }, []);

  //UseEffect to change page number
  React.useEffect(() => {
    setreqobj((prev) => ({ ...prev, page_number: pageNumber }));
  }, [pageNumber]);

  //Useeffect for getting waterwallet payment method
  React.useEffect(() => {
    if (walletDetails.isWalletSucess) {
      window.scrollTo(0, 0);
      dispatch(AllOrderOtpPaymentDetails());
      ApiCall();
      dispatch(ResetComponentState());
      dispatch(GetWalletDetails());
      handelEditOrderClose();
      setOpenOrderDetailsDialog(false);
    }
  }, [walletDetails]);

  //Useeffect for getting waterwallet payment method
  React.useEffect(() => {
    return () => {
      dispatch(ResetComponentState());
      dispatch(ClearPendingOrderData());
      dispatch(SelectedProductaction({}));
    };
  }, []);

  //Useeffect for getting waterwallet payment method
  React.useEffect(() => {
    dispatch(ResetComponentState());
  }, []);

  //Useeffect for setting address details
  React.useEffect(() => {
    const selectedAddressId = SkuDetails.selectedAddressId;
    const storedAddressId = localStorage.getItem("addressId");
    if (
      storedAddressId === null ||
      storedAddressId === undefined ||
      selectedAddressId === null ||
      selectedAddressId === undefined
    ) {
      let AddressData;
      if (address?.addresses !== undefined) {
        AddressData =
          address?.addresses.find((item) => item.is_primary === true) ||
          address?.addresses[0];
        CustomConsole(address);
      }
      if (AddressData?.address_id !== undefined) {
        localStorage.setItem("addressId", AddressData.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
      }
    } else {
      let AddressData;
      if (
        address?.addresses !== undefined &&
        storedAddressId === null &&
        storedAddressId === undefined
      ) {
        AddressData =
          address?.addresses.find(
            (item) => selectedAddressId === item.address_id
          ) || address?.addresses[0];
        CustomConsole(address);
      } else if (address?.addresses !== undefined) {
        AddressData =
          address?.addresses.find(
            (item) => storedAddressId == item.address_id
          ) || address?.addresses[0];
      }
      if (AddressData?.address_id !== undefined) {
        localStorage.setItem("addressId", AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
      }
    }
  }, [address.addresses]);

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    let validPayment = "";
    if (!BlocketPayments.has(300)) {
      validPayment = "WALLET";
    } else if (
      (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(103))
    ) {
      validPayment = "PAYSHARP";
    } else if (!BlocketPayments.has(200)) {
      validPayment = "RAZORPAY";
    }
    setValidPaymentMethod(validPayment);
  }, [SkuDetails]);

  //function for closing cancel dialog
  React.useEffect(() => {
    if (isCancelOrderDialogOpen) {
      setTimeout(() => {
        setIsCancelOrderDialogOpen(false);
      }, 15000);
    }
  }, [isCancelOrderDialogOpen]);

  //Useeffect for payment pending order
  // React.useEffect(() => {
  //   if (paymentPendingOrder?.allDetails?.payment_pending?.length !== 0) {
  //     let isPendingOrder =
  //       paymentPendingOrder?.allDetails?.payment_pending?.some(
  //         (item, idx) => item.payment_type !== 300
  //       );
  //     CustomConsole(isPendingOrder);
  //     setPendingFlag(isPendingOrder);
  //   } else if (paymentPendingOrder?.allDetails?.payment_pending?.length === 0) {
  //     setPendingFlag(false);
  //   }
  // }, [paymentPendingOrder?.allDetails]);

  //Function for handling payment pending order dialogbox
  const handlePaymentPendingDialog = (data) => {
    dispatch(orderActions.GetSingleOrderDetails(data));
    setOpenOrderDetailsDialog(true);
    setIsOrderPaymentPending(false);
  };

  //useeffect for handling reorder details
  React.useEffect(() => {
    if (
      checkOutDetails?.checkOutData !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== ""
    ) {
      setIsOrderPaymentPending(true);
    }
  }, [checkOutDetails?.checkOutData]);

  //function to share order details
  const shareContent = async (data) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: `BookWater Order Details

Order Id: ${data.order_id}
Order Status: ${
            data?.order_status_name === "NEW REQUEST"
              ? "Payment Pending"
              : data?.order_status_name
          }
Delivery Date: ${data.delivery_date} 
Delivery OTP: ${
            data?.delivery_otp_type !== undefined &&
            data?.delivery_otp_type !== null &&
            data?.delivery_otp_type !== "OTP-LESS" &&
            data?.delivery_otp !== 0
              ? data?.delivery_otp
              : "OTP-LESS"
          }
Payment Method: ${
            data?.payment_method === "PAYSHARP" ? "UPI" : data?.payment_method
          }
Total Price: ₹ ${data?.final_price}
Delivery Address: ${
            data?.delivery_address?.address_type === ""
              ? "Home"
              : data?.delivery_address?.address_type
          },
${data?.delivery_address?.contact_name},
${data?.delivery_address?.address_line1},
${data?.delivery_address?.address_line2},
${data?.delivery_address?.pincode},${data?.delivery_address?.service_area}

Products:
${
  data?.packaged_drinking_water?.twentyltr_capacity_qty > 0
    ? `👉 ${
        data?.packaged_drinking_water?.name !== undefined &&
        data?.packaged_drinking_water?.name !== null &&
        data?.packaged_drinking_water?.name !== ""
          ? `${data?.packaged_drinking_water?.name}`
          : `${data?.packaged_drinking_water?.selected_container_capacity}`
      }\n       Order Quantity: ${
        data?.packaged_drinking_water?.twentyltr_capacity_qty
      }
       Return Quantity: ${data?.packaged_drinking_water?.empty_container_qty}\n`
    : ""
}${data.products
            .map(
              (product) =>
                `👉 ${product.name}\n       Order Quantity: ${product.quantity}\n       Return Quantity: ${product.return}\n`
            )
            .join("")}

`,
        });
      } else {
        throw new Error("Web Share API is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing content:", error);
      // Fallback behavior in case sharing fails
      // You can provide alternative methods here, such as opening a modal with sharing options
    }
  };

  //useEffect for opening payment dialog if deposit is required
  React.useEffect(() => {
    if (SkuReOrderDetails.editDetails) {
      const editDetails = SkuReOrderDetails.editDetails;
      if (editDetails?.error && editDetails?.deposit_details !== undefined) {
        const reqObj = {
          capacity: editDetails?.deposit_details?.capacity,
          for_order: editDetails?.deposit_details?.for_order,
          material: editDetails?.deposit_details?.material,
          quantity: editDetails?.deposit_details?.quantity,
          is_crate: false,
          address_id: selectedEditOrderDetails?.delivery_address?.address_id,
          payment_method: "PAYSHARP",
          order_qty: editDetails?.deposit_details?.order_qty,
          return_qty: editDetails?.deposit_details?.return_qty,
        };
        dispatch(ExtraDepositAction(reqObj));
      }
    }
  }, [SkuReOrderDetails.editDetails]);

  //UseEffect for setting payment details
  React.useEffect(() => {
    if (
      !depositData.extraDepositError &&
      depositData.extraDepositError !== undefined
    ) {
      setEnteredPayAmount(depositData?.extraDeposiData?.amount);
      setFinalPrice(depositData?.extraDeposiData?.amount);
      setOrderId(depositData?.extraDeposiData?.order_id);
      setExtraDepositDialogOpen(true);
    }
  }, [depositData.extraDepositError]);

  // useEffect to calculate final price for multipayment
  React.useEffect(() => {
    if (
      useWalletBalancePayFlagforDeposit &&
      walletDetails?.walletAmount >= 1 &&
      extraDepositDialogOpen &&
      amountEntered > walletDetails?.walletAmount &&
      parseFloat(amountEntered) - walletDetails?.walletAmount >= 1
    ) {
      setEnteredPayAmount(
        parseFloat(amountEntered - walletDetails?.walletAmount).toFixed(2)
      );
    } else if (!useWalletBalancePayFlagforDeposit && extraDepositDialogOpen) {
      setEnteredPayAmount(depositData?.extraDeposiData?.amount);
    }
  }, [useWalletBalancePayFlagforDeposit, extraDepositDialogOpen]);

  CustomConsole(SkuReOrderDetails.editDetails);

  //use effect to call order api for refresh

  //useEffect to calculate final price for multipayment
  React.useEffect(() => {
    if (!SkuReOrderDetails.editOrderError) {
      // setEnteredPayAmount(amountEntered - walletDetails?.walletAmount);
      ApiCall();
      dispatch(GetWalletDetails());
      dispatch(GetWaterDropsUser());
      dispatch(ResetOrderState());
      handelEditOrderClose();
      setOpenOrderDetailsDialog(false);
    }
  }, [SkuReOrderDetails.editOrderError]);

  return (
    <Box sx={styles.ordersMain}>
      <Grid container direction="column">
        <Grid item lg={12}>
          <NavigationButtons
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setreqobj={setreqobj}
            setfilterDialog={setfilterDialog}
          />
        </Grid>
        <Grid item lg={12} sx={{ marginTop: "3rem" }}>
          {orderDetails?.orders?.length > 0 ? (
            <OrderDetailsCard
              orderDetails={orderDetails}
              setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
              handelPaymentFlag={handelPaymentFlag}
              handleCancelOrder={handleCancelOrder}
              handelReorderData={handelReorderData}
              pendingFlag={pendingFlag}
              setIsOrderPaymentPending={setIsOrderPaymentPending}
              shareContent={shareContent}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography sx={{ color: appColors.darkGray }}>
                Order Details Not Found
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {openOrderDetailsDialog && (
        <OrderDetailsDialog
          openOrderDetailsDialog={openOrderDetailsDialog}
          setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
          handelOpenEditOrderDetails={handelOpenEditOrderDetails}
          handelPaymentFlag={handelPaymentFlag}
          handleCancelOrder={handleCancelOrder}
          handelReorderData={handelReorderData}
          pageName={pageName}
          pendingFlag={pendingFlag}
          shareContent={shareContent}
        />
      )}
      <FilterDialogOrders
        filterDialog={filterDialog}
        setfilterDialog={setfilterDialog}
        setPageNumber={setPageNumber}
        options={options}
        setreqobj={setreqobj}
        ApiCall={ApiCall}
        reqObj={reqObj}
      />

      <MyWallet
        myWalletInBooking={false}
        setpromoCode={setpromoCode}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        setpaymentFlag={setpaymentFlag}
        promoCode={promoCode}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountEntered}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        setAmountToAdd={setEnteredPayAmount}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
        orderId={orderId}
      />

      {bookingpaymentFlag && (
        <ChoosePaymentDialogSKU
          orderPaymentMethod={orderPaymentMethod}
          open={bookingpaymentFlag}
          setPaymentType={setPaymentType}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          myWalletInBooking={false}
          setpaymentFlag={setpaymentFlag}
          upiId={upiId}
          setrazorPayFlag={setrazorPayFlag}
          setPerWeekPrice={amountEntered}
          setUpiId={setUpiId}
          upiIdVPAObj={upiIdVPAObj}
          setUpiIdVPAObj={setUpiIdVPAObj}
          setVerifyUpiIdClicked={setVerifyUpiIdClicked}
          verifyUpiClicked={verifyUpiClicked}
          payment={payment}
          setPayment={setPayment}
          upiUserOption={upiUserOption}
          setUpiUserOption={setUpiUserOption}
          setUpiPayUserOption={setUpiPayUserOption}
          amountEntered={amountEntered}
          setRazorPayAmount={amountEntered}
          setsubcribePaymentFlag={setBookingPaymentFlag}
          payType={payType}
          paymentTypeWallet={paymentTypeWallet}
          verifyBtn={verifyBtn}
          setVerifyBtn={setVerifyBtn}
          setPayType={setPayType}
          isUpiIdValid={isUpiIdValid}
          setIsUpiIdValid={setIsUpiIdValid}
          paymentFlag={paymentFlag}
          opean={opean}
          setOpean={setOpean}
          setMyWalletInBooking={setMyWalletInBooking}
          amountToAdd={amountEntered}
          setAmountToAdd={setEnteredPayAmount}
          setPayFor={setPayFor}
          setPayOptionCheck={setPayOptionCheck}
          choosenPartial={choosenPartial}
          setChoosenPartial={setChoosenPartial}
          perWeekPrice={amountEntered}
          setUpiPay={setUpiPay}
          pageName="myorders"
          finalPrice={finalPrice}
          useWalletBalancePayFlag={useWalletBalancePayFlag}
          setUseWalletBalancePayFlag={setUseWalletBalancePayFlag}
          multiplePaymentFlag={multiplePaymentFlag}
          setUpdatePaymentClick={setUpdatePaymentClick}
        />
      )}
      {extraDepositDialogOpen && (
        <ExtraDepositPayDialog
          orderPaymentMethod={orderPaymentMethod}
          open={extraDepositDialogOpen}
          setsubcribePaymentFlag={setExtraDepositDialogOpen}
          setPaymentType={setPaymentType}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          myWalletInBooking={false}
          setpaymentFlag={setpaymentFlag}
          upiId={upiId}
          setrazorPayFlag={setrazorPayFlag}
          setPerWeekPrice={amountEntered}
          setUpiId={setUpiId}
          upiIdVPAObj={upiIdVPAObj}
          setUpiIdVPAObj={setUpiIdVPAObj}
          setVerifyUpiIdClicked={setVerifyUpiIdClicked}
          verifyUpiClicked={verifyUpiClicked}
          payment={payment}
          setPayment={setPayment}
          upiUserOption={upiUserOption}
          setUpiUserOption={setUpiUserOption}
          setUpiPayUserOption={setUpiPayUserOption}
          amountEntered={amountEntered}
          setRazorPayAmount={amountEntered}
          payType={payType}
          paymentTypeWallet={paymentTypeWallet}
          verifyBtn={verifyBtn}
          setVerifyBtn={setVerifyBtn}
          setPayType={setPayType}
          isUpiIdValid={isUpiIdValid}
          setIsUpiIdValid={setIsUpiIdValid}
          paymentFlag={paymentFlag}
          opean={opean}
          setOpean={setOpean}
          setMyWalletInBooking={setMyWalletInBooking}
          amountToAdd={amountEntered}
          setAmountToAdd={setEnteredPayAmount}
          setPayFor={setPayFor}
          setPayOptionCheck={setPayOptionCheck}
          choosenPartial={choosenPartial}
          setChoosenPartial={setChoosenPartial}
          perWeekPrice={amountEntered}
          setUpiPay={setUpiPay}
          pageName="myorders"
          finalPrice={finalPrice}
          useWalletBalancePayFlag={useWalletBalancePayFlagforDeposit}
          setUseWalletBalancePayFlag={setUseWalletBalancePayFlagforDeposit}
          multiplePaymentFlag={multiplePaymentFlag}
        />
      )}
      <CancelOrderDialog
        // cancelOrderClicked={cancelOrderClicked}
        setIsCancelOrderDialogOpen={setIsCancelOrderDialogOpen}
        isCancelOrderDialogOpen={isCancelOrderDialogOpen}
        orderCancelMsg={orderCancelMsg}
        setOrderCancelMsg={setOrderCancelMsg}
        loading={loading}
        setLoading={setLoading}
        handleCancelYes={handleCancelYes}
      />
      <EditCancelReasonDialog
        cancelOrderClicked={cancelOrderClicked}
        cancelOrderReasonsValues={cancelOrderReasonsValues}
        setCancelOrderClicked={setCancelOrderClicked}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
        setIsCancelOrderDialogOpen={setIsCancelOrderDialogOpen}
        setCancelOtherReason={setCancelOtherReason}
        cancelOtherReason={cancelOtherReason}
        cancelReasonStr={cancelReasonStr}
        setCancelReasonStr={setCancelReasonStr}
        handleCancelYes={handleCancelYes}
      />
      {openEditOrderDialog && (
        <EditOrderDialogSku
          open={openEditOrderDialog}
          onClose={handelEditOrderClose}
          editOrderData={editOrderData}
          editOrderType={editOrderType}
          orderId={cancelOrderId}
          ApiCall={ApiCall}
          setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={orderLoading || orderDetails.loading}
      >
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BiconLoader}
            alt="Loading..."
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </Backdrop>
      <ToastMsgCotainer />
      {isOrderInPaymentPending && (
        <PendingOrderDialogBox
          open={isOrderInPaymentPending}
          handleClose={handlePaymentPendingDialog}
          paymentPendingDetails={checkOutDetails?.checkOutData?.order_id}
          setDialogClose={setIsOrderPaymentPending}
        />
      )}
    </Box>
  );
}
