/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

const actionTypes = {
  RENDER_DASHBOARD: "RefreshDashboardButtonAndTitle",
  APP_META_DATA: "GET: getAppMetaData",
  SIGN_UP: "POST: signUp",
  SIGN_IN: "POST: logIn",
  EMAIL_VERIFICATION: "emailVerification",
  RESEND_OTP: "resendOtp",
  SEND_EMAIL_OTP: "POST: sendEmailOtpForVerification",
  PINCODE_AVAILABILITY: "POST: searchForPincodeAvailability",
  PHONE_VERIFICATION: "phoneVerification",
  FORGOT_PASSWORD_USER_VERIFY_OTP: "forgotPasswordUserVerifyOtp",
  FORGOT_PASSWORD_NEW_PASSWORD: "forgotPasswordNewPassword",
  SIGN_OUT: "signOut",
  KEEP_ALIVE: "keepAlive",
  GET_USER_PROFILE: "getUserProfile",
  GET_FILES: "getFiles",
  SEARCH_PDW_AVAILABILITY: "POST: searchPackagedDrinkingWaterAvailability",
  FORGOT_PASSWORD_EMAIL_PHONE: "POST: verifyingOTP",
  GET_FILES_DOWNLOAD: "getFilesDownload",
  GET_RECENT_ACTIVITIES: "getRecentActivities",
  GET_TRANSACTION_DETAILS: "getTransactionDetails",
  GET_PDW_PAYMENT_PENDING: "GET: getPackagedDrinkingWaterPaymentPending",
  GET_PDW_ONGOING_TRIPS: "GET: getPackagedDrinkingWaterOngoingTrips",
  GET_PDW_COMPLETED_TRIPS: "GET: getPackagedDrinkingWaterCompletedTrips",
  GET_PDW_ALL_ORDERS: "GET: getPackagedDrinkingWaterAllOrders",
  GET_WST_PAYMENT_PENDING: "GET: getWaterSewageTankerPaymentPending",
  GET_WST_ONGOING_TRIPS: "GET: getWaterSewageTankerOngoingTrips",
  GET_WST_COMPLETED_TRIPS: "GET: getWaterSewageTankerCompletedTrips",
  GET_WST_ALL_ORDERS: "GET: getWaterSewageTankerAllOrders",
  LOADING_DATA: "FetchingOrPostDatatoServer",
  SETTINGS: "userSettings",
  UPDATE_USER_PROFILE: "PUT: updateUserProfile",
  UPDATE_PASSWORD: "POST: changeUserPassword",
  UPDATE_PHONE_NUMBER: "POST: changeCurrentPhoneNumber",
  UPDATE_PHONE_NUMBER_VERIFY: "PUT: verifyConsumerPhoneNumber",
  UPDATE_EMAIL: "POST: changeCurrentEmail",
  UPDATE_EMAIL_VERIFY: "PUT: verifyConsumerEmail",
  UPLOAD_FILE: "POST: uploadFiles",
  UPLOAD_PO_FILE: "POST: uploadPoFileWithOrderDetails",
  GET_USER_ADDRESS_LIST: "GET: getUserAdrressBookList",
  DELETE_USER_ADDRESS: "PUT: deletUserAddress",
  ADD_NEW_ADDRESS: "POST: addNewAddress",
  EDIT_EXISTING_ADDRESS: "PUT: editAddress",
  GET_VIRTUAL_ACCOUNTS_DETAILS: "GET: getUserVirtualBankAccountDetails",
  GET_USER_ACCOUNTS_LIST: "GET: getUserBankAccountsList",
  DELETE_USER_ACCOUNT: "PUT: deletUserBankAccount",
  ADD_NEW_ACCOUNT: "POST: addNewBankAccount",
  EDIT_EXISTING_ACCOUNT: "PUT: editExistingAccount",
  DELETE_USER_BANK_ACCOUNT: "PUT: deleteUserBankAccount",
  SEARCH_AVAILABILITY: "POST: searchAvailabilitybyPincode",
  WATER_TANKER_LIST: "GET: listTheWaterTankersForTheServiceArea",
  SEWAGE_TANKER_LIST: "GET: listTheSewageTankersForTheServiceArea",
  PDW_TANKER_LIST: "GET: listThePackagedDrinkingWaterTankersForTheServiceArea",
  BOOK_TANKER_ORDER: "POST: bookAWaterOrSewageTanker",
  BOOK_PDW_ORDER: "POST: bookPackagedDrinkingWater",
  PAYMENT_PENDING_TANKER: "GET: paymentPendingTanker",
  ONGOING_TANKER: "GET: ongoingTanker",
  COMPLETED_TANKER: "GET: completedTanker",
  ALLORDERS_TANKER: "GET: allOrdersTanker",
  ORDER_DETAILS: "GET: getOrderDetails",
  DELIVERY_DETAILS: "GET: getDeliveryDetails",
  PAYSHARP_HANDLE_STATUS: "POST: enableOrDisablePaysharp",
  PAY_BY_RAZORPAY: "POST: payThroughRazorPay",
  PAY_BY_RAZORPAY_NEW: "POST: payThrougnRazorPayNew",
  PAY_BY_PAYSHARP_UPI: "POST: PaythroughPaysharpUPI",
  PAY_BY_VPA_PAYSHARP_UPI: "POST: PaythroughVPAPaysharpUPI",
  PAY_BY_VPA_PAYSHARP_UPI_REQUEST: "POST: PaythroughVPAPaysharpUPIRequest",
  PAY_BY_UPI_ORDER_STATUS: "POST: PaythroughUPIOrderStatus",
  UPI_ORDER_STATUS: "upiOrderStatus",
  GET_WALLET_DETAILS: "GET: getWalletDetails",
  GET_WALLET_TRANSACTIONS: "GET: getWalletTransactionsDetails",
  PAY_THROUGH_WALLET: "POST: paythroughWalletForAnOrder",
  GET_DEPOSIT_DETAILS: "GET: getDepositDetails",
  GET_QR_DATA: "POST: scannedQrDetails",
  GET_USER_STOCK: "GET: getTheUserStockDetails",
  GET_DEPOSIT_DATA: "GET: getDepositData",
  GET_CONFIG_DATA: "GET: getConfigDetails",
  PUT_START_ORDER: "PUT: putStartOrder",
  GET_QR_REPORT_QUALITY: "GET: getQrQualityReport",
  INVOICE_DETAILS: "GET: getInvoiceDetails",
  DOWNLOAD_INVOICE: "GET: downloadInvoiceDetails",
  CANCEL_ORDER: "POST: cancelOrder",
  GET_SUBSCRIPTION_DETAILS: "GET: getSubscriptionOrderDetails",
  BOOK_PDW_SUBSCRIBE_ORDER: "POST: bookSubscribedPackageDrinkingWater",
  UPDATE_PDW_SUBSCRIBE_ORDER: "PUT: updateSubscriptionOrder",
  GET_PWD_ORDER_OTP: "getPwdOrderOtp",
  GET_BANK_DOWN_TIME: "getBankDownTime",
  GET_REPEAT_ORDER: "GET: getRepeatOrderDetails",
  SEND_EMAIL_OTP: "POST: sendEmailOtp",
  EDIT_ORDER_USER: "PUT: editOrderUser",
  RETURN_CANS_REQUEST: "POST: returnCansRequest",
  GET_PWD_PARTICULAR_ORDER: "getParticularOrderId",
  // GET_RETURN_CAN_LIST: "GET: returnReturnCanList",
  RETURN_CANS_OTP: "GET: returnCansOtp",
  REPLACEMENT_CANS_OTP: "GET: replacementCansOtp",
  DAMAGE_CANS_DATA: "GET: damageCansData",
  DAMAGE_CANS_ACCEPT: "PUT: damageCansAccept",
  GET_BANNERS: "getBanners",
  PINCODE_AVAILABILITY: "pincodeAvailability",
  REQUEST_REPLACEMENT_CAN: "requestReplacementCan",
  BANNERS_IMG: "bannersImg",
  GET_FILES_BANNER: "getFilesBanner",
  GET_PROMO_CODES: "getPromoCodes",
  GET_REFERRAL_CODES: "getReferralCodes",
  APPLY_PROMO_CODE: "applyPromoCodes",
  APPLY_WATER_DROPS: "applyWaterDrops",
  GET_WATER_DROPS: "getWaterDrops",
  REQUEST_INFLUENCER: "requestInfluencer",
  GET_DELIVERY_SLOTS_LIST: "getDeliverySlotList",
  REQ_DELIVERY_TIME: "requestDeliveryTime",
  INFLUENCER_AUTO_PAY: "InfluencerAutoPayAction",
  WATER_DROP_TRANSACTION: "waterDropTransactions",
  CONSUMER_BK_DETAILS: "consumerBkDet",
  GET_BANK_DETAILS_IFSC: "consumerIfscBkDet",
  WATER_DROP_SETTLEMENT: "waterDropSettlements",

  //For resetting component state
  RESET_COMPONENT_STATE: "reset_componenet_state",

  RESET_PROMO_CODE: "rest_promocode_details",
  REFERAL_CODE_VERIFY: "refer_code_verification",
  GET_QUALITY_REPORT: "files_banner",
  RESET_TOGGLE_STATE: "rest_toggle_state",
  RESET_DARK_THEME: "reset_dark_theme",
  TOAST_MSG_CONTAINER: "toast_msg_container",

  //SKU booking action
  SKU_PDW_BOOKING: "sku_pdw_bookinb",
  GET_PAYMENTBLOCKING_DETAILS: "SkuPaymentBlock",
  SKU_AVAILABLE_PRODUCTLIST: "SkuAvailableProductList",
  SET_SELECTED_ADDRESS: "SetSelectedAddress",
  ADD_SKU_PRODUCT_QTY: "AddSkuProductQty",
  REDUCE_SKU_PRODUCT_QTY: "ReduseSkuProductQty",
  ADD_RETURN_PRODUCT_QTY: "AddReturnProductQty",
  REDUCE_RETURN_PRODUCT_QTY: "ReduseProductQty",
  SELECTED_PRODUCT_ACTION: "SelectedProductAction",
  CHECKOUT_PRODUCTS_ACTION: "CheckOutDetails",
  ADD_CHECKOUT_REQUIRED_QTY: "AddCheckOutSkuQty",
  REDUSE_CHECKOUT_REQUIRED_QTY: "ReduseRequiredQty",
  ADD_CHECKOUT_RETURN_QTY: "AddCheckoutReturnQty",
  REDUSE_CHECKOUT_RETURN_QTY: "ReduseCheckoutReturnQty",
  SKU_ORDER_PLACING_ACTION: "SkuOrderPlacingAction",
  SINGLE_ORDER_DETAILS: "SingleOrderDetails",
  FETCH_DATA_START: "FetchDataStart",
  DELETE_SKU_PRODUCT_QTY: "DeleteSkuProductQty",
  FETCH_DATA_CHECKOUT_START: "FetchDataCheckOutStart",
  SET_SELECTED_DAYS_SUBSCRIBE: "SetSelectedDaysSubscribe",
  SKU_SUBSCRIBE_PLACEORDER: "SubscribePlaceOrder",
  SKU_SUBSCRIBE_ORDERDETAILS: "SkuSubcribeOrderDetails",
  SKU_UPDATE_SUBSCRIBEORDER: "SkuUpdateSubscribeOrder",
  RESET_ORDER_STATE: "ResetOrderState",
  REORDER_DETAILS_ACTION: "ReOrderDetailAction",
  RE_ORDER_DETAILS_FLAG: "ReOrderDetailsFlag",
  EDIT_ORDER_ACTION_SKU: "EditOrderActionSku",
  DEPOSIT_REFUND_ACTION: "DepositRefundAction",
  EXTRA_DEPOSIT_ACTION: "ExtraDepositAction",
  FETCH_EXTRA_DEPOSIT_DATA_START: "FetchExtraDepositData",
  DELETE_ALLSELECTED_PRODUCT_QTY: "DeleteAllSelectedProductQty",
  BLOCKED_DATE_INFO: "BlockedDateInfo",
  ALL_OTP_AND_ORDER_DETAILS: "AllOtpAndOrderDetails",
  SET_LOADING_FALSE_AFTER_DELAY: "SetLoadingFalseAfterDelay",
  SET_LOADING_FALSE_AFTER_DELAY_Booking: "SetLoadingFalseAfterDelayBooking",
  FETCH_ORDER_DATA_START: "FetchOrderDataStart",
  RESET_ORDER_DATA: "ResetOrderData",
  FETCH_DATA_START_ADDRESS: "FetchDataStarttAddress",
  NEW_ORDERID_PAYSHARP: "NewOrderIdPaysharp",
  SET_CANCEL_ORDER_ERROR: "CancelOrderError",
  CANCEL_BOOKING_ORDER_ERROR: "CancelOrderBookingError",
  RESET_UPDATE_ORDER_ERROR: "ResetUpdateOrderError",
  STORE_BANNER_IMAGES: "StoreBannerImages",
  STORE_BANNER_IMAGES_FLAG: "StoreImagesFlag",
  FETCH_DATA_STOP_ADDRESS: "FetchDataStopAddress",
  SPINNER_LOADING: "spinnerLoading",
  SPINNER_LOADED: "spinnerLoaded",
  RESET_ADDRESS_STATE: "ResetAddressState",
  SIGN_IN_LOADING: "SignInLoading",
  SIGN_IN_LOADING_STOP: "SignInLoadingStop",
  CLEAR_PENDING_ORDER_DATA: "ClearPendingOrderData",
  UPDATE_CHECKOUT_ERROR_FALSE: "UpdateCheckoutError",
  FORCE_APP_TOUR_INFO: "ForceAppTourInfo",
  RATING_BY_CONSUMER_STATE: "RatingByConsumerState",
  CLEAR_DEPOSIT_DETAILS_ACTION: "clearDepositDetailsAction",
  GET_RETURN_CANORDER_HISTROY: "GetReturnCanOrderHistroy",
  GET_REPLACEMENT_CANORDER_HISTROY: "ReplacementOrderHistroy",
};

export default actionTypes;
