// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 08/Jan/2024
// File Name: depositHistroyMain.jsx
// Author: Gowtham Prasath
// Email/Phone: gowtham.prasath@bookwater.com / +91-8817679918
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SkuDetailsProfile from "./skuDetailsProfile";
import appColors from "../../Assets/AppTheme/appColors";
import DepositDetailsSku from "./depositDetails";
import { useDispatch, useSelector } from "react-redux";
import { GetUserStocksAction } from "../../Redux/actions/getDepositHistoryAction";
import ChoosePaymentDialogSKU from "../../Components/DialogBoxes/SkuDialogs/ChoosePaymentDialog";
import MyWallet from "../../Components/PaymentComponent/mywallet/myWallet";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import { Backdrop, Typography } from "@mui/material";
import BiconLoader from "../../Assets/Animations/preloader.gif";
import { GetPaymentBlockDetails } from "../../Redux/actions/PaymentActionSku";
import { ResetOrderData } from "../../Redux/actions/ordersAction";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import DepositSucess from "../../Components/DialogBoxes/SkuDialogs/depositSucessPopup";
import ExtraDepositPayDialog from "../../Components/DialogBoxes/SkuDialogs/extraDepositPayDialog";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import { ReturnCansAction } from "../../Redux/actions/returnCansAction";
import {
  ReplacementOrderHistroy,
  ReturnCanOrderHistroy,
} from "../../Redux/actions/returnReplacementAction";
import CustomConsole from "../../coustomConsole";
export default function DepositHistroyMain() {
  const dispatch = useDispatch();

  const SkuDetails = useSelector((state) => state.skuBookDetails);
  const toastMsgDetails = useSelector((state) => state.toastMsgDetails);
  const depositDetails = useSelector((state) => state.depositDetails);
  //settingsAction
  const depositData = useSelector((state) => state.depositData);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [promoCode, setpromoCode] = React.useState("");
  const [openVbDialog, setOpenVbDialog] = React.useState(false);
  const [paymentFlag, setpaymentFlag] = React.useState(false);
  const [upiId, setUpiId] = React.useState("");
  const [razorPayFlag, setrazorPayFlag] = React.useState(false);
  const [amountEntered, setEnteredPayAmount] = React.useState(0);
  const [upiIdVPAObj, setUpiIdVPAObj] = React.useState({});
  const [verifyUpiClicked, setVerifyUpiIdClicked] = React.useState(false);
  const [checkOutButtonClick, setCheckOutButtonClick] = React.useState(false);
  const [orderPaymentMethod, setOrderPaymentMethod] = React.useState("");
  const [referLoading, setReferLoading] = React.useState(true);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = React.useState(paymentTypeWallet.PAYSHARP);
  const [orderId, setOrderId] = React.useState("");
  const [upiUserOption, setUpiUserOption] = React.useState("2");
  const [upiPayUserOption, setUpiPayUserOption] = React.useState("");
  const [payType, setPayType] = React.useState(100);
  const [refundPaymentFlag, setRefundPaymentFlag] = React.useState(false);
  const [verifyBtn, setVerifyBtn] = React.useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = React.useState(false);
  const [opean, setOpean] = React.useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = React.useState(false);
  const [payFor, setPayFor] = React.useState("");
  const [payOptionCheck, setPayOptionCheck] = React.useState(false);
  const [choosenPartial, setChoosenPartial] = React.useState(false);
  const [bookingpaymentFlag, setBookingPaymentFlag] = React.useState(false);
  const [upiPay, setUpiPay] = React.useState(false);
  const paymentBlockDetails = useSelector(
    (state) => state.paymentDetailsSku.paymentBlockData
  );
  const BlocketPayments = new Set(paymentBlockDetails);
  //state for storing address ID
  const [paymentType, setPaymentType] = React.useState("PAYSHARP");
  const [depositSucessDialog, setOpenDepositSucessDialog] =
    React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [multiplePaymentFlag, setMultiplePayment] = React.useState(false);
  const [
    useWalletBalancePayFlagforDeposit,
    setUseWalletBalancePayFlagforDeposit,
  ] = React.useState(true);
  const [openExtraDepositDialog, setOpenExtraDepositDialog] =
    React.useState(false);
  const [paymentTriggerFlag, setpaymentTriggerFlag] = React.useState(false);
  const [finalPrice, setFinalPrice] = React.useState(0);
  
  //Useeffect for getiing deposit histroy details
  React.useEffect(() => {
    dispatch(GetUserStocksAction());
  }, []);

  //function for loading icon and clering selected products after orders page
  React.useEffect(() => {
    setTimeout(() => {
      setReferLoading(false);
    }, 2000);
  }, []);

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    let validPayment = "";
    if (!BlocketPayments.has(200)) {
      validPayment = "RAZORPAY";
      setPayment(paymentTypeWallet.RAZORPAY);
    } else if (
      (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(103))
    ) {
      validPayment = "PAYSHARP";
    } else if (!BlocketPayments.has(300)) {
      validPayment = "WALLET";
      setPayment(paymentTypeWallet.WATER_WALLET);
    }
    setPaymentType(validPayment);
  }, []);

  //useEffect for getting payment block details
  React.useEffect(() => {
    dispatch(GetPaymentBlockDetails());
    return () => {
      dispatch(ResetComponentState());
    };
  }, []);

  //useEffect for getting payment block details
  React.useEffect(() => {
    if (window.innerWidth > 600) {
      setUpiUserOption("1");
      setPayType(200);
    }
    dispatch(ResetComponentState());
  }, []);

  //function to hit update api after getting payment sucess responce from orderstatus api
  React.useEffect(() => {
    if (
      (walletDetails?.paymentData?.data?.payment_status === "SUCCESS" &&
        !refundPaymentFlag) ||
      (walletDetails?.paymentData?.data?.status === "SUCCESS" &&
        !refundPaymentFlag)
    ) {
      dispatch(GetUserStocksAction());
      setRefundPaymentFlag(true);
    }
  }, [walletDetails.paymentData]);

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    if (walletDetails?.walletAmount >= depositData?.extraDeposiData?.amount) {
      if (
        !BlocketPayments.has(101) &&
        !BlocketPayments.has(100) &&
        window.innerWidth <= 600
      ) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("2");
        setPayType(100);
      } else if (!BlocketPayments.has(102) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("1");
        setPayType(200);
      } else if (!BlocketPayments.has(300)) {
        setPaymentType("WALLET");
        setPayment(paymentTypeWallet.WATER_WALLET);
      } else if (!BlocketPayments.has(200)) {
        setPayment(paymentTypeWallet.RAZORPAY);
        setPaymentType("RAZORPAY");
      } else if (!BlocketPayments.has(103) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("3");
        setPayType(100);
      }
    }

    if (depositData?.extraDeposiData?.amount !== undefined) {
      setFinalPrice(parseFloat(depositData?.extraDeposiData?.amount));
    }
  }, [depositData?.extraDeposiData?.amount]);

  //Useeffect for getting waterwallet payment method
  React.useEffect(() => {
    if (walletDetails?.isWalletSucess) {
      setPaymentType("PAYSHARP");
      dispatch(GetUserStocksAction());
      setOpenDepositSucessDialog(true);
      dispatch(ResetComponentState());
    }
  }, [walletDetails]);

  // useEffect to calculate final price for multipayment commented due to hold in release
  React.useEffect(() => {
    if (
      useWalletBalancePayFlagforDeposit &&
      walletDetails?.walletAmount >= 1 &&
      bookingpaymentFlag &&
      amountEntered > walletDetails?.walletAmount &&
      amountEntered - parseFloat(walletDetails?.walletAmount) >= 1
    ) {
      CustomConsole(amountEntered + " " + "amountEntered");
      CustomConsole(
        walletDetails?.walletAmount + " " + "walletDetails?.walletAmount"
      );
      CustomConsole(amountEntered - walletDetails?.walletAmount);
      setEnteredPayAmount(
        parseFloat(
          (amountEntered - parseFloat(walletDetails?.walletAmount)).toFixed(2)
        )
      );
    } else if (!useWalletBalancePayFlagforDeposit && bookingpaymentFlag) {
      setEnteredPayAmount(parseFloat(depositData?.extraDeposiData?.amount));
    }
  }, [useWalletBalancePayFlagforDeposit, bookingpaymentFlag]);

  React.useEffect(() => {
    if (paymentTriggerFlag) {
      if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else if (upiUserOption !== "3") {
        setpaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      }
      setpaymentTriggerFlag(false);
    }
  }, [paymentTriggerFlag]);

  //useEffect to get return can order details

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          marginTop: "4rem",
          height: "auto",
          marginBottom: "4rem",
        }}
      >
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {depositDetails?.user_stock?.length > 0 ? (
            <>
              <Grid
                item
                xs={5}
                md={3}
                sx={{
                  height: "80vh",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none", // Webkit browsers
                  },
                }}
              >
                <SkuDetailsProfile setSelectedData={setSelectedData} />
              </Grid>
              <Grid item xs={7} md={9}>
                <DepositDetailsSku
                  isUpiIdValid={isUpiIdValid}
                  upiUserOption={upiUserOption}
                  selectedData={selectedData}
                  setOrderId={setOrderId}
                  setBookingPaymentFlag={setBookingPaymentFlag}
                  setPaymentType={setPaymentType}
                  paymentType={paymentType}
                  setEnteredPayAmount={setEnteredPayAmount}
                  amountEntered={amountEntered}
                  setpaymentFlag={setpaymentFlag}
                  payType={payType}
                  openExtraDepositDialog={openExtraDepositDialog}
                  setOpenExtraDepositDialog={setOpenExtraDepositDialog}
                />
              </Grid>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                width: "100vw",
              }}
            >
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography
                sx={{ color: appColors.darkGray, textAlign: "center" }}
              >
                Please Start Booking!
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
      <MyWallet
        myWalletInBooking={false}
        setpromoCode={setpromoCode}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        setpaymentFlag={setpaymentFlag}
        promoCode={promoCode}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountEntered}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        setAmountToAdd={setEnteredPayAmount}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
        orderId={orderId}
      />
      {bookingpaymentFlag && (
        <ExtraDepositPayDialog
          orderPaymentMethod={orderPaymentMethod}
          open={bookingpaymentFlag}
          setsubcribePaymentFlag={setBookingPaymentFlag}
          setPaymentType={setPaymentType}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          myWalletInBooking={false}
          setpaymentFlag={setpaymentFlag}
          upiId={upiId}
          setrazorPayFlag={setrazorPayFlag}
          setPerWeekPrice={amountEntered}
          setUpiId={setUpiId}
          upiIdVPAObj={upiIdVPAObj}
          setUpiIdVPAObj={setUpiIdVPAObj}
          setVerifyUpiIdClicked={setVerifyUpiIdClicked}
          verifyUpiClicked={verifyUpiClicked}
          payment={payment}
          setPayment={setPayment}
          upiUserOption={upiUserOption}
          setUpiUserOption={setUpiUserOption}
          setUpiPayUserOption={setUpiPayUserOption}
          amountEntered={amountEntered}
          setRazorPayAmount={amountEntered}
          payType={payType}
          paymentTypeWallet={paymentTypeWallet}
          verifyBtn={verifyBtn}
          setVerifyBtn={setVerifyBtn}
          setPayType={setPayType}
          isUpiIdValid={isUpiIdValid}
          setIsUpiIdValid={setIsUpiIdValid}
          paymentFlag={paymentFlag}
          opean={opean}
          setOpean={setOpean}
          setMyWalletInBooking={setMyWalletInBooking}
          amountToAdd={amountEntered}
          setAmountToAdd={setEnteredPayAmount}
          setPayFor={setPayFor}
          setPayOptionCheck={setPayOptionCheck}
          choosenPartial={choosenPartial}
          setChoosenPartial={setChoosenPartial}
          perWeekPrice={amountEntered}
          setUpiPay={setUpiPay}
          pageName="deposit"
          finalPrice={finalPrice}
          useWalletBalancePayFlag={useWalletBalancePayFlagforDeposit}
          setUseWalletBalancePayFlag={setUseWalletBalancePayFlagforDeposit}
          multiplePaymentFlag={multiplePaymentFlag}
          setpaymentTriggerFlag={setpaymentTriggerFlag}
        />
      )}

      <DepositSucess
        open={depositSucessDialog}
        setOpenDepositSucessDialog={setOpenDepositSucessDialog}
      />
      {toastMsgDetails.toastContainerFlag ? <ToastMsgCotainer /> : ""}
    </>
  );
}
