// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconListManager from "../../Assets/AppTheme/appIcons";
import referDark from "../../Assets/newMobileIcon/darkTheme/Full Menu/Refer & Earn.svg";
import subScribe from "../../Assets/newMobileIcon/darkTheme/Full Menu/Subscriptions.svg";
import scanIconDark from "../../Assets/newMobileIcon/darkTheme/Home/SCAN.svg";
import menuIconDark from "../../Assets/newMobileIcon/darkTheme/Home/MENU.svg";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import MenuOptionDialog from "./menuDialogBox";
import "./footer.css";
import appColors from "../../Assets/AppTheme/appColors";
import { ReactComponent as Home } from "../../Assets/newMobileIcon/home.svg";
import FeatureDiscoveryPrompt from "../FeatureDiscoveryPrompt/FeatureDiscoveryPrompt";
import { Box } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function FooterAppBarMobile(props) {
  const { pathname, search } = useLocation();
  const pageTitle = pathname.split("/dashboard/");
  const name = new URLSearchParams(search).get("rechargeValue");
  const [activeIndex, setActiveIndex] = useState(2);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory();
  const handleOpenDialog = () => {
    props.setmenuDialogOpean(true);
  };
  const IconList = IconListManager();

  //Useeffect for setting active to list item
  React.useEffect(() => {
    let list = document.querySelectorAll(".list");
    function setActiveItem(activeIndex) {
      list.forEach((item, i) => {
        if (i === activeIndex) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });
    }
    // Call setActiveItem with the initial active index
    setActiveItem(activeIndex);
  }, [activeIndex]);

  //Function for closing a dialog box
  const handleCloseDialog = () => {
    props.setmenuDialogOpean(false);
  };

  //Function for redirection to mobile screen
  const handelHome = () => {
    history.push("/mobile");
  };

  //useEffect for getting window size for transaction of icon button
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // // Calculate the transform value based on the active index and window width
  // Calculate the transform value based on the active index and window width
  // useEffect(() => {
  //   const indicator = document.querySelector(".indicator");
  //   const transformValue = `translateX(calc(${
  //     (windowWidth - 60) / 4
  //   }px * ${activeIndex}))`;
  //   if (indicator) {
  //     indicator.style.transform = transformValue;
  //   }
  // }, [activeIndex, windowWidth]);

  // Update the active index based on your logic (e.g., user interactions)
  const handleSetActiveIndex = (index) => {
    functionSwitchPage(index);
  };

  //Function for changing stroke color of Refer and Earn svg icon
  function changeStrokeColor(index) {
    if (index === 1) {
      const svgElement = document.getElementById("Refer_Earn"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path").forEach((path) => {
          path.setAttribute("stroke", "#fff"); // Change the stroke color to red (#FF0000)
        });
      }
    } else {
      const svgElement = document.getElementById("Refer_Earn"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path").forEach((path) => {
          path.setAttribute("stroke", "#068fff"); // Change the stroke color to red (#FF0000)
        });
      }
    }
  }

  //Function for changing stroke color of Scan svg icon
  function changeStrokeColorScan(index) {
    if (index === 3) {
      const svgElement = document.getElementById("SCAN"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#fff"); // Change the stroke color to red (#FF0000)
        });
      }
    } else {
      const svgElement = document.getElementById("SCAN"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#068fff"); // Change the stroke color to red (#FF0000)
        });
      }
    }
  }

  //Function for changing stroke color of Subscribe svg icon
  function changeStrokeColorSubscribe(index) {
    if (index === 3) {
      const svgElement = document.getElementById("subscribe"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#fff"); // Change the stroke color to red (#FF0000)
        });
      }
    } else {
      const svgElement = document.getElementById("subscribe"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#068fff"); // Change the stroke color to red (#FF0000)
        });
      }
    }
  }

  //Function for changing stroke color of Subscribe svg icon
  function changeStrokeMenu(index) {
    if (index === 3) {
      const svgElement = document.getElementById("menu"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#fff"); // Change the stroke color to red (#FF0000)
        });
      }
    } else {
      const svgElement = document.getElementById("menu"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelectorAll("path, rect").forEach((element) => {
          element.setAttribute("stroke", "#068fff"); // Change the stroke color to red (#FF0000)
        });
      }
    }
  }
  //Function for changing stroke color of Home svg icon
  function changeStrokeColorHome(index) {
    if (index === 2) {
      const svgElement = document.getElementById("home"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelector("path").setAttribute("fill", "#fff"); // Change the fill color to red (#FF0000)
      }
    } else {
      const svgElement = document.getElementById("home"); // Get the SVG element by its ID
      if (svgElement) {
        svgElement.querySelector("path").setAttribute("fill", "#068fff"); // Change the fill color to red (#FF0000)
      }
    }
  }

  //Function for redirection to route based on click
  function functionSwitchPage(index) {
    switch (index) {
      case 0:
        history.push("/dashboard/subscriptionList");
        break;
      case 1:
        history.push("/dashboard/referandearn");
        break;
      case 2:
        handelHome();
        break;
      case 3:
        <Link to="/dashboard/qrscan" />;
        break;
      case 4:
        history.push("/dashboard/menu");
        break;
    }
  }

  //useeffect for identifing route and setting icon state for transaction
  React.useEffect(() => {
    if (pageTitle[1] === "referandearn") {
      setActiveIndex(1);
      changeStrokeColor(1);
      changeStrokeColorScan(1);
      changeStrokeColorHome(1);
      changeStrokeColorSubscribe(1);
    } else if (pageTitle[1] === "qrscan") {
      setActiveIndex(3);
      changeStrokeColorScan(3);
      changeStrokeColorHome(3);
      changeStrokeColor(3);
      changeStrokeColorSubscribe(3);
    } else if (
      pageTitle[1] === "subscriptionList" ||
      pageTitle[1] === "subscribe"
    ) {
      setActiveIndex(0);
      changeStrokeColorScan(0);
      changeStrokeColorHome(0);
      changeStrokeColor(0);
      changeStrokeColorSubscribe(0);
    } else if (pageTitle[0] === "/mobile") {
      setActiveIndex(2);
      changeStrokeColorHome(2);
      changeStrokeColorScan(2);
      changeStrokeColor(2);
      changeStrokeColorSubscribe(2);
    } else {
      setActiveIndex(4);
      changeStrokeColorHome(4);
      changeStrokeColorScan(4);
      changeStrokeColor(4);
      changeStrokeColorSubscribe(4);
      changeStrokeMenu(4);
    }
  }, [pathname, search]);

  return (
    <>
      <Box className="navigation">
        <ul className="unorderList">
          <Link to="/dashboard/subscriptionList">
            <li className="list">
              <a href="#">
                <FeatureDiscoveryPrompt
                  setmenuDialogOpean=""
                  setPromptStates={props.setPromptStates}
                  open={
                    props.promptStates?.bookWater !== undefined
                      ? props.promptStates.bookWater
                      : false
                  }
                  backgroundColor={appColors.textColorNew}
                  componentState="bookWater"
                  triggerState="scanQR"
                  title="Subscribe Your Order"
                  description="Let us plan your water delivery schedule for you."
                  subdescription="Your cans can be delivered on specific days of the week."
                  subdescription1="Subscribe and get your orders booked automatically."
                >
                  <span className="icon">
                    {activeIndex !== 0 ? (
                      <img
                        style={{ height: "2rem", width: "2rem" }}
                        src={IconList.subcribeIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          height: "2rem",
                          width: "2rem",
                        }}
                        src={subScribe}
                        alt=""
                      />
                    )}
                  </span>
                </FeatureDiscoveryPrompt>
                {activeIndex !== 0 && <span className="text">SUBSCRIBE</span>}
              </a>
            </li>
          </Link>
          <Link to="/dashboard/referandearn">
            <li className="list">
              <a href="#" className="tourRefer">
                <FeatureDiscoveryPrompt
                  setmenuDialogOpean=""
                  setPromptStates={props.setPromptStates}
                  open={
                    props.promptStates?.referAndEarn !== undefined
                      ? props.promptStates.referAndEarn
                      : false
                  }
                  backgroundColor={appColors.textColorNew}
                  componentState="referAndEarn"
                  triggerState="menu"
                  title="Refer & Earn"
                  description="Water is essential for life, and so are friends."
                  subdescription="You can gift safe and pure water to your friends now."
                  subdescription1="Refer a friend to the BookWater app and earn rewards for yourself and your friend!."
                >
                  <span className="icon">
                    {activeIndex !== 1 ? (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={IconList.referIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={referDark}
                        alt=""
                      />
                    )}
                  </span>
                </FeatureDiscoveryPrompt>
                <span className="text">REFER</span>
              </a>
            </li>
          </Link>
          <li className="list active" onClick={() => handleSetActiveIndex(2)}>
            <a href="#">
              <span className="icon">
                <Home
                  className="iconcolorHome"
                  style={{ height: "2.2rem", width: "2.2rem" }}
                />
              </span>
              <span className="text">HOME</span>
            </a>
          </li>
          <Link to="/dashboard/qrscan">
            <li className="list">
              <a href="#" className="tourScan">
                <FeatureDiscoveryPrompt
                  setmenuDialogOpean=""
                  setPromptStates={props.setPromptStates}
                  open={
                    props.promptStates?.scanQR !== undefined
                      ? props.promptStates.scanQR
                      : false
                  }
                  backgroundColor={appColors.textColorNew}
                  componentState="scanQR"
                  triggerState="wallet"
                  title="Scan QR"
                  description="Now you know! Unlock the secrets of your BookWater can with a simple scan of the QR code on your can! "
                  subdescription="Stay informed on quality parameters of both, the water you are drinking and the can it is stored in."
                  subdescription1="Get usage insights and water quality reports using the QR code."
                >
                  <span className="icon">
                    {activeIndex !== 3 ? (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={IconList.scanIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={scanIconDark}
                        alt=""
                      />
                    )}
                  </span>
                </FeatureDiscoveryPrompt>
                <span className="text">SCAN</span>
              </a>
            </li>
          </Link>
          <Link to="/dashboard/menu">
            <li className="list">
              <a href="#" className="tourMenu">
                <FeatureDiscoveryPrompt
                  setmenuDialogOpean={props.setmenuDialogOpean}
                  setPromptStates={props.setPromptStates}
                  open={
                    props.promptStates?.menu !== undefined
                      ? props.promptStates.menu
                      : false
                  }
                  backgroundColor={appColors.textColorNew}
                  componentState="menu"
                  triggerState="address"
                  title="Menu"
                  description="Click on the Menu to access Subscription, Your Profile, Your Transactions and current and past Order details"
                  subdescription=""
                  subdescription1=""
                >
                  <span className="icon">
                    {activeIndex !== 4 ? (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={IconList.menuIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: "1.8rem", width: "1.8rem" }}
                        src={menuIconDark}
                        alt=""
                      />
                    )}
                  </span>
                </FeatureDiscoveryPrompt>
                <span className="text">MENU</span>
              </a>
            </li>
          </Link>
          <div class="indicator">
            <span></span>
          </div>
        </ul>
      </Box>
      <MenuOptionDialog
        open={props.menuDialogOpean}
        onClose={handleCloseDialog}
        promptStates={props.promptStates}
        setPromptStates={props.setPromptStates}
      />
    </>
  );
}
