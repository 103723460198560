// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { useDispatch, useSelector } from "react-redux";
import WaterWalletIcon from "../../../Assets/newMobileIcon/Water Wallet.svg";
import UPISvg from "../../../Assets/AppIcons/PGUPI.svg";
import razorPay from "../../../Assets/newMobileIcon/Booking/Cards.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import DownIcon from "../../../Assets/newMobileIcon/Booking/DownIcon.svg";
import scanQr from "../../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import ProceedButton from "../../../Components/Buttons/ProceedButton";
import { FooterStyles } from "../../Cards/SkuCards/cardStyles";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SkuBillOverViewDialog from "../../DialogBoxes/SkuDialogs/Booking/skuBillOverView";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import CustomConsole from "../../../coustomConsole";

export default function MobileBookingFooterSku({
  setPaymentDialogOpen,
  toggleDrawer,
  setpaymentFlag,
  handelBookingFunction,
  paymentType,
  amountEntered,
  walletPayDone,
  skuSubscribeOrderDetails,
  deliveryCharges,
  payType,
  upiUserOption,
  samedayDelivery,
  isUpiIdValid,
  useWalletBalancePayFlag,
  secondaryPayment,
  invalidProducts,
}) {
  const dispatch = useDispatch();
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const orderIdDetails = useSelector((state) => state.skuOrderIdDetails);
  const { pathname, search } = useLocation();
  const [billOverViewOpean, setBillOverViewOpean] = React.useState(false);
  const pageName = new URLSearchParams(search).get("pageName");
  const [buttonFlag, setButtonFlag] = React.useState(false);

  //useEffect to disable button if selected days is 0
  React.useEffect(() => {
    if (
      pageName === "subscribe" &&
      skuSubscribeOrderDetails?.selectedDays.length === 0
    ) {
      setButtonFlag(true);
    } else if (
      pageName === "subscribe" &&
      skuSubscribeOrderDetails?.selectedDays.length > 0
    ) {
      setButtonFlag(false);
    }
  }, [skuSubscribeOrderDetails?.selectedDays]);

  CustomConsole(skuSubscribeOrderDetails?.selectedDays);
  //function for handling payment and booking action
  const handelPaymentFlag = () => {
    // setpaymentFlag(true);
    handelBookingFunction();
    setButtonFlag(true);
    setTimeout(() => {
      setButtonFlag(false);
    }, 2000);
  };

  React.useEffect(() => {
    if (pageName === "booking") {
      if (orderIdDetails.orderIdDetails?.orderId.length > 0) {
        setButtonFlag(true);
      } else {
        setButtonFlag(false);
      }
    } else if (pageName !== "booking") {
      setTimeout(() => {
        setButtonFlag(false);
      }, 2000);
    }
  }, [orderIdDetails]);

  React.useEffect(() => {
    if (upiUserOption == "3") {
      if (pageName === "booking") {
        if (orderIdDetails.orderIdDetails?.orderId.length > 0) {
          setButtonFlag(true);
        } else {
          setButtonFlag(false);
        }
      } else if (pageName !== "booking") {
        setTimeout(() => {
          setButtonFlag(false);
        }, 2000);
      }
    }
  }, [buttonFlag]);
  //function for closing price break up dialog
  const handelClosePriceBreakUp = () => {
    setBillOverViewOpean(false);
  };

  //useEffect to disable button if selected days is 0
  React.useEffect(() => {
    if (
      (pageName === "subscribe" &&
        skuSubscribeOrderDetails?.selectedDays.length === 0) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        skuSubscribeOrderDetails?.selectedDays.length === 0)
    ) {
      setButtonFlag(true);
    } else if (
      (pageName === "subscribe" &&
        skuSubscribeOrderDetails?.selectedDays.length > 0) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        skuSubscribeOrderDetails?.selectedDays.length > 0)
    ) {
      setButtonFlag(false);
    } else if (pageName === null || pageName === undefined) {
      setButtonFlag(true);
    }
  }, [skuSubscribeOrderDetails?.selectedDays]);

  CustomConsole(payType);
  return (
    <>
      {billOverViewOpean ? (
        <>
          <Card
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 100,
              right: 0,
              backgroundColor: appColors.dialogColor,
              overflowY: "scroll",
            }}
          >
            <img
              src={DownIcon}
              onClick={() => setBillOverViewOpean(false)}
              style={{
                position: "absolute",
                top: -9,
                right: "45%",
                height: "2.3rem",
                width: "3rem",
                color: appColors.darkGray,
              }}
              alt=""
            />
            <SkuBillOverViewDialog
              deliveryCharges={deliveryCharges}
              onClose={handelClosePriceBreakUp}
            />
          </Card>
        </>
      ) : (
        ""
      )}

      <AppBar position="fixed" sx={FooterStyles.footerMain}>
        {(checkOutDetails?.discount > 0 &&
          !billOverViewOpean &&
          pageName &&
          !pageName.includes("editSubscribe") &&
          pageName !== "subscribe") ||
        !billOverViewOpean ? (
          <Box
            sx={{
              // height: "1.2rem",
              width: "100%",
              backgroundColor: appColors.textColorNew,
              borderRadius: ".5rem 0.5rem 0rem 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: ".75rem",
            }}
          >
            <Typography
              sx={{
                fontSize: ".75rem",
                backgroundColor: "#8a4cd6",
                width: "100%",
                borderRadius: ".5rem 0.5rem 0rem 0",
                textAlign: "center",
              }}
            >
              {checkOutDetails?.balance_to_pay >= 1 &&
                useWalletBalancePayFlag &&
                pageName &&
                checkOutDetails?.wallet_balance > 0 &&
                !pageName.includes("editSubscribe") &&
                pageName !== "subscribe" &&
                `₹${checkOutDetails?.wallet_balance} will be deducted from your wallet`}
            </Typography>
            <Typography
              sx={{
                fontSize: ".75rem",
                backgroundColor: appColors.textColorNew,
                textAlign: "center",
                borderRadius: ".5rem 0.5rem 0rem 0",
              }}
            >
              {checkOutDetails?.discount > 0 &&
                pageName &&
                !pageName.includes("editSubscribe") &&
                pageName !== "subscribe" &&
                `🎊You saved ₹${checkOutDetails?.discount} for this Order🎊`}
              {checkOutDetails?.discount > 0 && !samedayDelivery ? "" : ""}
              {!samedayDelivery ? "🎊Free Delivery Unlocked🎊" : ""}
            </Typography>
          </Box>
        ) : null}
        <Box sx={FooterStyles.footerIcons}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: true ? "center" : "space-between",
              gap: ".4rem",
              width: 1,
              height: "2rem",
            }}
          >
            {amountEntered > 0 && (
              <Box
                sx={{
                  minWidth: "11rem", // Set a fixed width for both boxes (50% each)
                  backgroundColor: "#fff",
                  color: appColors.darkTextColor,
                  fontWeight: "bold",
                  borderRadius: ".3rem",
                  marginRight: ".3rem",
                  height: "2.2rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  // padding: "0.5rem 0 0.5rem 0.5rem",
                  fontSize: "12px",
                  boxShadow: 2,
                }}
                onClick={() => setPaymentDialogOpen(true)}
              >
                <span style={{ padding: "0 0 0 .4rem" }}>Payment&nbsp;</span>
                <span>
                  <img
                    alt=""
                    src={
                      (payType === 100 &&
                        secondaryPayment === "PAYSHARP" &&
                        useWalletBalancePayFlag) ||
                      (paymentType === "PAYSHARP" && payType === 100)
                        ? UPISvg
                        : (payType === 200 &&
                            secondaryPayment === "PAYSHARP" &&
                            useWalletBalancePayFlag) ||
                          (paymentType === "PAYSHARP" && payType === 200)
                        ? scanQr
                        : paymentType === "RAZORPAY" ||
                          (secondaryPayment === "RAZORPAY" &&
                            useWalletBalancePayFlag)
                        ? razorPay
                        : WaterWalletIcon
                    }
                  />
                </span>
                <Box
                  style={{
                    backgroundColor: appColors.textColorNew,
                    height: "2.2rem",
                    width: "2rem",
                    color: "#fff",
                    borderRadius: ".3rem",
                    marginLeft: ".3rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <KeyboardArrowUpIcon />
                </Box>
              </Box>
            )}
            <Box
              onClick={() => setBillOverViewOpean(!billOverViewOpean)}
              sx={{
                width: "100%", // Set a fixed width for both boxes (50% each)
                backgroundColor: "#fff",
                color: "#fff",
                borderRadius: ".3rem",
                height: "2.2rem",
                boxShadow: 2,
              }}
            >
              <Typography
                sx={{
                  padding: "0 0 0 .5rem",
                  color: appColors.darkTextColor,
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  fontWeight: "bold",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Price Breakup&nbsp;
                <Box
                  style={{
                    backgroundColor: appColors.textColorNew,
                    height: "2.2rem",
                    width: "2rem",
                    color: "#fff",
                    borderRadius: ".3rem",
                    marginLeft: ".3rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <KeyboardArrowUpIcon />
                </Box>
              </Typography>
            </Box>
          </Toolbar>
          {amountEntered > 0 && pageName === "booking" && !walletPayDone ? (
            <Box
              sx={{
                width: "92%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <ProceedButton
                totalFare={
                  pageName === "booking" && amountEntered > 0
                    ? amountEntered
                    : "Pay"
                }
                disabled={buttonFlag || invalidProducts?.length > 0}
                onClick={() => handelPaymentFlag()}
              />
            </Box>
          ) : null}

          {pageName &&
          pageName === "subscribe" &&
          invalidProducts?.length == 0 ? (
            <Box
              sx={{
                width: "92%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <ProceedButton
                totalFare={
                  pageName === "subscribe" && amountEntered > 0
                    ? amountEntered
                    : "Subscribe"
                }
                disabled={buttonFlag || invalidProducts?.length > 0}
                onClick={() => handelPaymentFlag()}
              />
            </Box>
          ) : null}

          {pageName &&
          pageName.includes("editSubscribe") &&
          invalidProducts?.length == 0 ? (
            <Box
              sx={{
                width: "92%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <ProceedButton
                totalFare={
                  amountEntered > 0 ? amountEntered : "Update & Proceed"
                }
                disabled={buttonFlag || invalidProducts?.length > 0}
                onClick={() => handelPaymentFlag()}
              />
            </Box>
          ) : null}
        </Box>
      </AppBar>
    </>
  );
}
