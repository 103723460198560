/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { GetUserAdressBook } from "../../Redux/actions/settingsAction";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

export default function SelectAddressDetails({ Addressid, setAddressId }) {
  const dispatch = useDispatch();
  const addressDetails = useSelector((state) => state.settings);
  // //Useeffect for getting address details
  // React.useEffect(() => {
  //     dispatch(GetUserAdressBook());
  // }, [])

  //Function for handling address details
  const handleChange = (event) => {
    setAddressId(event.target.value);
  };

  return (
    <FormControl sx={{ width: { xs: "100%", sm: "320px" } }} size="small">
      <InputLabel
        id="demo-select-small-label"
        sx={{ color: appColors.darkGray, fontSize: appTheme.fontSizeMedium }}
      >
        Select Address
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={Addressid}
        label="Select Address"
        onChange={handleChange}
        sx={{
          // Update to your desired background color
          color: appColors.darkGray,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: appColors.secondaryCard,
            },
          },
        }}
      >
        {addressDetails.addresses.map((data, index) => (
          <MenuItem
            key={index} // Add a unique key for each MenuItem
            value={data.address_id}
            sx={{
              bgcolor: appColors.secondaryCard,
              color: appColors.darkGray,
              "&.Mui-selected": {
                bgcolor: appColors.lightblue,
                color: "black",
              },
              "&.Mui-selected:hover": {
                bgcolor: appColors.lightblue,
                color: "black",
              },
              "&:hover": {
                bgcolor: appColors.lightblue,
                color: "black",
              },
            }} // Style changes on hover
          >
            <div>
              {data.address_type}
              <br />
              {data.address_line1}
              <br />
              {data.address_line2 && (
                <>
                  {data.address_line2}
                  <br />
                </>
              )}
              {data.pincode}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
