/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import CustomConsole from "../../coustomConsole";
//Pay through RazorPay
// export const PayThroughRazorPay = (razorPayDetails, paymentStatus) => {
//   CustomConsole("----PayThroughRazorPay--------");
//   CustomConsole(JSON.stringify(razorPayDetails));
//   CustomConsole("----paymentstatus----");
//   CustomConsole(JSON.stringify(paymentStatus));
//   let api = useAxios();
//   return async (dispatch) => {
//     try {
//       let response = await api.post(
//         `/PM/payments/webhook/razorpay`,
//         razorPayDetails
//       );

//       CustomConsole("--------PaybyRazorPay Redux--------");
//       CustomConsole(response);
//       if (response.status === AppMetaData.httpStatus.SUCCESS) {
//         try {
//           let response2 = await api.post(`/PM/Razorpayments/process/callback`, {
//             order_id: razorPayDetails.order_id,
//             payment_method: "RAZORPAY",
//             payment_status: paymentStatus,
//             payment_type: razorPayDetails.payment_type,
//             reason: "PAID",
//           });
//           // CustomConsole("--------PaybyRazorPay Inside Redux--------");
//           // CustomConsole(razorPayDetails.order_id);
//           dispatch({
//             type: actionType.PAY_BY_RAZORPAY,
//             payload: { data: response2.data, statusFlag: true },
//           });
//         } catch (error) {
//           CustomConsole("Error: " + JSON.stringify(error));
//         }
//       }
//     } catch (error) {
//       CustomConsole("Error: " + JSON.stringify(error));
//     }
//   };
// };

// {razorpay_signature,razorpay_order_id,transaction_id,amount,user_id,phone,email,payment_type,payment_status,reason,payment_method,deposit_details}
export const PayThroughRazorPayNew = (razorPayObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/razorpay/payment`, {
        razorpay_signature: razorPayObj.razorpay_signature,
        razorpay_order_id: razorPayObj.razorpay_order_id,
        transaction_id: razorPayObj.transaction_id,
        amount: razorPayObj.amount,
        user_id: razorPayObj.user_id,
        phone: razorPayObj.phone,
        email: razorPayObj.email,
        payment_type: razorPayObj.payment_type,
        payment_status: razorPayObj.payment_status,
        reason: razorPayObj.reason,
        payment_method: razorPayObj.reason,
        deposit_details: razorPayObj.deposit_details,
        order_id: razorPayObj.order_id,
        transactionDate: razorPayObj.transactionDate,
        promo_code:
          razorPayObj.promo_code === undefined ? "" : razorPayObj.promo_code,
      });
      CustomConsole(`PayThroughRazorPayNew response here`);
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_RAZORPAY_NEW,
        payload: { data: response.data, statusFlag: true },
      });
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const PaythroughPaysharpUPI = (upiPayObj) => {
  let api = useAxios();
  CustomConsole(upiPayObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/payments/upi`, {
        orderId: upiPayObj.orderId,
        amount: upiPayObj.amount,
        pay_type: upiPayObj.pay_type,
        promo_code:
          upiPayObj.promo_code === undefined ? "" : upiPayObj.promo_code,
      });
      CustomConsole("-----upi response----");
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_PAYSHARP_UPI,
        payload: { data: response.data, statusFlag: false },
      });
    } catch (error) {
      dispatch({
        type: actionType.PAY_BY_PAYSHARP_UPI,
        payload: { data: "Payment Failed", statusFlag: false },
      });
      toast.dark("Payment Request failed please contact Bookwater support", {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};
export const PaythroughVPAPaysharpUPI = (upiID) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/payments/upi/validate`, {
        customerVPA: upiID,
      });
      CustomConsole("-----VPA upiID response----");
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_VPA_PAYSHARP_UPI,
        payload: { data: response.data, statusFlag: true },
      });
      if(response?.data?.valid_data?.isValid){
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: "UPI ID verified successfully",
            },
          },
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const PaythroughVPAPaysharpUPIRequest = (upiIdVPAObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/payments/upi/request`, {
        orderId: upiIdVPAObj.orderId,
        amount: upiIdVPAObj.amount,
        customerVPA: upiIdVPAObj.customerVPA,
        promo_code:
          upiIdVPAObj.promo_code === undefined ? "" : upiIdVPAObj.promo_code,
      });
      CustomConsole("-----VPA upiID response after verification----");
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_VPA_PAYSHARP_UPI_REQUEST,
        payload: { data: response.data, statusFlag: true },
      });
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const PaythroughUPIOrderStatus = (orderId) => {
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/upi/orderStatus`, {
        orderId: orderId,
      });
      CustomConsole("-----UPI pay order status response----");
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_UPI_ORDER_STATUS,
        payload: { data: response.data, statusFlag: true },
      });
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const PaythroughRazorOrderStatus = (orderId, RazorOrderId) => {
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/upi/orderStatus`, {
        order_id: orderId,
        rp_order_id: RazorOrderId,
      });
      CustomConsole("-----UPI pay order status response----");
      CustomConsole(response);
      dispatch({
        type: actionType.PAY_BY_UPI_ORDER_STATUS,
        payload: { data: response.data, statusFlag: true },
      });
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};
//Enable Paysharp For the Order To Pay through Paysharp
export const UpdatePaymentMethodAsPaysharp = (data) => {
  CustomConsole("----UpdatePaymentMethodAsPaysharp--------");
  CustomConsole(data);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/updatePaysharphandle`, data);
      // CustomConsole("--------PaybyRazorPay Redux--------");
      // CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        var message = "";
        if (data.paysharphandle) {
          message = "Paysharp Enabled for Order ID: " + data.order_id;
        } else {
          message = "Paysharp Disabled for Order ID: " + data.order_id;
        }
        toast.dark(message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        CustomConsole(response);
        dispatch({
          type: actionType.PAYSHARP_HANDLE_STATUS,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

//Pay through Wallet for an Order
export const PayThroughWallet = (data) => {
  // CustomConsole("----PayThroughWallet--------");
  // CustomConsole(data);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/payments/webhook/waterWalletPay`,
        data
      );
      // CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: `Payment of Rs.${data.amount} for Order Id: ${data.order_id} successfully paid`,
            },
          },
        });
        // CustomConsole(response);
        dispatch({
          type: actionType.PAY_THROUGH_WALLET,
          payload: { data: response.data, statusFlag: true },
        });
        dispatch(GetWalletDetails())
      }
    } catch (error) {
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg:
              error?.response?.data?.message === undefined
                ? "Error in wallet pay please try later"
                : error?.response?.data?.message,
          },
        },
      });
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

//Enable Paysharp For the Order To Pay through Paysharp
export const GetWalletDetails = () => {
  CustomConsole("----GetWalletDetails--------");
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    try {
      let response = await api.get(`PM/payments/accounts/wallet`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_WALLET_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};
