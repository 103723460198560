/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import IconListManager from "../../Assets/AppTheme/appIcons";
import { useDispatch, useSelector } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useScreenSize from "../../Components/Utils/mobileScreen";
import StopIcon from "@mui/icons-material/Stop";
import {
  GetProductsByAddress,
  SelectedProductaction,
  SetSelectedAddress,
  SetSelectedPartialAddress,
} from "../../Redux/actions/skuBookingAction";
import Biconcard from "../../Assets/MobileTheme/Water wallet without Card Background.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import appColors from "../../Assets/AppTheme/appColors";
import {
  GetSubscriptinOrderDetailsSku,
  SetSelectedDaysSubscribe,
  StopPauseSubscriptionOrder,
} from "../../Redux/actions/skuSubscribeAction";
import moment from "moment";
import {
  CheckOutActionLoading,
  SubscribeCheckOutActionLoading,
} from "../../Redux/actions/checkOutAction";
import { GetUserAdressBook } from "../../Redux/actions/settingsAction";
import CustomConsole from "../../coustomConsole";
import NotificationInfoDialog from "../../Components/DialogBoxes/info/notificationDialog";
import appMessages from "../../Assets/AppMeta/appMessages";
const styles = {
  header: {
    fontSize: ".9rem",
    color: appColors.headerTextColor,
    fontWeight: "bold",
  },
  subHeader: {
    fontSize: ".8rem",
    color: appColors.Textgray,
    fontWeight: "bold",
    width: "100%",
  },
};
export default function ListOfSubscriptionOrders() {
  const iconList = IconListManager();
  const isMobile = useScreenSize();
  const history = useHistory();
  const dispatch = useDispatch();
  //settingsAction
  const addressDetails = useSelector((state) => state.settings.addresses);
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  const [checkOutButtonClick, setCheckOutButtonClick] = React.useState(false);
  const [subscribtionId, setSubscriptionId] = React.useState(0);
  const [openDialogBox, setOpenDialogBoxOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  //useEffect for getting subscribe orderdetails
  React.useEffect(() => {
    dispatch(GetSubscriptinOrderDetailsSku());
  }, []);

  CustomConsole(skuSubscribeOrderDetails);

  //function for handel click edit
  const handelClickEdit = (data) => {
    CustomConsole(data);
    // Specify the key to be removed
    const keyToRemove = "crates";
    // Use map to iterate through each object in the array
    let arrayOfDetails = data?.order_details?.products.map((obj) =>
      Object.keys(obj)
        .filter((objKey) => objKey !== keyToRemove)
        .reduce((newObj, key) => {
          newObj[key] = obj[key];
          return newObj;
        }, {})
    );
    const selectedObj = {
      products: arrayOfDetails,
      booking_type: data?.order_details?.booking_type,
      address_id: data?.order_details?.delivery_address?.address_id,
      coupon_code: "",
      water_drops: 0,
      payment_method: "RAZORPAY",
      is_otp_less: data?.order_details?.is_otp_less,
      delivery_date: moment()
        .tz("Asia/Kolkata")
        .add(10, "days")
        .format("YYYY-MM-DD"),
      is_auto_apply_water_drops: data?.order_details?.is_auto_apply_water_drops,
      is_auto_apply_coupon: data?.order_details?.is_auto_apply_coupon,
    };
    dispatch(SelectedProductaction(selectedObj));
    dispatch(SetSelectedDaysSubscribe(data?.subscription_details?.repeat_days));
    setSubscriptionId(data?.subscription_details?.subscription_id);
    dispatch(SubscribeCheckOutActionLoading(selectedObj));
    setCheckOutButtonClick(true);
    dispatch(
      SetSelectedAddress(data?.order_details?.delivery_address?.address_id)
    );
    localStorage.setItem(
      "addressId",
      data?.order_details?.delivery_address?.address_id
    );
  };

  //function for handling add subscribe order
  const handelAddSubscribeOrder = () => {
    history.push(`/dashboard/subscribe?pageName=addNew`);
    dispatch(SetSelectedDaysSubscribe([]));
  };

  React.useEffect(() => {
    if (
      checkOutDetails?.loading === false &&
      checkOutDetails?.checkOutDetails !== undefined &&
      checkOutDetails?.checkOutDetails?.valid_products?.length > 0 &&
      checkOutButtonClick
    ) {
      history.push(
        `/dashboard/checkout?pageName=editSubscribe_${subscribtionId}`
      );
      setCheckOutButtonClick(false);
    }
  }, [checkOutDetails?.loading]);

  //function for updating subscription
  const handelUpdateSubscribe = (data) => {
    if (data?.subscription_details?.is_pause === false) {
      const reqObj = {
        subscription_update_state: 200,
        subscription_ids: [data?.subscription_details?.subscription_id],
      };
      dispatch(StopPauseSubscriptionOrder(reqObj));
    } else {
      const reqObj = {
        subscription_update_state: 100,
        subscription_ids: [data?.subscription_details?.subscription_id],
      };
      dispatch(StopPauseSubscriptionOrder(reqObj));
    }
  };

  //function for updating subscription
  const handelStopSubscribe = () => {
    const reqObj = {
      subscription_update_state: 300,
      subscription_ids: [deleteData?.subscription_details?.subscription_id],
    };
    dispatch(StopPauseSubscriptionOrder(reqObj));
    setDeleteData({});
    setOpenInfoDialog(false);
  };

  //For getting user address details
  React.useEffect(() => {
    if (addressDetails?.length === 0) {
      dispatch(GetUserAdressBook());
    }
  }, []);

  //function to contain addresss in card
  const addressLength = (primaryAddress) => {
    const maxLength = window.innerWidth / 10;
    let truncatedAddress =
      primaryAddress?.address_line1 +
      " " +
      primaryAddress?.service_area +
      " " +
      primaryAddress?.pincode;
    CustomConsole(truncatedAddress.length);
    if (truncatedAddress !== undefined && truncatedAddress.length > maxLength) {
      return truncatedAddress.substring(0, maxLength) + "...";
    } else {
      return truncatedAddress;
    }
  };

  //useEffect for setting flag value as true
  React.useEffect(() => {
    if (!skuSubscribeOrderDetails?.updateOrderError) {
      skuSubscribeOrderDetails.updateOrderError = true;
    }
  }, [skuSubscribeOrderDetails?.updateOrderError]);

  //function to close dialog box
  const handleCloseDialog = () => {
    setOpenDialogBoxOpen(false);
  };

  //function to open dialog box
  const handelOpenDialogBox = (data) => {
    setDeleteData(data);
    setOpenInfoDialog(true);
  };

  //function to handel on off notification
  const handelInfoDialogClose = (flag) => {
    setOpenInfoDialog(flag);
  };
  return (
    <>
      <Grid
        container
        sx={{
          marginTop: { xs: "4.5rem" },
          paddingRight: "5px",
          paddingBottom: "20px",
          paddingLeft: "5px",
          marginBottom: { sm: "1rem", xs: "8rem" },
        }}
      >
        <Grid item xs={12}>
          <Button
            size="small"
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "16rem" },
              background: appColors.walletCard,
            }}
            onClick={handelAddSubscribeOrder}
          >
            Add New Subscription +
          </Button>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {skuSubscribeOrderDetails?.subscribeOrderDetails?.length > 0 ? (
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: appColors.headerTextColor,
                  fontWeight: "bold",
                  marginBottom: ".5rem",
                }}
              >
                My Subscriptions
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                {skuSubscribeOrderDetails?.subscribeOrderDetails?.map(
                  (data, index) => (
                    <Card
                      key={index}
                      sx={{
                        boxShadow: "5",
                        marginBottom: "1rem",
                        backgroundColor: appColors.cardColor,
                        position: "relative",
                        borderRadius: ".5rem",
                        width: { xs: "100%", sm: "20rem" },
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              minWidth: "20rem",
                            }}
                          >
                            {" "}
                            <Typography sx={styles.header}>
                              {data?.order_details?.delivery_address
                                ?.address_type === ""
                                ? "HOME"
                                : data?.order_details?.delivery_address
                                    ?.address_type}
                            </Typography>
                            <Link
                              size="small"
                              onClick={() => handelClickEdit(data)}
                              sx={{
                                color: appColors.headerTextColor,
                                fontWeight: "bold",
                                zIndex: 31,
                                position: "absolute",
                                top: 0,
                                right: 10,
                              }}
                            >
                              Edit
                            </Link>
                          </Box>
                          <Typography sx={styles.subHeader}>
                            {addressLength(
                              data?.order_details?.delivery_address
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%", // Set width to 100% to ensure it doesn't overflow
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography sx={styles.header}>Products</Typography>

                          {data?.order_details?.products?.map(
                            (product, index) => (
                              // Added return statement for JSX
                              <Typography sx={[styles.subHeader]} key={index}>
                                {product?.name !== undefined &&
                                product?.name !== null &&
                                product?.name !== ""
                                  ? product?.name
                                  : `${product?.material}, ${product?.capacity}`}
                              </Typography>
                            )
                          )}
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography sx={styles.header}>
                              Delivering Date
                            </Typography>
                            <Typography sx={styles.subHeader}>
                              {moment(
                                data?.subscription_details?.next_delivery_date
                              ).format("DD MMM YYYY")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography sx={styles.header}>
                              Order Type
                            </Typography>
                            <Typography sx={styles.subHeader}>
                              {data?.order_details?.booking_type === 2
                                ? "One-time Purchase"
                                : "Refill Purchase"}
                            </Typography>
                          </Box>
                        </Box>
                        <img
                          alt=""
                          src={Biconcard}
                          style={{
                            position: "absolute",
                            height: "20rem",
                            objectFit: "contain",
                            zIndex: 1,
                            top: 0,
                          }}
                        />
                      </CardContent>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "space-around" }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            backgroundColor: appColors.textColorNew,
                            color: "#fff",
                            zIndex: 1,
                          }}
                          onClick={() => handelUpdateSubscribe(data)}
                        >
                          {data?.subscription_details?.is_pause === false ? (
                            <PauseIcon />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            bgcolor: appColors.Failed,
                            color: "#fff",
                            zIndex: 1,
                          }}
                          onClick={() => handelOpenDialogBox(data)}
                        >
                          <StopIcon />
                        </Button>
                      </CardActions>
                    </Card>
                  )
                )}
              </Box>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                width: "100vw",
              }}
            >
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography
                sx={{ color: appColors.darkGray, textAlign: "center" }}
              >
                Start Subscription by clicking Add New Subscription
              </Typography>
            </Box>
          )}
        </Grid>
        <NotificationInfoDialog
          setOpenInfoDialog={setOpenInfoDialog}
          openInfoDialog={openInfoDialog}
          infoDialogTextHeading={appMessages.subcribtionDeleteTitle}
          infoDialogTextMain={appMessages.subscribtionDeleteContent}
          handelProceedAction={handelStopSubscribe}
          handelInfoDialogClose={handelInfoDialogClose}
        />
      </Grid>
    </>
  );
}
