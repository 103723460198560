/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import { getFiles } from "./fileHandlingActions";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";

//Get the User Profile data
export const GetUserProfile = () => {
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.get(`/SCM/profile`);
    if (response.status === AppMetaData.httpStatus.SUCCESS) {
      if (
        response.data.profile.consumer.profile_pic_url !== undefined &&
        response.data.profile.consumer.profile_pic_url
      ) {
        await dispatch(
          getFiles(response.data.profile.consumer.profile_pic_url)
        );
      }
      dispatch({
        type: actionType.GET_USER_PROFILE,
        payload: response.data,
      });
    } else {
      // console.log("ELSE: Response in GetUserProfile Action Error");
    }
  };
};

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description This Function will perform the sending OTP to Phone to verify and then proceed to create new account
 * @date 14/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
//Action for deleting consumer account
export const DeleteAccountAction = () => {
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post(`SCM/consumer/delete_account`);
    if (response.status === AppMetaData.httpStatus.SUCCESS) {
      if (response.data.error === false)
        dispatch({
          type: actionType.SIGN_OUT,
        });
    } else {
      // console.log("ELSE: Response in GetUserProfile Action Error");
    }
  };
};

//function to trigget app rating request
export const ConsumerRatingState = (data) => {
  return {
    type: actionType.RATING_BY_CONSUMER_STATE,
    payload: data,
  };
};

//function to update consumer app rating
export const ConsumerAppRating = (reqObj) => {
  const api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/consumer/app/rating`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        if (response?.error === false) {
          dispatch({
            type: actionType.TOAST_MSG_CONTAINER,
            payload: {
              data: {
                error: false,
                containerFlag: true,
                msg: response.data.msg,
              },
            },
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
