/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import UPISvg from "../../../Assets/AppIcons/PGUPI.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import scanQr from "../../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import rupee from "../../../Assets/newMobileIcon/RupeePayment.svg";
import { useDispatch, useSelector } from "react-redux";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import razorPay from "../../../Assets/newMobileIcon/Booking/Cards.svg";
import ListItemButton from "@mui/material/ListItemButton";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import appColors from "../../../Assets/AppTheme/appColors";
import { Alert, Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import waterWalletSvg from "../../../Assets/newMobileIcon/Water Wallet.svg";
import { GetPaymentBlockDetails } from "../../../Redux/actions/PaymentActionSku";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import CustomConsole from "../../../coustomConsole";
import { ClearDepositDetailAction } from "../../../Redux/actions/DepositDetailsAction";
const useStyles = makeStyles((theme) => ({
  fullscreenDialog: {
    maxWidth: "100%",
    width: "100%",
  },
  halfscreenDialog: {
    maxWidth: "100%",
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExtraDepositPayDialog(props) {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const pageTitle = pathname.split("/dashboard/");
  const pageName = new URLSearchParams(search).get("pageName");
  const paymentBlockDetails = useSelector(
    (state) => state.paymentDetailsSku.paymentBlockData
  );
  const BlocketPayments = new Set(paymentBlockDetails);

  const screenWidth = window.innerWidth;
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [upiUserInput, setUpiUserInput] = React.useState(false);
  const [showUpiIdToEnter, setShowUpiIdToEnter] = React.useState(false);
  const payThroughRazor = useSelector((state) => state.paymentMethods);
  const [loadingToVerify, setLoadingToVerify] = React.useState(false);
  const [verifyUpiClicked, setVerifyUpiIdClicked] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [paymentValue, setPaymentValue] = React.useState("");
  const [upiHelper, setUpiHelper] = React.useState(false);
  const [error, setError] = React.useState("");
  const style = {
    button: {
      width: "40%",
      boxShadow: "5",
      marginBottom: "1rem",
      height: "50px",
      justifyContent: "center",
      borderRadius: "5px",
    },
    buttonScanQr: {
      width: "40%",
      boxShadow: "5",
      height: "50px",
      justifyContent: "center",
      borderRadius: "5px",
    },
    buttonWallet: {
      boxShadow: "5",
      width: "40%",
      height: "50px",
      justifyContent: "center",
      borderRadius: "5px",
    },
  };

  //function for closing payment dialog
  const handleClose = () => {
    props.setsubcribePaymentFlag(false);
    dispatch(ClearDepositDetailAction());
  };

  //use Effect for setting up a default payment method
  React.useEffect(() => {
    // props.setPayment(props.paymentTypeWallet.RAZORPAY);
    // props.setUpiUserOption("2");
    // props.setPayType(100);
    props.setIsUpiIdValid(false);
    props.setVerifyBtn(false);
  }, []);

  //useEffect for setting upi status
  React.useEffect(() => {
    if (verifyUpiClicked) {
      if (payThroughRazor.message.error === false) {
        setUpiHelper(!payThroughRazor.message.valid_data.isValid);
      }
      setVerifyUpiIdClicked(false);
    }
  }, [payThroughRazor]);

  //Function for selecting razorpay payment option
  const handleRazorPaySelect = (data) => {
    setPaymentMethod("RAZORPAY");
  };

  //function for handling upi pay option selection
  const handleUpiOptions = (value, method) => {
    CustomConsole(method+"extrapayment");
    CustomConsole(value+"extrapayment");
    props.setPayment(props.paymentTypeWallet.PAYSHARP);
    CustomConsole(props.upiPayUserOption === "3");
    props.setUpiUserOption(value);
    props.setPaymentType(method);
    if (value == "1") {
      props.setPayType(200);
      props.setIsUpiIdValid(false);
      props.setVerifyBtn(false);
      setShowUpiIdToEnter(false);
      if (props.pageName === "deposit") {
        props.setpaymentTriggerFlag(true);
      }
      handleClose();
    } else if (value == "2") {
      props.setPayType(100);
      props.setVerifyBtn(false);
      setShowUpiIdToEnter(false);
      if (props.pageName === "deposit") {
        props.setpaymentTriggerFlag(true);
      }
      handleClose();
    } else if (value == "3") {
      if (props.isUpiIdValid) {
        props.setPayType(100);
        props.setUpiPay(false);
        setShowUpiIdToEnter(true);
        if (props.pageName === "deposit") {
          props.setpaymentTriggerFlag(true);
        }
        handleClose();
      } else {
        setError("Please verify your UPI id to proceed ");
      }
    }
  };

  /**
   * @author Gowtham Prasath
   * @date 07/06/23
   * @description This function is get the payment type from form.
   * @param {method:string}
   * @return {""}
   */
  const paymentTypeFun = (method, value) => {
    setPaymentMethod(method);
    setPaymentValue(value);
  };

  //function for handling upi id verification
  const handleVerifyUpiID = () => {
    verifyUpiId(props.upiId);
    setVerifyUpiIdClicked(true);
    setPaymentMethod("PAYSHARP");
    setPaymentValue("3");
  };

  //function for handling upi id verification
  React.useEffect(() => {
    if (verifyUpiClicked === true) {
      CustomConsole("state after verify");
      if (payThroughRazor.message.error === false) {
        props.setIsUpiIdValid(payThroughRazor.message.valid_data.isValid);
        setUpiHelper(!payThroughRazor.message.valid_data.isValid);
        setLoadingToVerify(false);
      }
      CustomConsole(payThroughRazor);
      setVerifyUpiIdClicked(false);
    }
  }, [payThroughRazor]);

  //function for handling loading upi id spinner
  React.useEffect(() => {
    CustomConsole("upi verify api useEffect");
    if (verifyUpiClicked === true) {
      setLoadingToVerify(true);
    }
  }, [verifyUpiClicked]);

  //UseEffect for controlling spinner in upi id validity
  React.useEffect(() => {
    if (props.isUpiIdValid === true) {
      setLoadingToVerify(false);
    }
  }, [props.isUpiIdValid]);

  //function for handling upi id regex format
  const handleChangeUpiId = (e) => {
    // let reg2 = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}*$/
    let reg2 = /^[\w.-]+@[\w.-]+$/;
    var result = reg2.test(e.target.value);
    let userInp = e.target.value;
    CustomConsole(result);
    setUpiUserInput(result);
    props.setUpiId(userInp.trim());
    props.setIsUpiIdValid(false);
    setPaymentMethod("PAYSHARP");
    setPaymentValue("3");
  };

  const verifyUpiId = (upiId) => {
    props.setVerifyUpiIdClicked(true);
    props.setVerifyBtn(true);
    props.setUpiIdVPAObj({
      // orderId: currentOrderId,
      orderId: "",
      amount: 110,
      customerVPA: upiId,
    });
  };

  //function for opening neft payment dialog
  const handelNeft = () => {
    props.setOpenVbDialog(true);
    handleClose();
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: "13px", // Set the desired label color
    },
  };
  //useEffect for getting payment block details
  React.useEffect(() => {
    dispatch(GetPaymentBlockDetails());
  }, []);

  //function got handling wallet payment
  const handelWalletPayment = (data) => {
    setPaymentMethod(data);
  };

  //function to check wallet balance use flag
  const handelUseWalletBalance = (e) => {
    CustomConsole(e.target.checked);
    props.setUseWalletBalancePayFlag(e.target.checked);
  };
  CustomConsole(props.finalPrice > walletDetails.walletAmount);
  CustomConsole(walletDetails.walletAmount);

  //function to trigger payment action
  const handelPayButtonClick = () => {
    if (paymentMethod === "PAYSHARP") {
      handleUpiOptions(paymentValue, paymentMethod);
    } else if (paymentMethod === "RAZORPAY") {
      props.setPayment(props.paymentTypeWallet.RAZORPAY);
      props.setPaymentType(paymentMethod);
      props.setUpiUserOption("");
      props.setIsUpiIdValid(false);
      if (props.pageName === "deposit") {
        props.setpaymentTriggerFlag(true);
      }
      handleClose();
    } else if (paymentMethod === "WALLET") {
      props.setPayment(props.paymentTypeWallet.WATER_WALLET);
      props.setPaymentType(paymentMethod);
      props.setUpiUserOption("");
      if (props.pageName === "deposit") {
        props.setpaymentTriggerFlag(true);
      }
      handleClose();
    } else {
      setError("Please select payment method to proceed payment");
    }
  };

  //useEffect to close error state as false
  React.useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error]);

  CustomConsole(paymentMethod);
  CustomConsole(props.multiplePaymentFlag);
  //function to handel default payment method
  React.useEffect(() => {
    if (
      parseFloat(walletDetails?.walletAmount) >= props.finalPrice &&
      !BlocketPayments.has(300)
    ) {
      setPaymentMethod("WALLET");
    } else if (
      !BlocketPayments.has(101) &&
      !BlocketPayments.has(100) &&
      window.innerWidth <= 600 &&
      props.amountEntered >= 10
    ) {
      setPaymentMethod("PAYSHARP");
      setPaymentValue("2");
    } else if (
      !BlocketPayments.has(102) &&
      !BlocketPayments.has(100) &&
      props.amountEntered >= 10
    ) {
      setPaymentMethod("PAYSHARP");
      setPaymentValue("1");
    } else if (!BlocketPayments.has(200)) {
      setPaymentMethod("RAZORPAY");
    } else if (
      !BlocketPayments.has(103) &&
      !BlocketPayments.has(100) &&
      props.amountEntered >= 10
    ) {
      setPaymentMethod("PAYSHARP");
      setPaymentValue("3");
    }
  }, [props.amountEntered]);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: appColors.dialogBackGround }}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Box
          sx={{ backgroundColor: appColors.secondaryCard, minWidth: "250px" }}
        >
          <Box sx={{ background: appColors.secondaryCard, padding: "1rem" }}>
            <Typography
              sx={{
                color: appColors.secondaryText,
                fontSize: "1rem",
                fontWeight: "bold",
                marginBottom: ".5rem",
              }}
            >
              Select Payment Method
            </Typography>
            {error !== "" && (
              <Typography
                sx={{
                  color: appColors.white,
                  fontSize: ".8rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  bgcolor: appColors.Failed,
                  padding: "0 .3rem",
                }}
              >
                {error}
              </Typography>
            )}
            <Typography
              sx={{
                color: appColors.headerTextColor,
                fontSize: ".8rem",
                fontWeight: "bold",
                marginBottom: ".5rem",
              }}
            >
              {props.pageName !== "deposit"
                ? `An additional deposit of ₹ ${props.finalPrice} is required to edit
              your order.`
                : `Deposit Amount ₹ ${props.finalPrice}`}
            </Typography>
            {props.finalPrice > walletDetails?.walletAmount &&
            props.finalPrice - walletDetails?.walletAmount >= 1 &&
            parseInt(walletDetails?.walletAmount) > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  height: "3rem",
                  borderRadius: "5px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.9em",
                    fontWeight: "500",
                    textAlign: "left",
                    color: appColors.darkGray,
                    display: "flex",
                    alignItems: "center",
                  }}
                  // noWrap
                >
                  <Checkbox
                    size="small"
                    onClick={handelUseWalletBalance}
                    checked={props.useWalletBalancePayFlag}
                  />
                  {/* {useWaterDrop && promocodeData?.promoDiscount
                        ? `₹${promocodeData?.promoDiscount} Worth Water Drops Applied`
                        : "Redeem  WaterDrops"} */}
                  ₹{walletDetails.walletAmount} will be deducted from your
                  wallet
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: ".8rem" }}>
                {screenWidth <= 600 &&
                props.amountEntered >= 10 &&
                !BlocketPayments.has(101) &&
                !BlocketPayments.has(100) ? (
                  <ListItemButton
                    onClick={() => paymentTypeFun("PAYSHARP", "2")}
                    sx={[
                      style.button,
                      {
                        border:
                          paymentMethod == "PAYSHARP" && paymentValue == "2"
                            ? `2px solid ${appColors.textColorNew}`
                            : "#fff",
                      },
                    ]}
                  >
                    <ListItemAvatar>
                      <img
                        alt="Bookwater"
                        src={UPISvg}
                        style={{ width: "4rem", height: "3rem" }}
                      />
                    </ListItemAvatar>
                    <Box sx={{ color: appColors.Textgray }}>
                      <Typography sx={{ fontSize: ".70rem" }}>
                        Choose UPI App
                      </Typography>
                    </Box>
                  </ListItemButton>
                ) : (
                  ""
                )}
                {props.pageName === "wallet" && (
                  <ListItemButton
                    onClick={() => handelNeft()}
                    sx={style.buttonWallet}
                  >
                    <ListItemAvatar>
                      <img
                        alt=""
                        src={rupee}
                        style={{ height: "40px", width: "60px" }}
                      />
                    </ListItemAvatar>
                    <Box sx={{ fontSize: ".3rem", color: appColors.Textgray }}>
                      <Typography sx={{ fontSize: ".70rem" }}>
                        NEFT/IMPS
                      </Typography>
                    </Box>
                  </ListItemButton>
                )}

                {props.finalPrice <= walletDetails.walletAmount &&
                !BlocketPayments.has(300) ? (
                  <ListItemButton
                    onClick={() => handelWalletPayment("WALLET")}
                    sx={[
                      style.button,
                      {
                        border:
                          paymentMethod == "WALLET"
                            ? `2px solid ${appColors.textColorNew}`
                            : "#fff",
                      },
                    ]}
                  >
                    <ListItemAvatar>
                      <img
                        alt=""
                        src={waterWalletSvg}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </ListItemAvatar>
                    <Box sx={{ color: appColors.Textgray }}>
                      <Typography sx={{ fontSize: ".8rem" }}>Wallet</Typography>
                      <Typography
                        sx={{
                          fontSize: ".8rem",
                          color: appColors.headerTextColor,
                        }}
                      >
                        ₹ {parseInt(walletDetails?.walletAmount)}
                      </Typography>
                    </Box>
                  </ListItemButton>
                ) : null}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                {props.amountEntered >= 10 &&
                !BlocketPayments.has(102) &&
                !BlocketPayments.has(100) ? (
                  <ListItemButton
                    onClick={() => paymentTypeFun("PAYSHARP", "1")}
                    sx={[
                      style.buttonScanQr,
                      {
                        border:
                          paymentMethod == "PAYSHARP" && paymentValue == "1"
                            ? `2px solid ${appColors.textColorNew}`
                            : "#fff",
                      },
                    ]}
                  >
                    <ListItemAvatar>
                      <img alt="" src={scanQr} />
                    </ListItemAvatar>
                    <Box sx={{ color: appColors.Textgray }}>
                      <Typography sx={{ fontSize: ".70rem" }}>Scan</Typography>
                    </Box>
                  </ListItemButton>
                ) : null}
                {!BlocketPayments.has(200) && (
                  <ListItemButton
                    onClick={() => handleRazorPaySelect("RAZORPAY")}
                    sx={[
                      style.buttonWallet,
                      {
                        border:
                          paymentMethod == "RAZORPAY"
                            ? `2px solid ${appColors.textColorNew}`
                            : "#fff",
                      },
                    ]}
                  >
                    <ListItemAvatar>
                      <img alt="" src={razorPay} style={{ height: "30px" }} />
                    </ListItemAvatar>
                    <Box sx={{ fontSize: ".3rem", color: appColors.Textgray }}>
                      <Typography sx={{ fontSize: ".70rem" }}>
                        Cards &
                      </Typography>
                      <Typography sx={{ fontSize: ".70rem" }}>
                        NetBanking
                      </Typography>
                    </Box>
                  </ListItemButton>
                )}
              </Box>
            </Box>
            {props.amountEntered >= 10 &&
            !BlocketPayments.has(103) &&
            !BlocketPayments.has(100) ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: ".5rem 0 0.5rem 0",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter UPI ID"
                    value={props.upiId}
                    InputProps={{
                      sx: {
                        bgcolor: appColors.secondaryCard,
                        color: appColors.darkGray,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: appColors.darkGray,
                        },
                      },
                    }}
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={handleChangeUpiId}
                    size="small"
                  />
                  <Box
                    sx={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {true ? (
                      <>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: appColors.textColorNew,
                            border: "1px solid white",
                          }}
                          disabled={!upiUserInput}
                          onClick={handleVerifyUpiID}
                          endIcon={
                            props.isUpiIdValid ? <CheckCircleIcon /> : ""
                          }
                          startIcon={
                            loadingToVerify ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              ""
                            )
                          }
                          color={props.isUpiIdValid ? "success" : "primary"}
                        >
                          {props.isUpiIdValid ? "Verified" : "Verify"}
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                {props.isUpiIdValid ? (
                  <>
                    <Alert>Verified, Continue to pay!</Alert>
                  </>
                ) : props.isUpiIdValid === false && upiHelper ? (
                  <>
                    <Alert severity="error">Invalid UPI Id!</Alert>
                  </>
                ) : null}
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: ".5rem",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: appColors.Failed,
                  color: "#fff",
                  marginTop: "1rem",
                }}
              >
                Close
              </Button>

              <Button
                onClick={handelPayButtonClick}
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: appColors.textColorNew,
                  color: "#fff",
                  marginTop: "1rem",
                }}
              >
                Pay ₹{props.amountEntered}
              </Button>
            </Box>
          </Box>
          <img
            alt=""
            src={closeIcon}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              height: "30px",
            }}
            onClick={handleClose}
          />
        </Box>
      </Dialog>
    </>
  );
}
