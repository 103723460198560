/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
import PrimarySkuButton from "../../Buttons/PrimarySkuButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PromoDisclaimer({ open, setOpen, promoDisclimeMsg }) {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ width: 1 }}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: appColors.secondaryCard,
            color: appColors.headerTextColor,
            padding: "1rem 1rem 0 1rem",
            fontSize: appTheme.fontSizeLarge,
          }}
          id="customized-dialog-title"
        >
          Promo Code Details{" "}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ bgcolor: appColors.secondaryCard, color: appColors.darkGray }}
        >
          <Typography
            sx={{
              color: appColors.headerTextColor,
              fontWeight: "bold",
              textAlign: "center",
              fontSize: appTheme.fontSizexl,
            }}
          >
            {" "}
            {promoDisclimeMsg.promo_label}
          </Typography>
          <Typography
            gutterBottom
            sx={{
              textAlign: "center",
              color: appColors.darkGray,
              fontSize: appTheme.fontSizeLarge,
            }}
          >
            Save up to <b>₹ {promoDisclimeMsg.promo_offer_value}</b> with this
            code.
          </Typography>
          <Typography
            gutterBottom
            sx={{
              textAlign: "center",
              color: appColors.Textgray,
              fontSize: appTheme.fontSizeLarge,
            }}
          >
            <b>Disclaimer:</b> {promoDisclimeMsg.promo_disclaimer}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: appColors.secondaryCard,
            color: appColors.darkGray,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PrimarySkuButton
            btnName="Close"
            color={appColors.textColorNew}
            handleButtonClick={handleClose}
          />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
