/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author 
 * @description Booking.jsx is the parent file for the booking page
 * @date 
 * @version 
 */
import * as React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import appColors from '../../Assets/AppTheme/appColors';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
};



//Slider changes css for changing slider 
const PrettoSlider = styled(Slider)({
    boxShadow: 4,
    height: 35,
    padding: 0,
    '& .MuiSlider-rail': {
        backgroundColor: appColors.blue,
        border:"1px solid white"
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 35,
     
    },
    '& .MuiSlider-thumb': {
        height: 35,
        width: 35,
        backgroundColor: '#fff',
        border: '2px solid #fff',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 1,
        },

    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: appColors.textColorNew,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});




export default function CustomizedSlider({ handelRouteonClick, SwipeText }) {
    const history = useHistory();
    const [value, setValue] = React.useState(5);

    //For handling setvalue details if slider changes
    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    //For controlling Redirection in swipe button
    React.useEffect(() => {
        if (value > 60) {
            handelRouteonClick(11)
        }
    }, [value])

    //For controlling touch back 
    const HandelTouchSwipe = () => {
        if (value < 60) {
            setValue(5)
        }
    }

    return (
        <Box sx={{ width: SwipeText === "Swipe to Recharge" ? 180 : "90%", }}>
            <PrettoSlider
                name="swipe"
                aria-label="pretto slider"
                value={value}
                onChange={handleChange}
                onTouchEnd={HandelTouchSwipe}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: '35px', // Adjust this value to position the text appropriately
                    left: SwipeText === "Swipe to Recharge" ? `${(35 / 100) * 100}%` : `${(50 / 100) * 100}%`, // Adjust this value to position the text along the track
                    transform: 'translateX(-50%)',
                    fontSize: '12px',
                    color:SwipeText === "Swipe to Recharge" ?appColors.white:appColors.darkGray
                }}
            >
                {SwipeText}
            </div>
        </Box>
    );
}