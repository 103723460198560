/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import WaterDropApplyButton from "../../../SkuButtons/waterDropButton";
import { Box } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import { EditOrderActionSku } from "../../../../Redux/actions/skuOrderPlacingAction";
import { DepositRefundDetailsAction } from "../../../../Redux/actions/DepositDetailsAction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DepositRefundDialog({ open, onClose, data }) {
  const profileDetails = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [orderQty, setOrderQty] = React.useState(0);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  //function for handelclose dialog
  const handleClose = () => {
    onClose();
  };

  //function for handel add quantity
  const handelAddQuantity = () => {
    if (orderQty < data?.max_with_out_depo_qty) {
      setOrderQty(orderQty + 1);
    }
  };

  //function for handel reduse quantity
  const handelReduseQuantity = () => {
    if (orderQty > 1) {
      setOrderQty(orderQty - 1);
    }
  };

  //useeffect for setting orderquantity
  React.useEffect(() => {
    setOrderQty(data?.max_with_out_depo_qty);

    setCapacity(data?.container_capacity);
    setMaterial(data?.container_material);
  }, [data]);

  //handel submit for refund
  const handelRefundDeposit = () => {
    const reqObj = {
      capacity: capacity,
      material: material,
      quantity: orderQty,
      is_crate: false,
      user_id: profileDetails?.user?.user_id,
    };

    dispatch(DepositRefundDetailsAction(reqObj));
    onClose();
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: appColors.secondaryCard,
            color: appColors.secondaryText,
          }}
          id="customized-dialog-title"
        >
          Deposit Refund
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ overflow: "hidden", backgroundColor: appColors.secondaryCard }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "15rem" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: appColors.darkGray }}>
                Deposit Qty
              </Typography>
              <WaterDropApplyButton
                handelAddQuantity={handelAddQuantity}
                handelReduseQuantity={handelReduseQuantity}
                quantity={orderQty}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: appColors.secondaryCard }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{ backgroundColor: appColors.Failed }}
          >
            close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handelRefundDeposit}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
