/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";

//Get all the Available Products Based on Address Orders
export const GetProductsByAddress = (addressId) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_START });
    try {
      let response = await api.get(`/OM/booking/products/address`, {
        params: addressId,
      });
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_AVAILABLE_PRODUCTLIST,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.SKU_AVAILABLE_PRODUCTLIST,
          payload: { data: response.data, statusFlag: true },
        });
      }
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY_Booking });
      }, 300);
    } catch (error) {
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY_Booking });
      }, 300);
      dispatch({
        type: actionType.SKU_AVAILABLE_PRODUCTLIST,
        payload: { data: error.response.data, statusFlag: true },
      });
      dispatch({
        type: actionType.SELECTED_PRODUCT_ACTION,
        payload: { data: {}, statusFlag: false },
      });
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
    }
  };
};

//action function for adding address id
export const SetSelectedAddress = (addressId) => ({
  type: actionType.SET_SELECTED_ADDRESS,
  payload: { data: addressId, statusFlag: false },
});

//function for adding product qty
export const AddProductQuantity = (productId, bookingType) => ({
  type: actionType.ADD_SKU_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for redusing product qty
export const ReduseProductQuantity = (productId, bookingType) => ({
  type: actionType.REDUCE_SKU_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for adding Return qty
export const AddReturnQuantity = (productId, bookingType) => ({
  type: actionType.ADD_RETURN_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for Redusing Return qty
export const ReduseReturnQuantity = (productId, bookingType) => ({
  type: actionType.REDUCE_RETURN_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for storing product details Return qty

export const SelectedProductaction = (product) => ({
  type: actionType.SELECTED_PRODUCT_ACTION,
  payload: { data: product, statusFlag: false },
});

//function for storing product details Return qty
export const DeleteProductaction = (product) => ({
  type: actionType.DELETE_SKU_PRODUCT_QTY,
  payload: { data: product, statusFlag: false },
});

//function for storing product details Return qty
export const DeleteAllProductQty = (product) => ({
  type: actionType.DELETE_ALLSELECTED_PRODUCT_QTY,
  payload: { data: product, statusFlag: false },
});

//function for storing product details Return qty

export const BlockedDateAction = (product) => ({
  type: actionType.BLOCKED_DATE_INFO,
  payload: { data: product, statusFlag: false },
});
