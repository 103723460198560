/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";

export const startLoading = (count) => {
  return {
    type: actionType.SPINNER_LOADING,
    payload: { totalLoadingCounts: count ? count : 1 },
  };
};

export const stopLoading = () => {
  return {
    type: actionType.SPINNER_LOADED,
  };
};
