// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 24/Nov/2021
// File Name: Dashboard.jsx
// Author: Tahir Shaik
// Email/Phone: tahir@bookwater.com / +91-9177327808
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
// import * as from "@mui/material";
//Importing the Style object to change the Existing UI Design
import { styles } from "./NavPageStyles";
//Importing the Custom Components
import { SidebarData } from "./SidebarData";
// import ButtonCustom from "./../../Components/Button/ButtonCustom";
import {
  ForceAppTourInfo,
  toggleState,
  toggleThemeState,
} from "../../Redux/actions/appMetaDataAction";
import ReferandEarnMainPage from "../NewReferandEarn/ReferandEarnMain";
import MyAccounts from "../MyAccounts/MyAccounts";
import Reports from "../Reports/Reports";
import Support from "../Support/Support";
import LogoutConfirmationDialog from "../../Components/DialogBoxes/Authentication/logoutDialog";
import QrScan from "../ScanQr/ScanQR";
import IconListManager from "../../Assets/AppTheme/appIcons";
import NotFound from "../NotFound/NotFound";
import { GetUserProfile } from "./../../Redux/actions/profileAction";
import actionTypes from "./../../Redux/actions/actionTypes";
import * as getSetUpAction from "../../Redux/actions/getSetUpConfigAction";
import * as paymentMethodAction from "../../Redux/actions/paymentMethodAction";
import "./drawer.css";
import waterDropImag from "../../Assets/newMobileIcon/waterDrop.svg";
import FooterAppBarMobile from "../MobileView/FooterMobileView";
import BookWaterLog from "../../Assets/AppIcons/BookWaterLogo.svg";
import OrderManager from "../MyOrders/orderManager";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog";
import NewHome from "../Home/NewHome/NewHome";
import WaterWalletNew from "../../Pages/WaterWalletNew/WaterWalletNew";
import TransactionMainComponent from "../Transactions/TransactionMain";
import LegalInformation from "../LegalInformation/Legal";
import appColors from "../../Assets/AppTheme/appColors";
import ProfileMainPage from "../Newprofile/NewProfileMain";
import { screenSize } from "../../Assets/Constants/AppMetaData";
import FaqMainComponent from "../FaqNew/FaqMain";
import { AppBarStyle, FooterStyles } from "../MobileView/MobileViewcss";
import DarkLightThemeSwitch from "../../darkTheme";
import CheckoutMain from "../CheckOutSku/checkOutMain";
import SkuBookingPage from "../BookNow/SkuBooking/SkuBookingPage";
import SkuSubscription from "../SubscriptionSKU/subscriptionSkuMain";
import DepositHistroyMain from "../DepositHistroySKU/depositHistroyMain";
import CheckOutLandingPage from "../CheckOutSku/checkOutLandingPage";
import ListOfSubscriptionOrders from "../SubscriptionSKU/listOfSubscription";
import { GetWaterDropsUser } from "../../Redux/actions/referAndEarnAction";
import { GetUserAdressBook } from "../../Redux/actions/settingsAction";
import ExitBookingDialog from "../../Components/DialogBoxes/info/exitBooking";
import MenuOptionDialog from "../MobileView/menuDialogBox";
import CustomLoaderDialog from "../../Components/DialogBoxes/CustomLoaderDialog";
import LowInternetDialogBox from "../../Components/DialogBoxes/info/lowInternetDialog";
import CustomConsole from "../../coustomConsole";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import appTheme from "../../Assets/AppTheme/appTheme";
const drawerWidth = 230;

//Navigation Function (Side Dawer & Top App Bar)
function Dashboard() {
  const { pathname, search } = useLocation();
  const name = "";
  const waterDrops = useSelector((state) => state.referAndEarnDetails);
  const profile = useSelector((state) => state.userProfile);
  const address = useSelector((state) => state.settings);
  const pageName = new URLSearchParams(search).get("pageName");
  const pageTitleSideBar = pathname.split("/dashboard/");
  const themeState = useSelector((state) => state.darkThemeState.themeState);
  const dispatch = useDispatch();
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  const [bId, setBId] = useState(0);
  const [isOnline, setIsOnline] = React.useState(true);
  const [pageTitle, setPageTitle] = useState("Home");
  const [getProfileFlag, setGetProfileFlag] = useState(true);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const appMetaDataReducer = useSelector((state) => state.appMetaDataDetails);
  const [otpFlag, setOtpFlag] = React.useState(false);
  const [logOutDialogOpen, setlogOutDialogOpen] = useState(false);
  const [menuDialogOpean, setmenuDialogOpean] = React.useState(false);
  const [openHomeRoute, setOpenHomeRoute] = React.useState(false);
  const spinnerState = useSelector((state) => state.spinnerReducer);
  const history = useHistory();
  //Getting theme status from local storage
  const selectedTheme = localStorage.getItem("selectedTheme");
  const [openProgressBar, setOpenProgressBar] = React.useState(true);
  const [downlinkSpeed, setDownlinkSpeed] = React.useState(null);
  const [lowInternetDialog, setLowInternetDialog] = React.useState(false);
  const orderIdDetails = useSelector((state) => state.skuOrderIdDetails);
  //useeffect to check internet status
  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsOnline(navigator.onLine);
      if (navigator.connection) {
        const speed = navigator.connection.downlink;
        setDownlinkSpeed(speed);
      }
    }, 1000); // Check speed every 1 second
    return () => clearInterval(interval);
  }, [pathname]);

  CustomConsole(downlinkSpeed+"downlink")
  //useeffect to check internet status
  React.useEffect(() => {
    if (
      (downlinkSpeed !== null &&
        downlinkSpeed !== undefined &&
        downlinkSpeed < 0.2) ||
      !isOnline
    ) {
      setLowInternetDialog(true);
    }
  }, [pathname, downlinkSpeed]);

  CustomConsole(downlinkSpeed);
  // CustomConsole(supplierDeliveries.getSupplerCompanyProfile.company_name);
  React.useEffect(() => {
    if (spinnerState.isLoading) {
      setOpenProgressBar(true);
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 800);
    }
  }, [spinnerState]);
  //For  handling logout dialog opening
  const handleOpenDialog = () => {
    setlogOutDialogOpen(true);
  };

  //For  handling logout dialog closing
  const handleCloseDialog = () => {
    setlogOutDialogOpen(false);
  };

  // Function for handling logout action
  const handleConfirmLogout = () => {
    dispatch({
      type: actionTypes.SIGN_OUT,
    });
    setIsLogOut(true);
  };
  //api for hitting address details
  React.useLayoutEffect(() => {
    if (address?.addresses?.length === 0) {
      dispatch(GetUserAdressBook());
    }
  }, []);
  //For hitting api once app opens
  useEffect(() => {
    if (
      getProfileFlag &&
      profile?.user !== undefined &&
      profile?.user.phone === ""
    ) {
      setGetProfileFlag(false);
      dispatch(GetUserProfile());
    }
    dispatch(getSetUpAction.GetSetUpConfigAction());
  }, []);

  //For hitting api once app opens
  useEffect(() => {
    dispatch(paymentMethodAction.GetWalletDetails());
  }, []);

  //For hitting api once app opens
  useEffect(() => {
    dispatch(GetWaterDropsUser());
    // dispatch(ForceAppTourInfo());
  }, []);
  // To Control the Page Heading on Click of Back & froward Button
  React.useEffect(() => {
    refreshDashboard(pageTitleSideBar[1]);
  }, [pathname]);

  //For ending app tour if route changes
  React.useEffect(() => {
    if (pageTitleSideBar[0] !== "/mobile") {
      localStorage.setItem("visitedBeforeHome", true);
    }
  }, [pathname]);

  //Changing text based on route in Appbar
  const pageTitles = {
    home: { title: "Home", id: 0 },
    booknow: { title: "Book Now", id: 1 },
    myorders: { title: "My Orders", id: 2 },
    transactions: { title: "Transaction", id: 3 },
    myaccounts: { title: "Accounts", id: 4 },
    deposit: { title: "Deposits", id: 5 },
    wallet: { title: "Wallet", id: 6 },
    subscribe: { title: "Subscription", id: 7 },
    referandearn: { title: "Refer & Earn", id: 8 },
    support: { title: "Support", id: 9 },
    Profile: { title: "Profile", id: 10 },
    legal: { title: "Legal Information", id: 11 },
    subscriptionList: { title: "Subscription", id: 7 },
    settings: { title: "Profile", id: 10 },
  };

  //Function for changing sidebar color based on selection
  const refreshDashboard = (pageTitleSideBar) => {
    const selectedPage = pageTitles[pageTitleSideBar];
    if (selectedPage) {
      setPageTitle(selectedPage.title);
      setBId(selectedPage.id);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //UseEffect for monitering screen size//////
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth > screenSize.MOBILE_SCREEN) {
      pageTitleSideBar[1] !== undefined
        ? history.push(`/dashboard/${pageTitleSideBar[1]}`)
        : history.push("/dashboard/home");
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //For setting up theme based on user last selected
  React.useEffect(() => {
    if (selectedTheme !== "") {
      document.querySelector("body").setAttribute("dark-theme", selectedTheme);
      localStorage.setItem("selectedTheme", selectedTheme);
    }
    if (selectedTheme === "dark") {
      dispatch(toggleThemeState(true));
    } else {
      dispatch(toggleThemeState(false));
    }
  }, [selectedTheme]);

  const NavigationclickHandler = (title, id) => {
    setPageTitle(title);
    setBId(id);
    setMobileOpen(false);
  };

  const sideData = SidebarData();
  const iconSvg = IconListManager();
  //Side Navigation Drawer Data
  const drawer = (
    <Box className="drawer" sx={styles.drawerContainer}>
      {/* User Profile */}
      <img
        src={
          themeState ? BookWaterLog : process.env.PUBLIC_URL + "/bwLogo2.png"
        }
        style={styles.bookwaterLogo}
        alt=""
        onClick={() => window.open("https://www.bookwater.com/", "_blank")}
      />
      <List sx={styles.drawerlist}>
        {sideData.map((item, index) => {
          return (
            <Link
              style={{ color: "inherit", textDecoration: "inherit" }}
              to={item.path}
              key={index}
            >
              <ListItem
                key={index}
                onClick={() => NavigationclickHandler(item.title, item.id)}
                sx={{
                  padding: "10px",
                  paddingLeft: "30px",
                  // borderTop:".5px solid #d6d6d6",
                  backgroundColor:
                    bId === item.id ? appColors.sideBarHover : "inherit",
                  "&: hover": {
                    backgroundColor: appColors.sideBarHover,
                  },
                }}
              >
                <ListItemIcon>
                  <img
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "1.2rem",
                      width: "1.2rem",
                    }}
                    src={item.icon}
                    alt=""
                  />
                </ListItemIcon>
                <Box>
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontWeight: "600",
                      fontSize: appTheme.fontSizeMedium,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </ListItem>
            </Link>
          );
        })}
      </List>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Devider */}
        {/* <hr style={{  width: "100%", color:"#d6d6d6" }} /> */}
        {/* Redirecting to Book Water Terms-Condition & Privacy Policy */}
        <Box sx={styles.termsAndPolicy}>
          <a
            href={process.env.REACT_APP_BW_TERMS_URl}
            style={styles.termsTxt}
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            href={process.env.REACT_APP_BW_ACCEP_URL}
            style={styles.policyTxt}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Box>
        {/* App Version */}
        <Typography
          varient="subtitle2"
          sx={{
            fontSize: appTheme.fontSizeSmall,
            color: appColors.darkGray,
            // backgroundColor: "#0a0c4a",
            marginBottom: "10px",
          }}
        >
          WebApp Version : {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  );

  //condition for redirection to login page if islogout state changes
  if (isLogOut) {
    return <Redirect to="authenticate" />;
  }

  //function for converting 1000 and above to 1k
  const NumberFormatter = ({ value }) => {
    const formatNumber = (num) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(2) + "k";
      } else {
        return num.toString();
      }
    };
    return <span>{formatNumber(value)}</span>;
  };

  //function to open Home dialog
  const handelOpenHomeDialog = (e) => {
    if (pageTitleSideBar[1] === "checkout") {
      if (!orderIdDetails?.exitBookingFlag) {
        history.push("/mobile");
        orderIdDetails.exitBookingFlag = true;
      } else {
        setOpenHomeRoute(true);
      }
    } else {
      if (
        pageTitleSideBar[1] === "settings" ||
        pageTitleSideBar[1] === "myaccounts" ||
        pageTitleSideBar[1] === "deposit" ||
        pageTitleSideBar[1] === "myorders" ||
        pageTitleSideBar[1] === "transactions" ||
        pageTitleSideBar[1] === "support" ||
        pageTitleSideBar[1] === "legal"
      ) {
        history.push("/dashboard/menu");
      } else {
        history.push("/mobile");
      }
    }
  };

  CustomConsole(profile);
  return (
    <Box sx={styles.mainDasfboard}>
      <CssBaseline />
      {/* Top Navigation Bar */}
      <AppBar
        style={{ backgroundColor: appColors.dashbordAppbar }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: { xs: "3.7rem" },
          ml: { sm: `${drawerWidth}px` },
          // boxShadow: 4,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            marginTop: { md: 0, sm: ".2rem", xs: "0.2rem" },
          }}
        >
          {/* BookWater Icon with BookWater Name */}
          {/* Logo only shows in mobile screen */}
          {windowWidth < screenSize.MOBILE_SCREEN &&
          pageTitleSideBar[0] === "mobile" ? (
            <Box
              sx={{
                mr: 1,
                display: { xs: "flex", sm: "flex", md: "flex" },
                alignItems: "center",
              }}
            >
              <img
                src={
                  themeState
                    ? BookWaterLog
                    : process.env.PUBLIC_URL + "/bwLogo2.png"
                }
                style={{
                  width: "7rem",
                  height: "3rem",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                alt=""
                onClick={() => history.push("/mobile")}
              />
            </Box>
          ) : (
            ""
          )}

          {windowWidth < screenSize.MOBILE_SCREEN &&
          pageTitleSideBar[0] !== "mobile" &&
          pageTitleSideBar[1] !== "menu" ? (
            <Box
              onClick={(e) =>
                appMetaDataReducer.toggleState && pageTitleSideBar[1] === "faq"
                  ? dispatch(toggleState())
                  : handelOpenHomeDialog(e)
              }
              sx={{
                mr: 2,
                display: { xs: "flex", sm: "flex", md: "flex" },
                alignItems: "center",
              }}
            >
              {" "}
              <IconButton
                edge="start"
                aria-label="close"
                sx={{ color: appColors.headerTextColor }}
              >
                <KeyboardBackspaceIcon fontSize="medium" />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontSize: appTheme.fontSizeLarge,
                  color: appColors.headerTextColor,
                  fontWeight: "600",
                }}
              >
                {(appMetaDataReducer.toggleState &&
                  pageTitleSideBar[1] === "faq") ||
                pageTitleSideBar[1] === "settings" ||
                pageTitleSideBar[1] === "myaccounts" ||
                pageTitleSideBar[1] === "deposit" ||
                pageTitleSideBar[1] === "myorders" ||
                pageTitleSideBar[1] === "transactions" ||
                pageTitleSideBar[1] === "support" ||
                pageTitleSideBar[1] === "legal"
                  ? "Back"
                  : "Home"}
              </Typography>{" "}
            </Box>
          ) : (
            ""
          )}
          {pageTitleSideBar[1] !== "menu" && (
            <Box
              sx={{
                flexGrow: { sm: 1, md: 1, xs: 0 },
                marginRight: { sm: "5px", xs: "15px" },
              }}
            />
          )}
          {/* Page Title: Dynamically change based on the Side Nav Button on click */}
          {windowWidth > screenSize.MOBILE_SCREEN ? (
            <Typography
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                color: appColors.darkGray,
                fontSize: {
                  xs: appTheme.fontSizeSmall,
                  md: appTheme.fontSizeLarge,
                  sm: appTheme.fontSizeLarge,
                },
                // backgroundColor: "yellow",
                marginRight: { xs: "10px" },
              }}
            >
              {pageTitle === "" ? "Home" : pageTitle}
              {/* {pageTitle} */}
            </Typography>
          ) : (
            ""
          )}
          {windowWidth > screenSize.MOBILE_SCREEN ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Box
                  className="tourWallet "
                  onClick={() =>
                    history.push("/dashboard/referandearn?pageValue=1")
                  }
                >
                  <Button
                    sx={styles.BackButton}
                    size="small"
                    variant="contained"
                  >
                    <img
                      alt=""
                      style={{
                        height: "1.3rem",
                      }}
                      src={waterDropImag}
                    />{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        color: appColors.darkGray,
                      }}
                    >
                      <NumberFormatter
                        value={
                          waterDrops?.allData.water_drops
                            ? waterDrops.allData.water_drops
                            : 0
                        }
                      />
                    </span>
                  </Button>
                </Box>
                <Box>
                  <Button
                    sx={styles.BackButton}
                    className="tourWalletWeb"
                    size="small"
                    variant="contained"
                    onClick={() => history.push("/dashboard/wallet")}
                  >
                    <img
                      alt=""
                      style={{
                        width: "2rem",
                        height: "1.3rem",
                      }}
                      src={iconSvg.walletIcon}
                    />{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        color: appColors.darkGray,
                      }}
                    >
                      ₹
                      {walletDetails.walletAmount === undefined
                        ? 0
                        : parseInt(walletDetails.walletAmount)}
                    </span>
                  </Button>
                </Box>
              </Box>
              <Button
                variant="contained"
                size="small"
                sx={styles.BackButton}
                // onClick={() => history.push("/authenticate")}
                onClick={handleOpenDialog}
              >
                <Avatar
                  sx={{
                    width: 16,
                    height: 22,
                  }}
                  src={iconSvg.logoutIcon}
                  variant="square"
                />
                &nbsp;
                <span
                  style={{
                    color: appColors.darkGray,
                    fontWeight: "bold",
                    fontSize: ".6rem",
                  }}
                >
                  Logout
                </span>
              </Button>
            </>
          ) : (
            <>
              {pageTitleSideBar[1] !== "menu" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="tourWallet"
                    onClick={() =>
                      history.push("/dashboard/referandearn?pageValue=1")
                    }
                  >
                    <Button
                      sx={styles.BackButton}
                      size="small"
                      variant="contained"
                    >
                      <img
                        alt=""
                        style={{
                          height: "1.5rem",
                        }}
                        src={waterDropImag}
                      />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                          color: appColors.darkGray,
                        }}
                      >
                        <NumberFormatter
                          value={
                            waterDrops?.allData.water_drops
                              ? waterDrops.allData.water_drops
                              : 0
                          }
                        />
                      </span>
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      sx={styles.BackButton}
                      size="small"
                      variant="contained"
                      onClick={() => history.push("/dashboard/wallet")}
                    >
                      <img
                        alt=""
                        style={{
                          width: "2rem",
                          height: "1.5rem",
                        }}
                        src={iconSvg.walletIcon}
                      />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                          color: appColors.darkGray,
                        }}
                      >
                        ₹
                        <NumberFormatter
                          value={
                            walletDetails.walletAmount === undefined
                              ? 0
                              : parseInt(walletDetails.walletAmount)
                          }
                        />
                      </span>
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
          {pageTitleSideBar[1] === "menu" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: appColors.headerTextColor,
                  fontWeight: "600",
                  fontSize: appTheme.fontSizeLarge,
                }}
                onClick={() => history.push("/mobile")}
              >
                <KeyboardBackspaceIcon /> Home
              </Typography>

              <div className="tourDarkWeb">
                <DarkLightThemeSwitch />
                {/* <SettingMenu /> */}
              </div>
            </Box>
          )}
          {windowWidth > screenSize.MOBILE_SCREEN ? (
            <div className="tourDarkWeb">
              <DarkLightThemeSwitch />
              {/* <SettingMenu /> */}
            </div>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>

      {/* Side Navigation starts from here */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
         
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        {window.innerWidth < screenSize.MOBILE_SCREEN &&
        pageTitleSideBar[1] !== "booknow" &&
        pageTitleSideBar[1] !== "checkout" &&
        pageTitleSideBar[1] !== "subscribe" &&
        pageTitleSideBar[1] !== "subscription" &&
        pageTitleSideBar[1] !== "menu" ? (
          <AppBar position="fixed" sx={FooterStyles.footerMain}>
            <Box sx={{ backgroundColor: appColors.homePageCard }}>
              <FooterAppBarMobile
                menuDialogOpean={menuDialogOpean}
                setmenuDialogOpean={setmenuDialogOpean}
              />
            </Box>
          </AppBar>
        ) : (
          ""
        )}
      </Box>

      {/* routes statrt here */}
      <Box
        component="main"
        sx={{
          padding: "1rem",
          background: appColors.backGround,
          minHeight: "100vh",
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            xs: "100%",
          },
        }}
      >
        {/* <Toolbar /> */}
        <Switch>
          <Route path="/dashboard/" exact component={NewHome} />
          <Route path="/dashboard/home" exact component={NewHome} />
          <Route path="/dashboard/booknow" component={SkuBookingPage} />
          <Route path="/dashboard/myorders" component={OrderManager} />
          <Route path="/dashboard/myaccounts" component={MyAccounts} />
          <Route path="/dashboard/menu" component={MenuOptionDialog} />
          <Route
            path="/dashboard/transactions"
            component={TransactionMainComponent}
          />
          <Route path="/dashboard/deposit" component={DepositHistroyMain} />
          <Route path="/dashboard/support" component={Support} />
          <Route path="/dashboard/settings" component={ProfileMainPage} />
          <Route
            path="/dashboard/referandearn"
            component={ReferandEarnMainPage}
          />
          <Route path="/dashboard/wallet" component={WaterWalletNew} />
          <Route path="/dashboard/subscribe" component={SkuSubscription} />
          <Route path="/dashboard/qrscan" component={QrScan} />
          <Route path="/dashboard/legal" component={LegalInformation} />
          <Route path="/dashboard/faq" component={FaqMainComponent} />
          <Route
            path="/dashboard/subscriptionList"
            component={ListOfSubscriptionOrders}
          />
          <Route
            path="/dashboard/checkout"
            exact
            component={CheckOutLandingPage}
          />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        {otpFlag && <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} />}
        <LogoutConfirmationDialog
          open={logOutDialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmLogout}
        />
      </Box>
      <ExitBookingDialog
        open={openHomeRoute}
        onClose={setOpenHomeRoute}
        pageName={pageName}
      />
      {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )}
      {lowInternetDialog && (
        <LowInternetDialogBox
          open={lowInternetDialog}
          onClose={setLowInternetDialog}
        />
      )}
    </Box>
  );
}

export default Dashboard;
