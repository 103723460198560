import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import canIcon from "../../../Assets/newMobileIcon/Payment processing(Can set).svg";
import * as Material from "@mui/material";
import { useLocation } from "react-router-dom";
import { IntervalTimerFunctional } from "../CountDownTimer";
import { useDispatch, useSelector } from "react-redux";
import {
  PaythroughRazorOrderStatus,
  PaythroughUPIOrderStatus,
} from "../../../Redux/actions/paymentMethodAction";
import { useHistory } from "react-router-dom";
import appColors from "../../../Assets/AppTheme/appColors";
import RazorPayStatusDialog from "../RazorPayStatusDialog";
import CustomConsole from "../../../coustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RazorPayTransactionDialog(props) {
  const { pathname } = useLocation();
  const name = "";
  const pageTitleSideBar = pathname.split("/dashboard/");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const bookNowState = useSelector((state) => state.bookNow);
  const [openRpRespDialog, setOpenRpRespDialog] = React.useState(false);
  const [rpPaymentId, setrpPaymentId] = React.useState("");
  const [openUPIStatusDialog, setOpenUPIStatusDialog] = React.useState(false);
  const [orderAmount, setOrderAmount] = React.useState("");
  const upiOrderStatus = useSelector((state) => state.UPIOrderStatus);
  const history = useHistory();
  CustomConsole(bookNowState);
  const handleClose = (e) => {
    e.preventDefault();
    props.handleClose();
  };
  React.useEffect(() => {
    // if (props.upiTransactionPage === true) {
    CustomConsole("inside 5 min timer close");
    let timeout = setTimeout(() => {
      CustomConsole("closing transaction timer page");
      history.push("/dashboard/myorders");
      // window.location.reload();
    }, 720000);
    return () => {
      clearTimeout(timeout);
    };
    // }
  }, []);

  const handleCloseIcon = () => {
    props.setRazorPayDialogFlag(false);
    if (pageTitleSideBar[1] === "booknow") {
      history.push("/dashboard/myorders");
    } else {
      history.push("/dashboard/home");
    }
    // props.setUpiPaymentDialogOpen(false);
  };
  const handleCloseRPDialog = () => {
    setOpenRpRespDialog(false);
    props.setRazorPayDialogFlag(false);
  };
  let orderFinalPrice = "100";

  CustomConsole(props.currentOrderId);
  const dispatch = useDispatch();
  // const [apiInterval, setApiInterval] = React.useState(true);
  React.useEffect(() => {
    CustomConsole(props.orderId);
    CustomConsole(bookNowState.orderId);
    CustomConsole(`upiTransactionPage ${props.upiTransactionPage}`);
    CustomConsole(props.razorPayOrderId);
    let interval = setInterval(() => {
      dispatch(
        PaythroughRazorOrderStatus(props.orderId, props.razorPayOrderId)
      );
    }, 5000);
    CustomConsole(interval);
    return () => {
      clearInterval(interval);
    };
  }, [props.razorPayOrderId]);

  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(upiOrderStatus);
    CustomConsole(`openUPIStatusDialog ${openUPIStatusDialog}`);

    if (
      upiOrderStatus.isPaymentUpdated === true &&
      upiOrderStatus.message?.data !== undefined &&
      upiOrderStatus.message?.data !== ""
    ) {
      if (upiOrderStatus.message.data.payment_status === "SUCCESS") {
        CustomConsole("Payment Success");
        setOrderAmount(upiOrderStatus.message.data.amount);
        setError(false);
        setrpPaymentId(upiOrderStatus.message.data.transaction_id);
        setOpenRpRespDialog(true);
      } else if (
        upiOrderStatus.message.data.payment_status === "FAILED" ||
        upiOrderStatus.message.data.payment_status === "TIMEOUT"
      ) {
        CustomConsole("Payment Failed");
        // dispatch(CancelOrder(upiOrderStatus.message.data.orderId));
        setrpPaymentId(upiOrderStatus.message.data.transaction_id);
        setOrderAmount(upiOrderStatus.message.data.amount);
        setError(true);
        setOpenRpRespDialog(true);
      }
    } else {
      CustomConsole("order status UPI else block");
    }
  }, [upiOrderStatus, upiOrderStatus.message]);

  const handleBackdropClick = (event) => {
    event.preventDefault(); // Prevents the default behavior of closing the dialog
  };

  const handelClose = () => {};
  return (
    <div>
      <Dialog
        fullScreen
        open={props.razorPayDialogFlag}
        onClose={handleClose}
        // onClose={cancelUpiPay}
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", backgroundColor: appColors.homePageCard }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDialogOpen(true)}
              aria-label="close"
              sx={{ color: appColors.secondaryText }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: appColors.secondaryText }}
              variant="h6"
              component="div"
            >
              Payment request
            </Typography>
          </Toolbar>
        </AppBar>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: appColors.secondaryCard,
            height: "100vh",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.Typography
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "30px",
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: appColors.secondaryText,
                // width: "600px",
                textAlign: "center",
              }}
            >
              Don't close or refresh this window.
            </Material.Typography>

            <>
              <Divider />
              <Material.Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <img alt="" src={canIcon} />
              </Material.Box>
            </>
            <Material.Box sx={{ marginTop: "20px" }}>
              {" "}
              <CircularProgress />
            </Material.Box>
            <Material.Typography
              sx={{
                color: appColors.darkGray,
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              Time left
            </Material.Typography>
            <IntervalTimerFunctional />
          </Material.Box>
          <Material.Typography
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: appColors.secondaryText,
              // width: "600px",
              textAlign: "center",
            }}
          >
            Waiting for payment confirmaiton from your UPI Application.
          </Material.Typography>
        </Material.Box>
      </Dialog>
      <Dialog
        open={dialogOpen}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Material.DialogContent sx={{ background: appColors.secondaryCard }}>
          <Material.Typography
            sx={{
              color: appColors.secondaryText,
              fontWeight: "bold",
            }}
          >
            Closing this window may affect this payment. Do you want to
            continue?
          </Material.Typography>
        </Material.DialogContent>
        <Material.DialogActions
          sx={{
            background: appColors.secondaryCard,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={handleCloseIcon}
            variant="contained"
            size="small"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            size="small"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            No
          </Button>
        </Material.DialogActions>
      </Dialog>
      {openRpRespDialog ? (
        <RazorPayStatusDialog
          handleClose={handleCloseRPDialog}
          open={openRpRespDialog}
          amount={parseFloat(orderAmount)}
          handleCloseRPDialog={() => setOpenRpRespDialog(false)}
          error={error}
          orderId={""}
          paymentId={rpPaymentId}
        />
      ) : (
        ""
      )}
    </div>
  );
}
