/**
 * @author Gowtham Prasath
 * @description Promo Code Apply and Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { dialogStyles } from "../../../Components/DialogBoxes/PdwBookingSummary/newPromoDialogCss";
import { ApplyPromocode } from "../../../Redux/actions/promocodesAction";
import appColors from "../../../Assets/AppTheme/appColors";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function WalletTransactionCard() {
  const transactionDetails = useSelector((state) => state.transactionDetails);
  const histroy = useHistory();
  //function to show date formate
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD MMM YYYY");
    return momentEndDate;
  };
  return (
    <>
      <Box
        sx={[
          dialogStyles.dialogContent1,
          { marginBottom: { xs: "2rem", sm: "none" } },
        ]}
      >
        <Box sx={dialogStyles.textFieldBox}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={dialogStyles.typograpy1}>
              Recent Transactions
            </Typography>
            <Typography
              sx={dialogStyles.typograpy1}
              onClick={() =>
                histroy.push(`/dashboard/transactions?pageName=wallet`)
              }
            >
              View more
            </Typography>
          </Box>
          <Box sx={[dialogStyles.scrollPromoCard, { marginTop: "1rem" }]}>
            {transactionDetails.walletTransactions.length > 0 ? (
              <Box>
                {transactionDetails.walletTransactions
                  .slice(0, 5)
                  .map((data, index) => {
                    return (
                      <Box sx={dialogStyles.mainCard} key={index}>
                        <Box
                          sx={{
                            borderTopRadius: "15px",
                            // width: "100%",
                            margin: ".5rem",
                            color: "white",
                            display: "flex",
                            // border: "1px solid gray",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: ".5rem 1rem",
                            backgroundColor: appColors.secondaryCard,
                            borderRadius: ".5rem",
                            boxShadow: 5,
                          }}
                          onClick={() =>
                            histroy.push(
                              `/dashboard/transactions?pageName=wallet`
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.textColorNew,
                                  fontSize: ".8rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {data?.transaction_id?.includes("BWORDWLT")
                                  ? `Refund on order ${data?.order_id}`
                                  : data?.order_id?.includes("BWUPIWLT")
                                  ? "Wallet Recharge"
                                  : data?.transaction_id?.includes("BWOPS")
                                  ? "Paid to Order"
                                  : data?.transaction_id?.includes("REF") &&
                                    !data?.order_id?.includes("WTHDRWL")
                                  ? "Security Deposit Refund"
                                  : data?.transaction_id?.includes("REF") &&
                                    data?.order_id?.includes("WTHDRWL")
                                  ? "Water price refund"
                                  : data?.transaction_id?.includes("EREF")
                                  ? `Refund on order ${data?.order_id}`
                                  : data?.transaction_id?.includes("upi")
                                  ? "Paid to order"
                                  : "Wallet Recharge"}
                                &nbsp;
                              </Typography>
                              {/* <Typography
                                sx={{
                                  color: appColors.Textgray,
                                  fontSize: ".8rem",
                                }}
                              >
                                {data.order_id !== "" ? data.order_id : ""}
                              </Typography> */}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.Textgray,
                                  fontSize: ".8rem",
                                  fontWeight: "bold",
                                  color:
                                    data?.payment_status === "SUCCESS"
                                      ? "#4CBB17"
                                      : appColors.Failed,
                                }}
                              >
                                ₹ {data.amount}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              {data.payment_method === "" ? (
                                <>
                                  <Typography
                                    sx={{
                                      color: appColors.textColorNew,
                                      fontSize: ".8rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Payment Type&nbsp;
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: appColors.Textgray,
                                      fontSize: ".8rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.payment_type}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      color: appColors.textColorNew,
                                      fontSize: ".8rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Paid using&nbsp;
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: appColors.Textgray,
                                      fontSize: ".8rem",
                                    }}
                                  >
                                    {data.payment_method}
                                  </Typography>
                                </>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.Textgray,
                                  fontSize: ".8rem",
                                }}
                              >
                                {endDateConverter(data.transaction_ts)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "10px",
                  color: appColors.textColorNew,
                }}
              >
                No Transaction Available
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
