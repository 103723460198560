/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import axios from "axios";
import { url } from "./../apis";
import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
import CustomConsole from "../../coustomConsole";
// import actionTypes, * as actionType from "./actionTypes";
import * as toastMessage from "./../../Assets/Constants/ToastMessages";

export const getFiles = (filename) => {

  let fileType;
  if (filename !== "") {
    fileType = filename.split(".")[1];
  } else {
    fileType = "png";
  }
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/SSM/files/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          //   "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileType}`);
          document.body.appendChild(link);
          link.click();
          // CustomConsole("getFilesAction" + link.href);
          dispatch({
            type: actionType.GET_FILES_DOWNLOAD,
            payload: link.href,
          });
          toast.dark("Successfully downloaded", {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
        })
        .catch((error) => {
          CustomConsole("getFiles Action Error : " + error);
        });
    };
  } else {
    //  toast.dark(toastMessage.TOKEN_INVALID, {
    //    position: "bottom-right",
    //    autoClose: 5000,
    //    type: "error",
    //  });
  }
};

// export async function get(url: string) {
//   try {
//     const response = await fetch(url, {
//       method: "GET", // *GET, POST, PUT, DELETE, etc.
//       mode: "cors", // no-cors, *cors, same-origin
//       cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//       headers: {
//         "Content-Type": "image/jpeg",
//       },
//     });
//     const blob = await response.blob();
//     return [URL.createObjectURL(blob), null];
//   } catch (error) {
//     console.error(`get: error occurred ${error}`);
//     return [null, error];
//   }
// }
