/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import * as Material from "@mui/material";
import orderSucess from "../../../Assets/newMobileIcon/Booking/Can Order successful.svg";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function DepositSucess(props) {
  const handleClose = () => {
    props.setOpenDepositSucessDialog(false);
  };

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="" src={orderSucess} />
          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign:"center"
            }}
          >
            Deposit added.
          </Material.Typography>
          <Material.Typography
            sx={{
              fontSize: "14px",
              color: appColors.darkGray,
              fontWeight: "500",
              marginTop: ".9rem",
              textAlign:"center"
            }}
          >
            Your deposit has been added to your account successful.
          </Material.Typography>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Material.Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
