/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as Material from "@mui/material";
import orderSucess from "../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import orderFailed from "../../Assets/newMobileIcon/Booking/Order Failed.svg";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetWalletDetails,
  PaythroughUPIOrderStatus,
} from "../../Redux/actions/paymentMethodAction";
import { useLocation } from "react-router-dom";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction";
import { ConsumerRatingState } from "../../Redux/actions/profileAction";
import CustomConsole from "../../coustomConsole";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function UPIPayStatusDialog(props) {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const pageTitle = pathname.split("/dashboard/");
  const pageName = new URLSearchParams(search).get("pageName");
  const [orderStatusApi, setOrderStatusApi] = React.useState(false);
  const [failedText, setFailedText] = React.useState("");
  const history = useHistory();

  //useEffect for setting up a failed test
  React.useEffect(() => {
    CustomConsole(pageTitle);
    if (
      pageTitle[1] === "subscribe" ||
      pageTitle[1] === "wallet" ||
      pageTitle[1] === "subscription"
    ) {
      setFailedText("Payment Failed Please Try again");
    } else {
      setFailedText(
        "Do not worry your order details are saved You can retry from the orders page again"
      );
    }
  }, [pathname, search]);

  React.useEffect(() => {
    if (orderStatusApi === true) {
      dispatch(PaythroughUPIOrderStatus(""));
      setOrderStatusApi(false);
    }
  }, [orderStatusApi]);

  //function for handling close operations
  const handleClose = () => {
    CustomConsole(props.inBookingPage);
    CustomConsole(props.inAccountPage);
    CustomConsole(props.inAccountPage || props.inBookingPage);
    if (!props.error) {
      dispatch(ConsumerRatingState(true));
    }
    if (pageName === "booking" || pageTitle[1] === "myorders") {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
      dispatch(ResetComponentState());
      setTimeout(() => {
        dispatch(ResetComponentState());
      }, 2000);
      setTimeout(() => {
        dispatch(GetWalletDetails());
      }, 100);
    } else if (pageTitle[1] === "wallet" || pageTitle[1] === "myorders") {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
      dispatch(ResetComponentState());
      setTimeout(() => {
        dispatch(ResetComponentState());
      }, 2000);
      setTimeout(() => {
        dispatch(GetWalletDetails());
      }, 100);
    } else {
      props.setUpiPaymentDialogOpen(false);
      setOrderStatusApi(true);
      dispatch(AllOrderOtpPaymentDetails());
      dispatch(PaythroughUPIOrderStatus(""));
      props.setOpenUPIStatus(false);
      setTimeout(() => {
        dispatch(ResetComponentState());
      }, 2000);
      dispatch(ResetComponentState());
      props.setUpiPay(false);
      props.setUpiTransactionPage(false);
      setTimeout(() => {
        dispatch(GetWalletDetails());
      }, 100);
    }
    props.handleClose();
    props.setPaymentAmount(0);
    props.setUpiDataReceived(false);
  };

  const bgcolor = appColors.backGroundGradient;

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      //   sx={{ backgroundColor: "gray" }}
      onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.DialogContent sx={{ background: appColors.secondaryCard }}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img alt="" src={props.error ? orderFailed : orderSucess} />
          <Material.Typography
            sx={{
              fontSize: "20px",
              color: appColors.darkGray,
              fontWeight: "bold",
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            {props.error ? "Payment Failed" : "Payment Success"}&nbsp;
          </Material.Typography>
          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            {props.error ? failedText : ""}
          </Material.Typography>
          {props.orderId ? (
            <Material.Typography
              sx={{
                fontSize: "16px",
                color: appColors.darkGray,
                fontWeight: "bold",
              }}
            >
              Order ID: {props.orderId}
            </Material.Typography>
          ) : (
            ""
          )}

          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
            }}
          >
            Order Price: ₹{props.amount}
          </Material.Typography>

          <Material.Button
            varient="contained"
            sx={{
              marginTop: "15px",
              backgroundColor: appColors.textColorNew,
              color: "white",
              "&:hover": { backgroundColor: appColors.textColorNew },
            }}
            onClick={handleClose}
          >
            Close
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
