/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description BookWater App Theme Colors Should Be declared Here and will use in entire app
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import home from "../newMobileIcon/home.svg";
import homeDark from "../newMobileIcon/darkTheme/Home/home.svg";
import Order from "../newMobileIcon/ORDER.svg";
import OrderDark from "../newMobileIcon/darkTheme/Full Menu/Orders.svg";
import myOrders from "../newMobileIcon/Orders.svg";
import myOrdersDark from "../newMobileIcon/darkTheme/Full Menu/Orders.svg";
import Payment from "../newMobileIcon/Transactions.svg";
import PaymentDark from "../newMobileIcon/darkTheme/Full Menu/Transactions.svg";
import Bank from "../newMobileIcon/Personal Bank.svg";
import BankDark from "../newMobileIcon/darkTheme/Full Menu/Personal Bank.svg";
import Deposit from "../newMobileIcon/Deposits.svg";
import DepositDark from "../newMobileIcon/darkTheme/Full Menu/Deposits.svg";
import wallet from "../newMobileIcon/Water Wallet.svg";
import walletDark from "../newMobileIcon/darkTheme/Full Menu/Water Wallet.svg";
import Subcribe from "../newMobileIcon/Subscriptions.svg";
import subscribeDark from "../newMobileIcon/darkTheme/Full Menu/Subscriptions.svg";
import Refer from "../newMobileIcon/Refer & Earn.svg";
import referDark from "../newMobileIcon/darkTheme/Full Menu/Refer & Earn.svg";
import support from "../newMobileIcon/Customer.svg";
import supportDark from "../newMobileIcon/darkTheme/Full Menu/help-desk.svg";
import profile from "../newMobileIcon/Profile.svg";
import profileDark from "../newMobileIcon/darkTheme/Full Menu/Profile.svg";
import legal from "../newMobileIcon/Legal.svg";
import legalDark from "../newMobileIcon/darkTheme/Full Menu/Legal.svg";
import logout from "../newMobileIcon/Logout.svg";
import logoutDark from "../newMobileIcon/darkTheme/Full Menu/Logout.svg";
import Scanqr from "../newMobileIcon/SCAN.svg";
import ScanqrDark from "../newMobileIcon/darkTheme/Home/SCAN.svg";
import Menu from "../newMobileIcon/MENU.svg";
import menuDark from "../newMobileIcon/darkTheme/Home/MENU.svg";
import addressBook from "../newMobileIcon/Address Book.svg";
import addressBookDark from "../newMobileIcon/darkTheme/Full Menu/Address Book.svg";
import location from "../newMobileIcon/Booking/Delivery Address.svg";
import locationDark from "../newMobileIcon/darkTheme/Booking/Delivery Address.svg";
import calenderIcon from "../newMobileIcon/Booking/Delivery Date.svg";
import calenderIconDark from "../newMobileIcon/darkTheme/Booking/Delivery Date.svg";
import deliveryCanIcon from "../newMobileIcon/Booking/Delivery.svg";
import deliveryCanIconDark from "../newMobileIcon/darkTheme/Booking/Delivery.svg";
import returnCanIcon from "../newMobileIcon/Booking/Return.svg";
import returnCanIconDark from "../newMobileIcon/darkTheme/Booking/Return.svg";
import coupon from "../newMobileIcon/Booking/Coupon.svg";
import couponDark from "../newMobileIcon/darkTheme/Booking/Coupon.svg";
import otp from "../newMobileIcon/Booking/OTP.svg";
import otpDark from "../newMobileIcon/darkTheme/Booking/OTP.svg";
import otpMenu from "../newMobileIcon/OTP.svg";
import otpDarkMenu from "../newMobileIcon/darkTheme/Full Menu/OTP.svg";
import infoIcon from "../newMobileIcon/Booking/info-circle.svg";
import infoIconDark from "../newMobileIcon/Booking/info-circle-white.svg";
import sucessHand from "../newMobileIcon/darkTheme/Subscription Successful/Success hand.svg";
import sucessHandDark from "../newMobileIcon/darkTheme/Subscription Successful/Subscription Updated.svg";
import { useSelector } from "react-redux";

function IconListManager() {
    const themeState = useSelector((state) => state.darkThemeState.themeState);

    const iconList = {
        home: themeState ? homeDark : home,
        Order: themeState ? OrderDark : Order,
        myOrders: themeState ? myOrdersDark : myOrders,
        transaction: themeState ? PaymentDark : Payment,
        bankIcon: themeState ? BankDark : Bank,
        depositIcon: themeState ? DepositDark : Deposit,
        walletIcon: themeState ? walletDark : wallet,
        subcribeIcon: themeState ? subscribeDark : Subcribe,
        referIcon: themeState ? referDark : Refer,
        supportIcon: themeState ? supportDark : support,
        profileIcon: themeState ? profileDark : profile,
        legalIcon: themeState ? legalDark : legal,
        logoutIcon: themeState ? logoutDark : logout,
        scanIcon: themeState ? ScanqrDark : Scanqr,
        menuIcon: themeState ? menuDark : Menu,
        addressIcon: themeState ? addressBookDark : addressBook,
        locationIcon: themeState ? locationDark : location,
        CalenderIcon: themeState ? calenderIconDark : calenderIcon,
        deliveryCanIcon: themeState ? deliveryCanIconDark : deliveryCanIcon,
        ReturnCanIcon: themeState ? returnCanIconDark : returnCanIcon,
        couponIcon: themeState ? couponDark : coupon,
        otpIcon: themeState ? otpDark : otp,
        infoIconIcon: themeState ? infoIconDark : infoIcon,
        sucessIcon:themeState ? sucessHandDark : sucessHand,
        menuOtp:themeState?otpDarkMenu:otpMenu
    };

    return iconList;
}

export default IconListManager;