/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import * as Material from "@mui/material";
import BIcon from "./../../Assets/MobileTheme/BookWater Logo.svg";
import * as FcIcons from "react-icons/fc";
import * as FaIcons from "react-icons/fa";
import { useDispatch } from "react-redux";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction";
import orderSucess from "../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import orderFailed from "../../Assets/newMobileIcon/Booking/Order Failed.svg";
import { useHistory, useLocation } from "react-router-dom";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction";
import { ConsumerRatingState } from "../../Redux/actions/profileAction";
import CustomConsole from "../../coustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function RazorPayStatusDialog(props) {
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const dispatch = useDispatch();
  CustomConsole("---------RazorPayStatusDialog props here---------"+props);
  const history = useHistory();

  //function to handel close function
  const handleClose = () => {
    props.handleCloseRPDialog();
    dispatch(ResetComponentState());
    setTimeout(() => {
      dispatch(GetWalletDetails());
    }, 1000);
    props.handleClose();
    if (pageName !== "subscribe" && pageName !== "subscription") {
      window.innerWidth > 600
        ? history.push("/dashboard/home")
        : history.push("/mobile");
    }
    dispatch(AllOrderOtpPaymentDetails());
    if (!props.error) {
      dispatch(ConsumerRatingState(true));
    }
  };
  const bgcolor = appColors.backGroundGradient;

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img alt="" src={props.error ? orderFailed : orderSucess} />
          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Payment Status: &nbsp;{props.error ? "Failed" : "Successfull"}&nbsp;
            {props.error ? <FaIcons.FaTimes color="red" /> : <FcIcons.FcOk />}
          </Material.Typography>
          {props.orderId ? (
            <Material.Typography
              sx={{
                fontSize: "16px",
                color: appColors.darkGray,
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Order ID: {props.orderId}
            </Material.Typography>
          ) : (
            ""
          )}

          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Amount: ₹{props.amount}
          </Material.Typography>
          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Payment ID: {props.paymentId}
          </Material.Typography>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Material.Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
