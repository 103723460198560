/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import * as toastMessage from "./../../Assets/Constants/ToastMessages";
import { url } from "../apis";
import { GetWalletDetails } from "./paymentMethodAction";
import { GetWaterDropsUser } from "./referAndEarnAction";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import moment from "moment";
import CustomConsole from "../../coustomConsole";
// GetSubscriptionOrderDetails
export const GetSubscriptionOrderDetails = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/getSubscribedOrderDetails`);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUBSCRIPTION_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
//Get all the Packaged Drinking Water Payment Pending Orders
export const GetPdwPaymentPendingOrders = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/pdworders/pending_payment`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PDW_PAYMENT_PENDING,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
//Get all the Water & Sewage Tanker's Payment Pending Orders
export const GetWstPaymentPendingOrders = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/orders/pending_payment`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WST_PAYMENT_PENDING,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//Get all the Packaged Drinking Water Ongoing Trips
export const GetPdwOngoingTrips = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/pdwdeliveries/ongoing`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PDW_ONGOING_TRIPS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//Get all the Water & Sewage Tanker's Ongoing Trips
export const GetWstOngoingTrips = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/deliveries/ongoing`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WST_ONGOING_TRIPS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//Get all the Packaged Drinking Water Completed Trips
export const GetPdwCompletedTrips = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/pdwdeliveries/completed`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PDW_COMPLETED_TRIPS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//Get all the Water & Sewage Tanker's Completed Trips
export const GetWstCompletedTrips = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/deliveries/completed`, {
        params: { pageNumber },
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WST_COMPLETED_TRIPS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//Get all the Packaged Drinking Water All Orders
export const GetPdwAllOrders = (reqObj) => {
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    try {
      dispatch({ type: actionType.FETCH_ORDER_DATA_START });
      let response = await api.post(`OM/pdw/orders`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_PDW_ALL_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        
      }
    } catch (error) {
   
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
      dispatch({
        type: actionType.GET_PDW_ALL_ORDERS,
        payload: error.response.data,
      });
    }
  };
};

//Get all the Water & Sewage Tanker's All Orders
export const GetWstAllOrders = (pageNumber) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get("/OM/orders", { params: { pageNumber } });
      //Error in response (Internet Issue or Inavlid Detaills)
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WST_ALL_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      // CustomConsole(error);
    }
  };
};

export const GetSingleOrderDetails = (order_id) => {
  // CustomConsole("GetDeliveryDetails");
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post(`/OM/pdw/orders`, {
      delivery_from_date: moment().subtract(28, "days").format("YYYY-MM-DD"),
      delivery_to_date: moment().format("YYYY-MM-DD"),
      page_size: 10,
      order_status: "",
      delivery_status: "",
      page_number: 1,
      sort_by: [{ key: "order_id", value: "DESC" }],
      order_ids: [order_id],
    });
    // CustomConsole(response);
    if (response.status === AppMetaData.httpStatus.SUCCESS) {
      dispatch({
        type: actionType.SINGLE_ORDER_DETAILS,
        payload: {
          error: response.data.error,
          msg: "sucess",
          data: response.data.data,
          statusFlag: true,
        },
      });
    } else {
      // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

export const GetOrderDetails = (order_id) => {
  // CustomConsole("GetOrderDetails");
  // CustomConsole(order_id);
  let api = useAxios();
  return async (dispatch) => {
    try {
      // CustomConsole("Before API");
      let response = await api.get(`/OM/orders?order_id=${order_id}`);
      // CustomConsole("After API");
      // CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("-----------------------");
        // CustomConsole(response.data);
        dispatch({
          type: actionType.ORDER_DETAILS,
          payload: {
            error: response.data.error,
            msg: response.data.msg,
            data: response.data.orders[0],
            statusFlag: true,
          },
        });
      } else {
        // CustomConsole("ELSE: Response in GetOrdersDetails Action Error");
      }
    } catch (e) {
      // CustomConsole(e);
    }
  };
};
export const GetInvoice = (order_id) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/PM/invoices/${order_id}/send_email`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_DETAILS,
          payload: {
            error: response.data.error,
            msg: response.data.msg,
            data: response.data,
          },
        });
      } else {
        // CustomConsole("ELSE: Response in GetOrdersDetails Action Error");
      }
    } catch (e) {
      // CustomConsole(e);
    }
  };
};

export const DownloadInvoice = (order_id) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      CustomConsole(`${url}/PM/invoices/${order_id}/download`);
      await fetch(`${url}/PM/invoices/${order_id}/download`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/pdf",
        },
        // responseType: "arrayBuffer",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          CustomConsole("download" + link.href);
          window.open(link.href);
          dispatch({
            type: actionType.DOWNLOAD_INVOICE,
            payload: link.href,
          });
        })
        .catch((error) => {
          CustomConsole("download invoice error : " + error);
        });
    };
  } else {
    toast.dark(toastMessage.TOKEN_INVALID, {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};

export const DownloadInvoice1 = (order_id) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/PM/invoices/${order_id}/download`, {
        method: "GET",
        "Content-Type": "application/pdf",
      });
      CustomConsole(`DownloadInvoice response ${response}`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DOWNLOAD_INVOICE,
          payload: {
            error: response.data.error,
            msg: response.data.msg,
            data: response.data,
          },
        });
      } else {
        // CustomConsole("ELSE: Response in GetOrdersDetails Action Error");
      }
    } catch (e) {
      // CustomConsole(e);
    }
  };
};

// export const DownloadInvoice = (order_id) => {
//   //   CustomConsole("------- (getFiles) getFiles Action--------");
//   let token = localStorage.getItem("token");
//   if (token === "" || token === null) {
//     token = sessionStorage.getItem("token");
//   }
//   // CustomConsole("Token: " + token);
//   if (token !== "") {
//     return async (dispatch) => {
//       await fetch(`PM/invoices/${order_id}/download`, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           // "Content-Type": "application/json"
//           "Content-Type": "image/jpeg",
//           Accept: "application/json",
//         },
//       })
//         .then((response) => response.blob())
//         .then((blob) => {
//           // Create blob link to download
//           const url = window.URL.createObjectURL(new Blob([blob]));
//           const link = document.createElement("a");
//           link.href = url;
//           CustomConsole("getFilesAction" + link.href);
//           dispatch({
//             type: actionType.DOWNLOAD_INVOICE,
//             payload: link.href,
//           });
//         })
//         .catch((error) => {
//           CustomConsole("download invoice error : " + error);
//           // CustomConsole("Error in Action: " + error);
//           // toast.dark(toastMessage.GET_RECENT_ACTIVITIES_ERROR_MESSAGE, {
//           //   position: "bottom-right",
//           //   autoClose: 5000,
//           //   type: "error",
//           // });
//         });
//     };
//   } else {
//     // toast.dark(toastMessage.TOKEN_INVALID, {
//     //   position: "bottom-right",
//     //   autoClose: 5000,
//     //   type: "error",
//     // });
//   }
// };

export const CancelOrder = (reqObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/order/cancel`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_ORDER,
          payload: {
            error: response.data.error,
            msg: response.data.msg,
            data: response.data,
          },
        });
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
        dispatch(GetWalletDetails());
        dispatch(GetWaterDropsUser());
      } else {
        // CustomConsole("ELSE: Response in GetOrdersDetails Action Error");
      }
    } catch (e) {
      dispatch({
        type: actionType.CANCEL_ORDER,
        payload: {
          error: e.response.data.error,
          msg: e.response.data.msg,
          data: e.response.data,
        },
      });
      toast.dark(e.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const RequestReplacementCan = (reqObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/admin/replaceCans/createRequest`,
        reqObj
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REQUEST_REPLACEMENT_CAN,
          payload: {
            error: response.data.error,
            msg: response.data.msg,
          },
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetOrdersDetails Action Error");
      }
    } catch (e) {
      // CustomConsole(e);
      toast.dark(e.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const GetDeliverySlotsList = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/supplierAdmin/deliverySlots/getAvailableSlots`
      );
      CustomConsole("---Delivery slots List---");
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DELIVERY_SLOTS_LIST,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const ReqDeliveryTimeChange = (reqObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/consumer/deliverySlot/requestSlot`,
        reqObj
      );
      CustomConsole("---Delivery slots change---");
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REQ_DELIVERY_TIME,
          payload: response.data,
        });
        if (response.data.msg) {
          if (response.data.error === false) {
            toast.dark(response.data.msg, {
              position: "bottom-right",
              autoClose: 3000,
              type: "success",
            });
          } else {
            toast.dark(response.data.msg, {
              position: "bottom-right",
              autoClose: 3000,
              type: "error",
            });
          }
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

export const ResetPromoComponentState = () => {
  return {
    type: actionType.RESET_PROMO_CODE,
  };
};

export const ResetOrderData = () => {
  return {
    type: actionType.RESET_ORDER_DATA,
  };
};

export const CancelOrderError = () => {
  return {
    type: actionType.SET_CANCEL_ORDER_ERROR,
  };
};
