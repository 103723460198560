/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import React from "react";
import {
  Box,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { styles } from "./profileStyles";
import { useDispatch, useSelector } from "react-redux";
import Link from "@mui/material/Link";
import BillandGstDialog from "../../Components/DialogBoxes/NewProfileDialog/BillNameandGst";
import ChangeEmailDialog from "../../Components/DialogBoxes/NewProfileDialog/changeEmailDialogBox";
import {
  AddNewAdress,
  DeleteUserAddress,
  EditAdress,
  GetUserAdressBook,
  UpdateUserProfile,
} from "../../Redux/actions/settingsAction";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { EditAddressDialogBox } from "../Settings/tabs/myaddressbook/EditDialogBox";
import EmailVerificationInSettings from "../Settings/tabs/myprofile/EmailVerificationInSettings";
import Bookwaterlogo from "../../Assets/AppIcons/BookWaterLogo.svg";
import { UploadFile, getFiles } from "../../Redux/actions/fileHandlingActions";
import { styled } from "@mui/material/styles";
import appColors from "../../Assets/AppTheme/appColors";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import DeleteAccountDialog from "../../Components/DialogBoxes/info/DeleteAccountDialog";
import NotificationInfoDialog from "../../Components/DialogBoxes/info/notificationDialog";
import appMessages from "../../Assets/AppMeta/appMessages";
const Input = styled("input")({
  display: "none",
});
export default function ProfileDetails() {
  const dispatch = useDispatch();
  //For getting Profile details
  const filesStatus = useSelector((state) => state.fileHandler);
  const profileDetails = useSelector((state) => state.userProfile);
  const [fullname, setFullname] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [gsturl, setGsturl] = React.useState("");
  const [verifyEmailBtn, setVerifyEmailBtn] = React.useState(false);
  const [EmailInvoiceClicked, setEmailInvoiceClicked] = React.useState(false);
  //For getting Address details
  const address = useSelector((state) => state.settings);
  // For opeaning and closing Bill and Gst Dialog Box
  const [openBillDetails, setOpenBillDetails] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [addressdata, setAddress] = React.useState({});
  const [openEmailDetails, setOpenEmailDetails] = React.useState(false);
  const [billDialogOpean, setbillDialogOpean] = React.useState("");
  const [FileName, setFileName] = React.useState("");
  const [closeProfileFileName, setCloseProfileFileName] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [notification, setNotificaton] = React.useState(true);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 25,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(20px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 26,
      height: 12,

      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
    // Styling for the text
    "& .MuiSwitch-thumb:after": {
      content: notification ? "'ON'" : "'Off'",
      color: "#000",
      fontSize: 10,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
    },
    // Styling for the text
  }));
  //useeffect to update billing name and gst number
  React.useEffect(() => {
    setFullname(profileDetails?.consumer?.billing_name);
    setGstNumber(profileDetails?.consumer?.gst);
    setGsturl(profileDetails?.consumer?.gst_file_url);
    setNotificaton(profileDetails?.consumer?.enable_notification);
    if (profileDetails?.consumer?.enable_notification) {
      setButtonValue("enable");
    } else {
      setButtonValue("disable");
    }
  }, [profileDetails]);

  const [buttonValue, setButtonValue] = React.useState("enable");

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setButtonValue(newAlignment);
      if (newAlignment === "enable") {
        handelNotificationChange(true);
      } else if (newAlignment === "disable") {
        handelNotificationChange(false);
      }
    }
  };

  //For handling gst and bill name changes
  const handelBillDetails = (value) => {
    setbillDialogOpean(value);
    setOpenBillDetails(true);
  };
  //For handling Email Dialog changes
  const handelEmailClick = () => {
    setOpenEmailDetails(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleUpdateAddressDetails = (details) => {
    // console.log("Edit Address Details: " + JSON.stringify(details));
    // console.log("Is Primary: " + isPrimary);
    dispatch(EditAdress(details));
  };

  const handelFileNameProfile = (e) => {
    const file = e.target.files[0];
    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
      if (allowedExtensions.includes(fileExtension)) {
        dispatch(UploadFile(e.target.files[0]));
        setCloseProfileFileName(true);
      } else {
        toast.dark("Please select a valid image file", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
      }
    }
  };

  React.useEffect(() => {
    if (closeProfileFileName) {
      // console.log(fullname);
      dispatch(
        UpdateUserProfile(fullname, gstNumber, gsturl, filesStatus.fileName)
      );
      setCloseProfileFileName(false);
    }
  }, [filesStatus.fileName]);

  // console.log(filesStatus.filePath);
  React.useEffect(() => {
    if (
      profileDetails.consumer.profile_pic_url !== undefined &&
      profileDetails.consumer.profile_pic_url !== ""
    ) {
      dispatch(getFiles(profileDetails.consumer.profile_pic_url));
      setFileName(profileDetails?.consumer?.profile_pic_url);
    }
  }, [profileDetails.consumer.profile_pic_url]);

  //function to handel notification change
  const handelNotificationChange = (value) => {
    if (value) {
      dispatch(
        UpdateUserProfile(
          fullname,
          gstNumber,
          gsturl,
          FileName,
          true
        )
      );
      setNotificaton(true);
    } else {
      setOpenInfoDialog(true);
    }
  };

  //function to disable notification option
  const handelProceedAction = () => {
    dispatch(
      UpdateUserProfile(
        fullname,
        gstNumber,
        gsturl,
        filesStatus.fileName,
        false
      )
    );
    setNotificaton(false);
    setButtonValue("enable");
    setOpenInfoDialog(false);
  };

  //function to handel on off notification
  const handelInfoDialogClose = (flag) => {
    setOpenInfoDialog(flag);
    setButtonValue("enable");
  };
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: { md: 0, xs: "1rem" },
          }}
        >
          <Box sx={styles.circle}>
            <img
              src={
                filesStatus.filePath === ""
                  ? Bookwaterlogo
                  : filesStatus.filePath
              }
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                display: "flex",
                boxShadow: "0 2px 2px rgba(0, 0, 0, 0.4)",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                marginLeft: "5rem",
                marginTop: "5rem",
              }}
            >
              <InputLabel htmlFor="import-button1">
                <Input
                  id="import-button1"
                  type="file"
                  onChange={(e) => handelFileNameProfile(e)}
                />
                <Tooltip title="Upload">
                  <IconButton component="span">
                    <UploadFileIcon color="white" size={18} />
                  </IconButton>
                </Tooltip>
              </InputLabel>
            </Box>
          </Box>
          <Typography sx={styles.welcomeText}>
            Welcome {profileDetails.user.fullname}
          </Typography>
        </Box>
        <Box>
          <Typography sx={styles.deatailHeading}>Profile Details:</Typography>
          <Typography sx={styles.marginSubHeading}>
            <span style={styles.subHeading}>Name: </span>{" "}
            <span style={styles.subHeading1}>
              {profileDetails.user.fullname}
            </span>
          </Typography>
          <Typography sx={styles.marginSubHeading}>
            <span style={styles.subHeading}>Phone:</span>{" "}
            <span style={styles.subHeading1}>{profileDetails.user.phone}</span>
            {/* <span >{""}{""}{""}    <Link
                            component="button"
                            sx={styles.edit}
                            underline="none"
                            variant="body2"
                            onClick={() => {
                                console.info("I'm a button.");
                            }}
                        >
                            (Edit)
                        </Link></span> */}
          </Typography>
          <Typography sx={styles.marginSubHeading}>
            <span style={styles.subHeading}>Email:</span>{" "}
            <span style={styles.subHeading1}>{profileDetails.user.email}</span>
            {profileDetails.user.is_email_verified === false ? (
              <span>
                {""}
                {""}
                {""}{" "}
                <Link
                  component="button"
                  sx={styles.edit}
                  underline="none"
                  variant="body2"
                  onClick={() => {
                    setVerifyEmailBtn(true);
                  }}
                >
                  (verify)
                </Link>
              </span>
            ) : (
              <span style={styles.edit}>verified</span>
            )}{" "}
            <span>
              {""}
              {""}
              {""}{" "}
              <Link
                component="button"
                sx={styles.edit}
                underline="none"
                variant="body2"
                onClick={() => {
                  handelEmailClick();
                }}
              >
                (Edit)
              </Link>
            </span>
          </Typography>

          <Typography sx={styles.marginSubHeading}>
            <span style={styles.subHeading}>Billing Name:</span>{" "}
            <span style={styles.subHeading1}>
              {profileDetails.consumer?.billing_name !== undefined
                ? profileDetails.consumer.billing_name
                : ""}{" "}
            </span>
            <span>
              {""}
              {""}
              {""}{" "}
              <Link
                component="button"
                sx={styles.edit}
                underline="none"
                variant="body2"
                onClick={() => {
                  handelBillDetails("name");
                }}
              >
                (Edit)
              </Link>
            </span>
          </Typography>
          <Typography sx={styles.marginSubHeading}>
            <span style={styles.subHeading}>GST Number:</span>{" "}
            <span style={styles.subHeading1}>
              {profileDetails.consumer?.gst !== undefined
                ? profileDetails.consumer.gst
                : ""}
            </span>
            <span>
              {""}
              {""}
              {""}{" "}
              <Link
                component="button"
                sx={styles.edit}
                underline="none"
                variant="body2"
                onClick={() => {
                  handelBillDetails("gst");
                }}
              >
                (Edit)
              </Link>
            </span>
          </Typography>
          <Typography
            sx={[
              styles.marginSubHeading,
              { display: "flex", alignItems: "center" },
            ]}
          >
            <span style={styles.subHeading}>Notification :</span> &nbsp;&nbsp;
            {/* <span>
              {""}
              {""}
              {""}{" "}
              <AntSwitch
                onChange={handelNotificationChange}
                defaultChecked={notification}
                inputProps={{ "aria-label": "ant design" }}
              />
            </span> */}
            <span>
              <ToggleButtonGroup
                size="small"
                sx={{
                  height: "1.7rem",
                  width: "1.7rem",
                  width: "100%",
                  bgcolor: buttonValue === "enable" ? "#1BCB22" : "#1BCB22",
                  color: "white",

                  "&:hover": {
                    bgcolor: buttonValue === "enable" ? "#1BCB22" : "gray",
                  },
                }}
                value={buttonValue}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  sx={{
                    bgcolor: buttonValue === "enable" ? "#1BCB22" : "gray",
                    color: "white",
                    "&.Mui-selected": {
                      bgcolor: "#1BCB22",
                      color: "white",
                    },
                    "&:hover": {
                      bgcolor: buttonValue === "enable" ? "#1BCB22" : "gray",
                    },
                  }}
                  value="enable"
                >
                  {buttonValue === "enable" ? "Enabled" : "Enable"}
                </ToggleButton>
                <ToggleButton
                  sx={{
                    bgcolor: buttonValue === "disable" ? "#1BCB22" : "gray",
                    color: "white",
                    "&.Mui-selected": {
                      bgcolor: "#1BCB22",
                      color: "white",
                    },
                    "&:hover": {
                      bgcolor: buttonValue === "disable" ? "#1BCB22" : "gray",
                    },
                  }}
                  value="disable"
                >
                  {buttonValue === "disable" ? "Disabled" : "Disable"}
                </ToggleButton>
              </ToggleButtonGroup>
            </span>
          </Typography>
          <Typography sx={styles.marginSubHeading}>
            <Link
              component="button"
              sx={{ fontSize: ".9rem", color: appColors.Failed }}
              variant="body2"
              onClick={() => {
                setOpenDeleteDialog(!openDeleteDialog);
              }}
            >
              Delete Account
            </Link>
          </Typography>
        </Box>
        <BillandGstDialog
          openBillDetails={openBillDetails}
          setOpenBillDetails={setOpenBillDetails}
          billDialogOpean={billDialogOpean}
        />
        <ChangeEmailDialog
          openEmailDetails={openEmailDetails}
          setOpenEmailDetails={setOpenEmailDetails}
        />
        <EmailVerificationInSettings
          verifyEmailBtn={verifyEmailBtn}
          setVerifyEmailBtn={setVerifyEmailBtn}
          setEmailInvoiceClicked={setEmailInvoiceClicked}
        />
        {openEditDialog && (
          <EditAddressDialogBox
            open={openEditDialog}
            data={address}
            handleClose={handleCloseEditDialog}
            handleUpdateAddressDetails={handleUpdateAddressDetails}
          />
        )}
        <DeleteAccountDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          profileDetails={profileDetails}
        />
        <NotificationInfoDialog
          setOpenInfoDialog={setOpenInfoDialog}
          openInfoDialog={openInfoDialog}
          infoDialogTextHeading={appMessages.notificationTitle}
          infoDialogTextMain={appMessages.notificationMsg}
          handelProceedAction={handelProceedAction}
          handelInfoDialogClose={handelInfoDialogClose}
        />
      </Box>
    </>
  );
}
