/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionTypes, * as actionType from "../actions/actionTypes";

const initialProfileState = { 
  error: true,
  isLoading: true,
  filePath: "",
  msg: "",
};

const getFilesReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILES_DOWNLOAD:
      // console.log("------- (getFilesReducer) File Details--------");
      // console.log(JSON.stringify(action));
      return {
        ...initialProfileState,
        error: action.payload.error,
        filePath: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getFilesReducer;
