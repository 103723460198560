/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import { Box, Grid, Typography, useTheme } from "@mui/material";
import useScreenSize from "../../../Components/Utils/mobileScreen.js";
import React from "react";
import BookingAddressCard from "../../../Components/Cards/SkuCards/BookingAddressCard";
import BookingSelectDateCard from "../../../Components/Cards/SkuCards/BookingSelectDate";
import BookingCardsSku from "../../../Components/Cards/SkuCards/BookingCards";
import CheckOutCardSku from "../../../Components/Cards/SkuCards/checkOutCard";
import Addresscard from "../../../Components/Cards/SkuCards/Addresscard.jsx";
import { AddNewAddressDialogBox } from "../../../Pages/Settings/tabs/myaddressbook/DialogBoxes.js";
import {
  AddNewAdress,
  GetUserAdressBook,
  ResetAddressStatus,
} from "../../../Redux/actions/settingsAction.js";
import { useDispatch, useSelector } from "react-redux";
import DateAndTimeSelectionCard from "../../../Components/DialogBoxes/SkuDialogs/dateSelectionDialog.jsx";
import CrateSelectionDialog from "../../../Components/DialogBoxes/SkuDialogs/CrateSelectionDialog.jsx";
import moment from "moment";
import {
  BlockedDateAction,
  GetProductsByAddress,
  SetSelectedAddress,
} from "../../../Redux/actions/skuBookingAction.js";
import IconListManager from "../../../Assets/AppTheme/appIcons.js";
import failedOrder from "../../../Assets/newMobileIcon/Booking/Order Failed.svg";
import PriceBreakUpCard from "../../../Components/DialogBoxes/SkuDialogs/PriceBreakUpDialogSKU.jsx";
import appColors from "../../../Assets/AppTheme/appColors.js";
import ToastMsgCotainer from "../../../Components/ToastMsg/toastMsg.jsx";
import { ToastMsgState } from "../../../Redux/actions/toastMsg.js";
import appTheme from "../../../Assets/AppTheme/appTheme.js";
import CustomConsole from "../../../coustomConsole.js";
const SkuBookingPage = React.memo(() => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const iconList = IconListManager();
  const isMobile = useScreenSize();
  const toastMsgDetails = useSelector((state) => state.toastMsgDetails);
  const checkOutDetails = useSelector((state) => state.checkOutDetails);

  const SkuDetails = useSelector((state) => state.skuBookDetails);
  //settingsAction
  const addressDetails = useSelector((state) => state.settings);
  //state for storing address ID
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const [addressDetailsID, setAddressDetailsID] = React.useState();
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [infoCard, setInfoCard] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [chooseAdressDialog, setchooseAddressDialog] = React.useState(false);
  const [primaryAddress, setPrimaryAddress] = React.useState({});
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);

  //useState for date selection
  const [currentDate, setCurrentDate] = React.useState(
    moment().tz("Asia/Kolkata").add(1, "days")
  );
  const [samedayDelivery, setSameDayDelivery] = React.useState(false);
  const [showImageFlag, setShowImageFlag] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState();
  const [openInstaAlertDialog, setOpenInstaAlertDialog] = React.useState(false);
  const [openOtpFlag, setOpenOtpFlag] = React.useState(false);
  const [calenderOpen, setcalenderOpen] = React.useState(false);
  const [toastFlag, setToastFlag] = React.useState(false);
  const [toastMsg, setTostMsg] = React.useState("");
  const [blockedDates, setBlockedDates] = React.useState([]);
  const BookingFlag = SkuProductDetails?.productDetails?.products?.length > 0;
  const [extraCharges, setExtraCharges] = React.useState(0);
  const [openCrateSelectionDialod, setOpenCrateSelectionDialog] =
    React.useState(false);
  const [checkOutUi, setCheckOutUi] = React.useState(false);
  const [bookingBlockType, setBookingBlockType] = React.useState([]);
  CustomConsole(openCrateSelectionDialod);

  CustomConsole(SkuProductDetails.selectedAddressId);
  //Useeffect for setting address details on state
  React.useEffect(() => {
    const selectedAddressId = SkuProductDetails.selectedAddressId;
    const storedAddressId = localStorage.getItem("addressId");
    if (
      (!isMobile && storedAddressId === null) ||
      (!isMobile && storedAddressId === undefined)
    ) {
      let AddressData;
      if (addressDetails?.addresses !== undefined) {
        AddressData =
          addressDetails?.addresses.find((item) => item.is_primary === true) ||
          addressDetails?.addresses[0];
        CustomConsole(addressDetails);
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    } else {
      let AddressData;
      if (
        (addressDetails?.addresses !== undefined && storedAddressId === null) ||
        (addressDetails?.addresses !== undefined &&
          storedAddressId === undefined)
      ) {
        AddressData =
          addressDetails?.addresses.find(
            (item) => selectedAddressId === item.address_id
          ) || addressDetails?.addresses[0];
        CustomConsole(addressDetails);
      } else if (addressDetails?.addresses !== undefined) {
        AddressData =
          addressDetails?.addresses.find(
            (item) => storedAddressId == item.address_id
          ) || addressDetails?.addresses[0];
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    }
  }, [addressDetails.addresses]);

  //Closing address dialog if address details added
  const handleAddAddressDialog = () => {
    setAddAddressDialog(false);
  };

  //Function for handling new address added
  const handleAddNewAddress = (details, isPrimary) => {
    dispatch(AddNewAdress(details, isPrimary));
  };

  //function for toggle dialog in mobile view
  const toggleDrawer = (newOpen) => {
    setInfoCard(newOpen);
  };

  //useeffect to set extra charges
  React.useEffect(() => {
    if (
      SkuProductDetails?.productDetails?.admin_settings
        ?.extra_charges_singleday !== undefined
    ) {
      setExtraCharges(
        SkuProductDetails?.productDetails?.admin_settings
          ?.extra_charges_singleday
      );
    }
  }, [SkuProductDetails.productDetails]);

  CustomConsole(SkuProductDetails, "skupageorderdetails");
  //React.useEffect for getting available product details if address id changes or first time
  React.useEffect(() => {
    if (
      !SkuProductDetails.addressFlag &&
      SkuProductDetails.selectedAddressId !== null &&
      SkuProductDetails.selectedAddressId !== undefined &&
      SkuProductDetails.selectedAddressId !== ""
    ) {
      dispatch(
        GetProductsByAddress({
          address_id: SkuProductDetails.selectedAddressId,
          validate_dist_stock: true,
        })
      );
      SkuProductDetails.addressFlag = true;
    }
  }, [SkuProductDetails.selectedAddressId]);

  //For setting error msg flag
  React.useEffect(() => {
    if (SkuDetails?.loading) {
      setShowImageFlag(true);
    }
  }, [SkuDetails?.loading]);

  //useEffect for hitting addressapi
  React.useEffect(() => {
    if (
      !addressDetails.isAddressAdded &&
      SkuProductDetails.selectedAddressId !== null &&
      SkuProductDetails.selectedAddressId !== undefined &&
      SkuProductDetails.selectedAddressId !== ""
    ) {
      dispatch(
        GetProductsByAddress({
          address_id: SkuProductDetails.selectedAddressId,
          validate_dist_stock: true,
        })
      );
      dispatch(ResetAddressStatus());
    }
  }, [addressDetails]);

  //useEffect to get blocked dates and block booking
  React.useEffect(() => {
    let validDates =
      SkuProductDetails?.productDetails?.admin_settings?.valid_dates
        ?.valid_dates;
    if (validDates !== undefined && validDates.length > 0) {
      setBlockedDates(validDates);
      // Function to generate an array of dates for the next 7 days
      const getNext7Days = () => {
        const dates = [];
        let currentDate = moment();
        for (let i = 0; i < 7; i++) {
          dates.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "days");
        }
        return dates;
      };
      // Get the next 7 days
      const next7Days = getNext7Days();
      // Filter out the dates that are not in the valid dates array
      const invalidDates = next7Days.filter(
        (date) => !validDates.includes(date)
      );
      dispatch(BlockedDateAction(invalidDates));
    } else if (
      SkuProductDetails?.productDetails?.admin_settings?.valid_dates
        ?.valid_dates.length === 0
    ) {
      setCurrentDate("");
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: SkuProductDetails?.productDetails?.admin_settings?.valid_dates
            ?.msg,
        })
      );
    }
  }, [SkuProductDetails?.productDetails]);

  //useEffect to set time option
  React.useEffect(() => {
    // Get today's date
    const today = moment().format("YYYY-MM-DD");
    const tommorrow = moment().add(1, "days").format("YYYY-MM-DD");
    if (blockedDates?.length > 0 && currentDate !== undefined) {
      if (
        blockedDates?.length >= 2 &&
        moment(today).isSame(blockedDates[0], "day")
      ) {
        if (moment(tommorrow).isSame(blockedDates[1], "day")) {
          setCurrentDate(blockedDates[1]);
        } else {
          setCurrentDate(blockedDates[1]);
        }
      } else {
        setCurrentDate(blockedDates[0]);
      }
    }
  }, [blockedDates]);
  //useEffect for handling isToday variable as true or false
  React.useEffect(() => {
    const isToday = moment(currentDate).isSame(moment(), "day");
    if (isToday) {
      setSameDayDelivery(isToday);
      setDeliveryType(0);
    } else {
      setSameDayDelivery(isToday);
      setDeliveryType(1);
    }
  }, [currentDate]);

  CustomConsole(addressDetails);
  return (
    <>
      <>
        <Grid
          container
          sx={{
            marginTop: { sm: "4.5rem" },
            paddingRight: "5px",
            paddingBottom: "20px",
            paddingLeft: "5px",
            marginBottom: { sm: "1rem", xs: "3rem" },
            gap: 1,
          }}
        >
          {isMobile ? null : (
            <>
              <Grid item sm={12} md={5}>
                <BookingAddressCard
                  primaryAddress={primaryAddress}
                  setchooseAddressDialog={setchooseAddressDialog}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <BookingSelectDateCard
                  samedayDelivery={samedayDelivery}
                  currentDate={currentDate}
                  setcalenderOpen={setcalenderOpen}
                  extraCharges={extraCharges}
                />
              </Grid>
            </>
          )}
          {BookingFlag && addressDetails?.addresses?.length > 0 && (
            <Grid
              container
              sx={{
                marginTop: ".5rem",
              }}
            >
              <Grid item xs={12}>
                <BookingCardsSku
                  toggleDrawer={toggleDrawer}
                  setCheckOutUi={setCheckOutUi}
                  SkuProductDetails={SkuProductDetails}
                  currentDate={currentDate}
                  infoCard={infoCard}
                  setInfoCard={setInfoCard}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              </Grid>
            </Grid>
          )}
          {SkuProductDetails?.productDetails === undefined && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              {showImageFlag && (
                <>
                  <img
                    src={failedOrder}
                    alt="Bookwater"
                    style={{ width: "13rem" }}
                  />
                  <Typography
                    sx={{
                      fontSize: appTheme.fontSizeMedium,
                      color: appColors.darkGray,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Your location is not serviceable!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: appTheme.fontSizeMedium,
                      color: appColors.darkGray,
                      fontWeight: "500",
                      textAlign: "center",
                      wordWrap: "break-word",
                    }}
                  >
                    Please try changing your location from Edit Address.
                  </Typography>
                </>
              )}
            </Grid>
          )}
        </Grid>
        {isMobile ||
        SkuDetails?.selectedProduct?.products === undefined ||
        SkuDetails?.selectedProduct?.products?.length === 0 ? null : (
          <Box
            sx={{
              background:
                window.innerWidth <= 600
                  ? appColors.checkOutCard
                  : "transparent",
              bottom: 0,
              left: "40%",
              // transform: "translate(-50%, -50%)",
              height: "4.5rem",
              width: "25rem",
              position: "fixed",
            }}
          >
            <CheckOutCardSku pageName="booking" />
          </Box>
        )}
        {chooseAdressDialog && (
          <Addresscard
            setAddress={setAddressDetailsID}
            setOpenEditDialog={setOpenEditDialog}
            openEditDialog={openEditDialog}
            setchooseAddressDialog={setchooseAddressDialog}
            chooseAdressDialog={chooseAdressDialog}
            addAdressDialog={addAdressDialog}
            address={addressDetailsID}
            primaryAddress={primaryAddress}
            setCurrentAddress={setPrimaryAddress}
            setAddAddressDialog={setAddAddressDialog}
          />
        )}
        <AddNewAddressDialogBox
          open={addAdressDialog}
          handleClose={handleAddAddressDialog}
          handleAddNewAddress={handleAddNewAddress}
          setAddAddressDialog={setAddAddressDialog}
        />
        {calenderOpen && (
          <DateAndTimeSelectionCard
            setCurrentDate={setCurrentDate}
            currentDate={currentDate}
            setSameDayDelivery={setSameDayDelivery}
            setDeliveryType={setDeliveryType}
            setOpenInstaAlertDialog={setOpenInstaAlertDialog}
            deliveryType={deliveryType}
            samedayDelivery={samedayDelivery}
            openInstaAlertDialog={openInstaAlertDialog}
            openOtpFlag={openOtpFlag}
            setOpenOtpFlag={setOpenOtpFlag}
            calenderOpen={calenderOpen}
            setcalenderOpen={setcalenderOpen}
            setToastFlag={setToastFlag}
            setTostMsg={setTostMsg}
            extraCharges={extraCharges}
          />
        )}
      </>
      {/* {!isMobile && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={SkuDetails?.pageLoading || checkOutDetails?.pageLoading}
        >
          <Box
            sx={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={BiconLoader}
              alt="Loading..."
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "transparent",
              }}
            />
          </Box>
        </Backdrop>
      )} */}

      {toastMsgDetails.toastContainerFlag ? <ToastMsgCotainer /> : ""}
    </>
  );
});

export default SkuBookingPage;
