/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import * as FcIcons from "react-icons/fc";
import appColors from "../../Assets/AppTheme/appColors";
export default function PageNavigation({
  setPageNumber,
  pageNumber,
  pageList,
}) {
  return (
    <>
      <Material.Box
        sx={{
          marginTop: "16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: { sm: "10px", xs: "10px" },
        }}
      >
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Material.Button
            disabled={pageNumber > 1 ? false : true}
            variant="standard"
            size="small"
            onClick={() => setPageNumber(pageNumber - 1)}
            sx={{
              textTransform: "none",
              color: appColors.darkGray,
              fontWeight: "600",
            }}
          >
            {<FcIcons.FcPrevious />}
            Back
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: appColors.textColorNew,
              color: appColors.white,
              padding: "3px",
              width: "30px",
              height: "30px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber}
          </Material.Typography>
          <Material.Button
            disabled={pageList}
            variant="standard"
            size="small"
            onClick={() => setPageNumber(pageNumber + 1)}
            sx={{
              textTransform: "none",
              color: appColors.darkGray,
              fontWeight: "600",
            }}
          >
            Next
            {<FcIcons.FcNext />}
          </Material.Button>
        </Material.Box>
      </Material.Box>
    </>
  );
}
