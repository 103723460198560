/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../Assets/AppTheme/appColors";

export const styles = {
  walletIconAppbar: {
    backgroundColor: appColors.cardColor,
    width: "100px",
    borderRadius: "10px", // Set the border radius to 0 (no rounding)
    marginLeft: "10px",
    height: "40px",
    fontSize: ".8rem",
    border: "1px solid #fff",
    "&: hover": {
      backgroundColor: appColors.cardColor,
    },
    boxShadow: 2,
  },
  drawerContainer: {
    height: "100%",
    // backgroundColor: "#4dd2ff",
    backgroundColor: appColors.cardColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowY: "auto",
    boxShadow: 4,
  },
  drawerlist: {
    marginTop: "5px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bookwaterLogo: {
    width: "100px",
    height: "40px",
    cursor: "pointer",
    marginTop: "10px",
   
  },
  appBarImage: {
    position: "absolute",
    height: "100%",
    width: "100vw",
    objectFit: "cover",
    zIndex: -1,
  },
  mainDasfboard: {
    display: "flex",
    backgroundColor: appColors.white,
  },
  walletButton: {
    backgroundColor: appColors.cardColor,
    width: "90px",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "1px solid #ffffff",
    height: "30px",
    fontSize: ".7rem",
    "&:hover": {
      backgroundColor: appColors.cardColor,
    },
  },
  BackButton: {
    backgroundColor: appColors.cardColor,
    padding: ".3rem .6rem",
    borderRadius: "10px", // Set the border radius to 0 (no rounding)
    marginLeft: ".8rem",
    height: "1.9rem",
    fontSize: ".7rem",
    boxShadow: 2,
    "&:hover": {
      backgroundColor: appColors.cardColor,
    },
  },

  button: {
    // borderRadius: "20%",
    textTransform: "none",
    // backgroundColor: "white",
    color: "black",
    size: "12px",
    textAlign: "center",
    fontSize: "14px",
    minWidth: "160px",
    minHeight: "40px",
    maxWidth: "140px",
    maxHeight: "40px",
    fontWeight: "bold",
    display: "flex",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#03a1fc",
      color: "white",
    },
  },
  topBarHeading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainpageMain: {
    width: "100vw",
    height: "100vh",
    boxSizin: "border-box",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "2fr 10fr",
    gridTemplateRows: "1fr 10fr",
  },
  leftNav: {
    backgroundColor: "rgb(18, 12, 69)",
    gridColumn: "1/2",
    gridRow: "1/3",
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
  topNav: {
    backgroundColor: "rgb(18, 12, 69)",
    // backgroundColor: "yellow"
  },
  content: {
    overflow: "hidden",
  },
  profile: {
    borderBottom: "1px solid #fff",
    width: "100%",
    height: "17%",
    display: "flex",
  },
  proimg: {
    width: "30%",
  },
  img: {
    borderRadius: "20px",
  },
  leftNavTabs: {
    marginTop: "20px",
    textAlign: "center",
    padding: "10px",
    borderRadius: "10px",
    width: "80%",
    alignSelf: "center",
    color: "#fff",
    backgroundColor: "#4958de",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  leftNavTabst: {
    marginTop: "15px",
    textAlign: "center",
    padding: "10px",
    borderRadius: "10px",
    width: "80%",
    alignSelf: "center",
    color: "#070b30",
    backgroundColor: "#fff",
    cursor: "pointer",
    display: "flex",
  },
  leftNavTabsTxt: {
    marginLeft: "20px",
    fontWeight: "bolder",
  },
  leftNavTabsTxtt: {
    marginLeft: "20px",
    fontWeight: "bolder",
  },
  fullNameLogo: {
    marginLeft: "15px",
  },
  nameLogo: {
    fontSize: "14px",
    fontWeight: "800",
    color: "#fff",
    fontFamily: "sans-serif",
  },
  logoutDiv: {
    marginLeft: "auto",
    marginRight: "10px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
  },
  logout: {
    color: "#000",
    fontWeight: "bolder",
    fontSize: "10px",
    marginTop: "8px",
  },
  uName: {
    fontSize: "14px",
    fontWeight: "bolder",
    color: "#fff",
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  uDetails: {
    fontSize: "12px",
    fontWeight: "bolder",
    color: "#fff",
  },
  profileImg: {
    marginTop: "10px",
    // marginLeft: "30%",
    width: "70px",
    height: "70px",
    borderRadius: "20%",
    textAlign: "center",
  },
  termsAndPolicy: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #d6d6d6",
  },
  termsTxt: {
    color: appColors.textColorNew,
    fontSize: "12px",
    margin: "5px",
  },
  policyTxt: {
    color: appColors.textColorNew,
    fontSize: "12px",
    margin: "5px",
  },
  buttonToggle: {
    borderRadius: "20%",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
    size: "12px",
    textAlign: "center",
    fontSize: "14px",
    minWidth: "160px",
    minHeight: "40px",
    maxWidth: "160px",
    maxHeight: "40px",
    fontWeight: "bold",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "blue",
      color: "white",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "yellow",
    },
  },
  loadingSpinner: {
    paddingTop: "20%",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export function findLink(location, type) {
  let loc = "";
  let i = location.length - 1;
  for (i; i > 0; i--) {
    if (location[i] === "/") {
      break;
    }
    loc += location[i];
  }
  const s = loc.split("").reverse().join("");
  switch (s) {
    case "home":
      return type === "num" ? 0 : "Home";
    case "booknow":
      return type === "num" ? 1 : "Book Now";
    case "myorders":
      return type === "num" ? 2 : "My Orders";
    case "transactions":
      return type === "num" ? 3 : "Transactions";
    case "myaccounts":
      return type === "num" ? 4 : "My Accounts";
    case "deposit":
      return type === "num" ? 5 : "Deposit";
    case "support":
      return type === "num" ? 6 : "Customer Support";
    case "settings":
      return type === "num" ? 7 : "Settings";
    case "subscription":
      return type === "num" ? 8 : "Subscription";
    case "referandearn":
      return type === "num" ? 9 : "Refer & Earn";
    default:
      return "";
  }
}
