import React from "react";
import TextField from "@mui/material/TextField";
import appMeta from "../../Assets/AppMeta/appMeta";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

const CustomInputTextField = (props) => {
  // Default values for isValid and setIsValid
  const [hint, setHint] = React.useState(false);
  const isValidDefault = props.isValid !== undefined ? props.isValid : true;
  const setIsValidDefault =
    props.setIsValid !== undefined ? props.setIsValid : () => {};
  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: appTheme.fontSizeSmall, // Set the desired label color
    },
  };
  const setDataForVariables = (state) => {
    setIsValidDefault(state);
    if (props.isHintRequired) setHint(!state);
  };

  const handleChange = (event) => {
    const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegEx = /^[6-9][0-9]{9}$/;
    const pinCodeRegEx = /^[1-9][0-9]{5}$/;
    const numberRegEx = /^[0-9]*$/;
    const nameValidation = /^[a-zA-Z]/;
    const addressValidation = /^[a-zA-Z0-9]/;
    const data = event.target.value + "";
    console.log(data)
    if (props.type === appMeta.textFieldTypes.PHONE && data.length < 11) {
      props.setValue(data);
      setDataForVariables(phoneRegEx.test(data));
    } else if (
      props.type === appMeta.textFieldTypes.PINCODE &&
      data.length <= 6
    ) {
      props.setValue(data);
      setDataForVariables(pinCodeRegEx.test(data));
    } else if (
      props.type === appMeta.textFieldTypes.FULL_NAME &&
      data.length > 3
    ) {
      props.setValue(data);
      setDataForVariables(nameValidation.test(data));
    } else if (
      props.type === appMeta.textFieldTypes.ADDRESS &&
      data.length > 3
    ) {
      props.setValue(data);
      setDataForVariables(addressValidation.test(data));
    } else if (props.type === appMeta.textFieldTypes.EMAIL) {
      props.setValue(data);
      // setDataForVariables(emailRegEx.test(data));
    } else if (props.type === appMeta.textFieldTypes.NUMBER) {
      props.setValue(data);
      setDataForVariables(numberRegEx.test(data));
    } else if (
      props.type !== appMeta.textFieldTypes.PINCODE &&
      props.type !== appMeta.textFieldTypes.PHONE &&
      props.type !== appMeta.textFieldTypes.NUMBER
    ) {
      props.setValue(data);
      setDataForVariables(false);
    } else {
      // Max Limit Exceedsc
    }
    switch (props.type) {
      case appMeta.textFieldTypes.EMAIL:
        emailRegEx.test(data)
          ? setDataForVariables(true)
          : setDataForVariables(false);
        break;
      case appMeta.textFieldTypes.PHONE:
      case appMeta.textFieldTypes.PINCODE:
        break;
      case appMeta.textFieldTypes.NUMBER:
        break;
      case appMeta.textFieldTypes.EMAIL_PHONE:
        emailRegEx.test(data)
          ? setDataForVariables(true)
          : phoneRegEx.test(data)
          ? setDataForVariables(true)
          : setDataForVariables(false);
        break;
      case appMeta.textFieldTypes.FULL_NAME:
        nameValidation.test(data)
          ? setDataForVariables(true)
          : setDataForVariables(false);
        break;
      case appMeta.textFieldTypes.ADDRESS:
        addressValidation.test(data)
          ? setDataForVariables(true)
          : setDataForVariables(false);
        break;
      default:
        setDataForVariables(props.isRequired && data.length);
        break;
    }
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      value={props.value}
      onChange={handleChange}
      error={!isValidDefault} // Use default value for isValid
      label={props.placeholder}
      helperText={hint ? "Incorrect entry." : ""}
      inputProps={{
        maxLength:
          props.type === "phone" ? 10 : props.type === "pincode" ? 6 : 175,
      }}
      InputProps={{
        sx: {
          bgcolor: appColors.secondaryCard,
          color: appColors.darkGray,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: appColors.darkGray,
          },
        },
      }}
      InputLabelProps={inputLabelProps}
      required={props.isRequired}
    />
  );
};

export default CustomInputTextField;
