/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import appColors from "../../Assets/AppTheme/appColors.js";
import FaqMainComponent from "../FaqNew/FaqMain.jsx";
import { useHistory } from "react-router-dom";
import appTheme from "../../Assets/AppTheme/appTheme.js";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import PrimarySkuButton from "../../Components/Buttons/PrimarySkuButton.jsx";
function Support() {
  const styles = {
    heading: {
      color:
        window.innerWidth > 600 ? appColors.darkBlue : appColors.textColorNew,
      fontWeight: "bold",
      marginBottom: ".5rem",
      fontSize: appTheme.fontSizeSmall,
    },
    headingPhone: {
      color:
        window.innerWidth > 600 ? appColors.darkBlue : appColors.textColorNew,
      fontWeight: "bold",
      fontSize: appTheme.fontSizeSmall,
    },
    emailHeading: {
      color:
        window.innerWidth > 600 ? appColors.darkBlue : appColors.textColorNew,
      fontWeight: "bold",
      fontSize: appTheme.fontSizeSmall,
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
    },
    boxinside: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      borderRadius: "1rem",
      color:
        window.innerWidth > 600 ? appColors.darkBlue : appColors.textColorNew,
      height: "5rem",
      width: "100%",
      backgroundColor: appColors.cardColor,
      // backgroundImage: "url(" + { background } + ")",
      "&:hover": {
        boxShadow: "0px 0px 5px 5px rgb(169,169,169)",
      },
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },
    Emailpaper: {
      color:
        window.innerWidth > 600 ? appColors.darkBlue : appColors.textColorNew,
      width: "100%",
      backgroundColor: appColors.cardColor,
      "&:hover": {
        boxShadow: "0px 0px 5px 5px rgb(169,169,169)",
      },
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "1rem",
    },
    typography: {
      fontSize: appTheme.fontSizeLarge,
      fontWeight: "bold",
      color: "white",
      "&:hover": {
        color: "white",
      },
    },
    content: {
      backgroundColor: appColors.textColorNew,
      color: "white",
      "&:hover": {
        backgroundColor: appColors.textColorNew,
      },
      marginBottom: "1rem",
    },
    contentText: {
      color: appColors.Textgray,
      fontSize: appTheme.fontSizeSmall,
    },
  };
  const history = useHistory();
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const [subjectContent, setsubjectContent] = React.useState("");
  const [bodyContent, setBodyContent] = React.useState("");
  const [qureyReason, setQureyReason] = React.useState([]);

  //Varialble for opeaning mail
  const mailto = "support@bookwater.com";

  //Handel click function for call function
  const handleClick = () => {
    const phoneNumber = "+9180 695 92837"; // Replace with the phone number you want to call
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };

  React.useEffect(() => {
    setUpConfigData.metaData.map((data) => {
      if (data.key === "email_subjects") {
        setQureyReason(data.value);
      }
    });
  }, [setUpConfigData]);
  // Variable for whats app phone number
  const phoneNumber = "919345945085";

  //Handel click function for redirecting whatsapp chat
  const handleWhatsappClick = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray, // Set the desired label color
    },
  };

  return (
    <Box
      sx={{
        marginTop: "5rem",
        marginBottom: { xs: "5rem", sm: "4rem", md: 0 },
      }}
    >
      {/* <Box sx={styles.box}> */}
      <Grid container spacing={{ xs: 2, md: 10 }}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={2} sx={styles.paper}>
                <Box sx={{ marginLeft: ".7rem" }}>
                  <LiveHelpIcon />{" "}
                  <span style={styles.heading}>Frequently Asked Questions</span>
                  <Typography sx={styles.contentText}>
                    What can we help you find?
                  </Typography>
                </Box>
                <Box sx={{ marginRight: ".7rem" }}>
                  <PrimarySkuButton
                    btnName="  Read More"
                    color={appColors.textColorNew}
                    handleButtonClick={() => history.push(`/dashboard/faq`)}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={2} sx={styles.paper}>
                <Box sx={{ marginLeft: ".7rem" }}>
                  <Typography sx={styles.headingPhone}>
                    <CallIcon /> &nbsp;Speak to us
                  </Typography>
                  <Typography sx={styles.contentText}>
                    +9180 695 92837 / WATER{" "}
                  </Typography>
                </Box>
                <Box sx={{ marginRight: ".7rem" }}>
                  <PrimarySkuButton
                    btnName="Call"
                    color={appColors.textColorNew}
                    handleButtonClick={handleClick}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={2}
                sx={styles.paper}
                onClick={() => window.fcWidget.open()}
              >
                <Box sx={{ marginLeft: ".7rem" }}>
                  <ChatIcon />
                  <span style={styles.heading}>
                    {" "}
                    Chat with customer support
                  </span>
                  <Typography sx={styles.contentText}>
                    What can we help you find?
                  </Typography>
                </Box>
                <Box sx={{ marginRight: ".7rem" }}>
                  <PrimarySkuButton
                    btnName="Chat"
                    color={appColors.textColorNew}
                    handleButtonClick={handleClick}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={2} sx={styles.paper}>
                <Box sx={{ marginLeft: ".7rem" }}>
                  <WhatsAppIcon />
                  <span style={styles.heading}> Whatsapp Chat</span>
                  <Typography sx={styles.contentText}>
                    Morning 10 am to 8 pm
                  </Typography>
                </Box>
                <Box sx={{ marginRight: ".7rem" }}>
                  <PrimarySkuButton
                    btnName="Chat"
                    color={appColors.textColorNew}
                    handleButtonClick={handleWhatsappClick}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Card
              elevation={2}
              sx={styles.Emailpaper}
              // onClick={() => window.open(mailto, "_blank")}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "1rem",
                }}
              >
                <EmailIcon />
                <span style={styles.emailHeading}>Mail Support</span>
              </Box>
              <Box sx={{ marginLeft: ".7rem" }}>
                <TextField
                  id="outlined-select-currency"
                  size="small"
                  select
                  label="Select"
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                    bgcolor: appColors.cardColor,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  value={subjectContent}
                  onChange={(e) => setsubjectContent(e.target.value)} // Use the onChange handler to update the state
                >
                  {qureyReason !== undefined &&
                    qureyReason.map((option) => (
                      <MenuItem key={option.v} value={option.v}>
                        {option.v}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  onChange={(e) => setBodyContent(e.target.value)}
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                    bgcolor: appColors.cardColor,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                />
                <Box
                  sx={{
                    marginRight: ".7rem",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    size="small"
                    sx={styles.content}
                    onClick={() => {
                      const mailtoLink = `mailto:${mailto}?subject=${encodeURIComponent(
                        subjectContent
                      )}&body=${encodeURIComponent(bodyContent)}`;
                      window.open(mailtoLink, "_blank");
                    }}
                  >
                    Mail
                  </Button>
                </Box>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {/* // </Box> */}
    </Box>
  );
}

export default Support;
