import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import AuthTextField from "../TextFields/AuthTextFields";
import ReferalTextField from "../TextFields/ReferalTextField";
import AuthPasswordTextField from "../TextFields/AuthPasswordTextField";
import appMeta from "../../Assets/AppMeta/appMeta";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchPdwAvailability,
  signUp,
} from "../../Redux/actions/ProfileActions";
import { AvailablityDialog } from "../DialogBoxes/Authentication/AvailablityDialog";
import { toast } from "react-toastify";
import { ReferalCodeVerify } from "../../Redux/actions/authAction";
import CustomConsole from "../../coustomConsole";


function SignUp() {
  const dispatch = useDispatch();
  const areaAvailabilityList = useSelector(
    (state) => state.pdwAvailabilityReducer
  );
  const authenticationDetails = useSelector((state) => state.authReducer);
  // to get the referal code from the queryparam
  CustomConsole(authenticationDetails)
  const location = useLocation();
  const referalParam = new URLSearchParams(location.search);
  const referalCode = referalParam.get("referrer");
  const [values, setValues] = React.useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    pincode: "",
    referral: referalCode === "" ? "" : referalCode,
  });
  const [isValid, setIsValid] = React.useState({
    name: false,
    phone: false,
    email: false,
    password: false,
    pincode: false,
    referral: true,
  });
  const [isAccepted, setIsAccepted] = React.useState(true);
  const [areaList, setAreaList] = React.useState([]);
  const [selectedArea, setSelectedArea] = React.useState("");
  const [
    openDialogConsumerVerify,
    setOpenDialogConsumerVerify,
  ] = React.useState(false);

  const [signUpBtnFlag, setSignUpBtnFlag] = React.useState(false);
  const [isAreaAvailable, setIsAreaAvailable] = React.useState(false);
  const [consumerReferal, setConsumerReferal] = React.useState("");
  CustomConsole(selectedArea)
  const handleChange = (props) => (event) => {
    setValues({ ...values, [props]: event });
    if (props === "referral") {
      if (event.length > 1) {
        CustomConsole(event);
        dispatch(ReferalCodeVerify(event));
      }
    }
  };
  const handleIsValid = (props) => (event) => {
    setIsValid({ ...isValid, [props]: event });
  };
  const handleChangeServiceArea = (event) => {
    setSelectedArea(event.target.value);
  };
  const handleSignUp = () => {
    // Validate all Fields are proper are not & Proceed For Dispatching the Action
    if (
      isValid.email &&
      isValid.password &&
      isValid.pincode &&
      isValid.name &&
      isAccepted
    ) {
      dispatch(
        signUp({
          fullname: values.name,
          email: values.email,
          pincode: values.pincode,
          areaname: selectedArea,
          password: values.password,
          phone: values.phone,
          phone_code: "91",
        })
      );
      setSignUpBtnFlag(true);
    } else {
      toast.dark("Kindly fill all required fields", {
        position: "bottom-right",
        autoClose: 2500,
        type: "error",
      });
    }
    setConsumerReferal(values.referral);
  };

  React.useEffect(() => {
    if (isValid.pincode) {
      // Dispatch the Pincode API & Get the Service Areas List
      dispatch(searchPdwAvailability({ pincode: values.pincode }));
    }
  }, [isValid.pincode]);

  React.useEffect(() => {
    CustomConsole(authenticationDetails);
    if (authenticationDetails.signUperror === false && signUpBtnFlag === true) {
      setIsAreaAvailable(authenticationDetails.isAvailable);
      setOpenDialogConsumerVerify(true);
      setSignUpBtnFlag(false);
    } else {
      // setOpenDialogConsumerVerify(false);
    }

  }, [authenticationDetails]);



  React.useEffect(() => {
    CustomConsole(areaAvailabilityList);
    setValues({ ...values, referral: referalCode });
    if (areaAvailabilityList.isAvailable) {
      setAreaList(areaAvailabilityList.serviceAreaList);
      setSelectedArea(areaAvailabilityList?.serviceAreaList[0]);
    } else {
      setAreaList([]);
      setSelectedArea("");
    }
  }, [areaAvailabilityList]);

  CustomConsole('BW_WEBSITE_URL:', process.env.PUBLIC_URL);
  return (
    <Box sx={styles.mainContainer}>
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthTextField
          lable={"Full Name"}
          type={appMeta.textFieldTypes.FULL_NAME}
          setValue={handleChange("name")}
          value={values.name}
          isRequired={true}
          isHintRequired={false}
          isValid={isValid.name}
          setIsValid={handleIsValid("name")}
        />
        <AuthTextField
          lable={"Phone Number"}
          type={appMeta.textFieldTypes.PHONE}
          setValue={handleChange("phone")}
          value={values.phone}
          isRequired={true}
          isHintRequired={true}
          isValid={isValid.phone}
          setIsValid={handleIsValid("phone")}
        />
        <AuthTextField
          lable={"Email Address"}
          type={appMeta.textFieldTypes.EMAIL}
          setValue={handleChange("email")}
          value={values.email}
          isRequired={true}
          isHintRequired={true}
          isValid={isValid.email}
          setIsValid={handleIsValid("email")}
        />
        <AuthPasswordTextField
          lable={"Password"}
          type={appMeta.textFieldTypes.PASSWORD}
          isStrongPasswordRequired={false}
          setValue={handleChange("password")}
          value={values.password}
          isValid={isValid.password}
          setIsValid={handleIsValid("password")}
          isRequired={true}
          isHintRequired={false}
        />
        <AuthTextField
          lable={"Pincode"}
          type={appMeta.textFieldTypes.PINCODE}
          setValue={handleChange("pincode")}
          value={values.pincode}
          isRequired={true}
          isHintRequired={true}
          isValid={isValid.pincode}
          setIsValid={handleIsValid("pincode")}
        />
        {isValid.pincode && areaList.length ? (
          <FormControl
            color="success"
            variant="standard"
            sx={{
              backgroundColor: "white",
              fontSize: appTheme.fontSizeLarge,
              borderRadius: "5px",
              width: { sm: "70%", xs: "90%" },
              marginTop: "2%"
            }}
          >
            <InputLabel
              variant="standard"
              id="serviceArea"
              sx={{ marginLeft: "10px" }}
            >
              Service Area
            </InputLabel>
            <Select
              value={selectedArea}
              label="Service Area"
              onChange={handleChangeServiceArea}
              sx={{ marginLeft: "10px" }}
            >
              {areaList.map((data, key) => {
                return (
                  <MenuItem value={data} key={key}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : undefined}

        <ReferalTextField
          lable={"Referral Code"}
          type={appMeta.textFieldTypes.REFERAL_CODE}
          setValue={handleChange("referral")}
          value={values.referral}
          isRequired={false}
          isHintRequired={true}
          isValid={isValid.referral}
          setIsValid={handleIsValid("referral")}
        />

        <Box
          sx={{
            width: { sm: "70%", xs: "90%" },
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: ".5rem"
          }}
        >
          <Checkbox
            checked={isAccepted}
            onClick={() => setIsAccepted(!isAccepted)}
            sx={{ color: isAccepted ? appColors.white : appColors.error }}
            color="default"
          />
          <Typography sx={{ fontSize: appTheme.fontSizeSmall, }}>
            I Accept the{" "}
            <Link
              href={process.env.REACT_APP_BW_TERMS_URl}
              target="_blank"
              underline="always"
              sx={{
                color:appColors.textColorNew,
                textDecoration: 'underline',
              }}
            >
              Terms Conditions
            </Link>{" "}
            &{" "}
            <Link
              href={process.env.REACT_APP_BW_ACCEP_URL}
              target="_blank"
              underline="always"
              sx={{
                color:appColors.textColorNew,
                textDecoration: 'underline',
              }}
            >
              Privacy policy
            </Link>{" "}
            governing the use of{" "}
            <Link
              href="https://www.bookwater.com/"
              target="_blank"
              underline="always"
              sx={{
                color:appColors.textColorNew,
                textDecoration: 'underline',
              }}
            >
              Bookwater.com
            </Link>
          </Typography>
        </Box>
      </Box>
      <PrimaryButton handleButtonClick={handleSignUp} btnName="SignUp" />
      <AvailablityDialog
        setOpenDialogConsumerVerify={setOpenDialogConsumerVerify}
        openDialogConsumerVerify={openDialogConsumerVerify}
        isAreaAvailable={isAreaAvailable}
        values={values}
        consumerReferal={consumerReferal}
      />
    </Box >
  );
}

export default SignUp;
const styles = {
  mainContainer: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
  forgotPasswordContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "70%",
  },
  forgotPassword: {
    fontWeight: "bold",
    color: appColors.blue,
    fontSize: appTheme.textSize,
    "&:hover": {
      color: appColors.white,
    },
  },
};
