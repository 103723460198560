/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import userProfileReducer from "./userProfileReducer";
import recentActivityReducer from "./recentActivityReducer";
import transactionDetailsReducer from "./transactionDetailsReducer";
import filesReducer from "./filesReducer";
import ordersReducer from "./ordersReducer";
import isLoadingData from "./isLoadingReducer";
import settingsReducer from "./settingsReducer";
import myAccountReducer from "./myAccountReducer";
import bookNowReducer from "./bookNowReducer";
import paymentMethodReducer from "./paymentMethodsReducer";
import appMetaDataReducer from "./appMetaDataReducer";
import qrScannerReducer from "./qrScannerReducer";
import getDepositDetailsReducer from "./getDepositHistoryReducer";
import getDepositDataReducer from "./getDepositDetailsReducer";
import getSetUpConfigActionReducer from "./getSetUpConfigActionReducer";
import StartOrderActionReducer from "./startOrderReducer";
import qrQualityReportReducer from "./qrQualityReportReducer";
import pwdListReducer from "./pwdListReducer";
import UPIOrderStatusReducer from "./UPIOrderStatusReducer";
import EditOrderReducer from "./editOrderReducer";
import ReturnCansReducer from "./returnCansReducer";
import GetBannersReducer from "./getBannersReducer";
import getFilesReducer from "./getFilesReducer";
import PromocodeReducer from "./promocodesReducer";
import referAndPromoReducer from "./referAndEarnReducer";
import bankDetailsReducer from "./bankDetailReducer";
import PdwAvailabilityReducer from "./PdwAvailabilityReducer";
import darkThemeReduser from "./darkThemeReduser";
import toastMsgReduser from "./toastMsgReduser";
import skuBookNowReducer from "./skuBookingReducer";
import PaymentReducerSku from "./paymentReduserSku";
import CheckOutReduser from "./checkOutReduser";
import skuOrderPlacingReduser from "./skuOrderPlacingReducer";
import skuSubscribeReduser from "./skuSubscribeReduser";
import spinnerReducer from "./spinner";
import ReturnReplacemtCanReduser from "./returnReplaceOrderReduser";
// import orderOtpReducer from "./getPwdOrderOtpReducer";
const RootReducer = combineReducers({
  appMetaDataDetails: appMetaDataReducer,
  authentications: AuthReducer,
  authReducer: AuthReducer,
  userProfile: userProfileReducer,
  fileHandler: filesReducer,
  getFilesDownload: getFilesReducer,
  recentActivities: recentActivityReducer,
  transactionDetails: transactionDetailsReducer,
  ordersDetails: ordersReducer,
  loadingData: isLoadingData,
  settings: settingsReducer,
  myAccounts: myAccountReducer,
  bookNow: bookNowReducer,
  pwdListRed: pwdListReducer,
  paymentMethods: paymentMethodReducer,
  UPIOrderStatus: UPIOrderStatusReducer,
  qrScannerDetails: qrScannerReducer,
  depositDetails: getDepositDetailsReducer,
  depositData: getDepositDataReducer,
  appMetaConfigData: getSetUpConfigActionReducer,
  startOrderData: StartOrderActionReducer,
  qrQualityReport: qrQualityReportReducer,
  editOrderDetails: EditOrderReducer,
  returnCansDetails: ReturnCansReducer,
  bannersDetails: GetBannersReducer,
  promocodeDetails: PromocodeReducer,
  referAndEarnDetails: referAndPromoReducer,
  bankDetailsIfsc: bankDetailsReducer,
  pdwAvailabilityReducer: PdwAvailabilityReducer,
  darkThemeState: darkThemeReduser,
  toastMsgDetails: toastMsgReduser,
  // orderOtp: orderOtpReducer,
  // viewOrdersDeliveryDetails: orderDeliveryDetailsReducer,
  //Sku booking details
  skuBookDetails: skuBookNowReducer,
  paymentDetailsSku: PaymentReducerSku,
  checkOutDetails: CheckOutReduser,
  skuOrderIdDetails: skuOrderPlacingReduser,
  skuSubscribeOrderDetails: skuSubscribeReduser,
  spinnerReducer: spinnerReducer,
  ReturnReplacemtCanOrder: ReturnReplacemtCanReduser,
});

export default RootReducer;
