/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { SearchAvailability } from "../../Redux/actions/bookNowAction";
import { useDispatch, useSelector } from "react-redux";
import appColors from "../../Assets/AppTheme/appColors";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomInputTextField from "../TextFields/commonTextField";
import appMeta from "../../Assets/AppMeta/appMeta";
import appTheme from "../../Assets/AppTheme/appTheme";
import { AddNewAdress, EditAdress } from "../../Redux/actions/settingsAction";
import CustomConsole from "../../coustomConsole";

export function AddEditAddressComponent({
  handelClose,
  selectedAddress,
  addressModule,
  editAddressData,
  setOpen,
}) {
  const dispatch = useDispatch();
  const bookNowState = useSelector((state) => state.bookNow);
  const profile = useSelector((state) => state.userProfile);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [addressLine3, setAddressLine3] = React.useState("");
  const [pinCode, setPinCode] = React.useState(0);
  const [isValidPincode, setisValidPincode] = React.useState(true);
  const [landMark, setLandMark] = React.useState("");
  const [isValidLandMark, setIsValidLandMark] = React.useState(true);
  const [isValidAddressType, setIsValidAddressType] = React.useState(true);
  const [isValidAddressLine3, setisValidAddressLine3] = React.useState(true);
  const [isValidAddressLine2, setisValidAddressLine2] = React.useState(true);
  const [isValidAddressLine1, setisValidAddressLine1] = React.useState(true);
  const [isValidPhone, setIsValidPhone] = React.useState(true);
  const [isValidName, setIsValidName] = React.useState(true);
  const [serviceArea, setServiceArea] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [isPrimary, setIsPrimary] = React.useState(false);
  const [serviceAreaObj, setServiceAreaObj] = React.useState({
    value: "none",
    message: "",
    error: true,
  });
  const [postOffice, setPostOffice] = React.useState([""]);
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);
  const [addressType, setAddressType] = React.useState("Home");
  const [openAddressType, setOpenAddressType] = React.useState("Home");
  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: appTheme.fontSizeSmall, // Set the desired label color
    },
  };
  //function to add service area onchange
  const onChangeServiceArea = (e) => {
    setServiceAreaObj((prev) => ({
      ...prev,
      value: e.target.value,
      error: false,
    }));
    setServiceArea(e.target.value);
  };

  //useeffect to call service area list
  React.useEffect(() => {
    if (pinCode !== 0 && isValidPincode) {
      setServiceAreaObj((prev) => ({
        ...prev,
        value: "",
        error: false,
      }));
      setServiceArea("");
      dispatch(SearchAvailability(pinCode)).then((resp) => {
        setPostOffice(resp?.data?.data);
      });
    } else if (!isValidPincode) {
      setServiceAreaObj((prev) => ({
        ...prev,
        value: "",
        error: false,
      }));
      setServiceArea("");
      setPostOffice([]);
    }
  }, [pinCode]);

  CustomConsole(selectedAddress+"selected address");
  //function to update address details from map api
  React.useEffect(() => {
    if (selectedAddress?.address_components !== undefined) {
      selectedAddress?.address_components.forEach((data) => {
        if (data.types.includes("postal_code")) {
          setPinCode(data.long_name);
        }
        if (data.types.includes("locality") && addressModule === "add") {
          setAddressLine2(data.long_name);
        }
      });
      setAddressLine1(selectedAddress?.formatted_address);
      setLatitude(selectedAddress?.geometry?.location?.lat);
      setLongitude(selectedAddress?.geometry?.location?.lng);
    }

    // if (selectedAddress?.address !== undefined) {
    //   setPinCode(selectedAddress?.address?.postcode);
    //   setAddressLine1(selectedAddress?.display_name);
    //   setAddressLine2(selectedAddress?.address?.town);
    //   setLatitude(selectedAddress?.lat);
    //   setLongitude(selectedAddress?.lon);
    // }
  }, [selectedAddress]);

  //function to update address details from profile api
  React.useEffect(() => {
    if (profile?.user !== undefined && addressModule === "add") {
      setName(profile?.user?.fullname);
      setPhone(profile?.user?.phone);
    }
  }, [selectedAddress]);

  //function to update address details from profile api
  React.useEffect(() => {
    if (addressModule === "edit" && editAddressData) {
      setName(editAddressData?.contact_name);
      setPhone(editAddressData?.contact_phone);
      // setAddressLine1(editAddressData?.address_line1);
      setAddressLine2(editAddressData?.address_line2);
      setAddressLine3(editAddressData?.address_line3);
      setAddressType(editAddressData?.address_type);
      setLandMark(editAddressData?.landmark);
      setIsPrimary(editAddressData?.is_primary);
      setOpenAddressType(editAddressData?.address_type);
    }
  }, [editAddressData]);

  //function to handel Add Address details action
  const handelSubmitAddAddress = () => {
    if (name === "" || !isValidName) {
      setErrorText("Please enter proper name");
    } else if (phone === "" || !isValidPhone) {
      setErrorText("Please enter proper phone number");
    } else if (addressLine1 === "" || !isValidAddressLine1) {
      setErrorText("Please enter proper Address1 details");
    } else if (pinCode === "" || !isValidPincode) {
      setErrorText("Please enter proper pincode and area");
    } else if (serviceArea === "") {
      setErrorText("Please select proper Service area");
    } else {
      const details = {
        name: name,
        phone: phone,
        address1: addressLine1,
        address2: addressLine2,
        address3: addressLine3,
        landmark: landMark,
        pincode: pinCode,
        serviceArea: serviceArea,
        deliveryTime: 100,
        location: "",
        latitude: latitude,
        longitude: longitude,
        address_type: addressType === "" ? "Home" : addressType,
        isPrimary: isPrimary,
        addressId: editAddressData?.address_id,
      };
      if (addressModule === "add") {
        dispatch(AddNewAdress(details, isPrimary)).then((resp) => {
          handelClose();
          setOpen(false);
        });
      } else if (addressModule === "edit") {
        dispatch(EditAdress(details)).then((resp) => {
          handelClose();
          setOpen(false);
        });
      }
    }
  };

  //function to change delivery typr
  const handelSelectAddressType = (value) => {
    setAddressType(value);
    setOpenAddressType(value);
  };

  //function to set error msg timing to end
  React.useEffect(() => {
    if (errorText !== "") {
      setTimeout(() => {
        setErrorText("");
      }, 3000);
    }
  }, [errorText]);


  console.log(addressLine1)
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: "0 1rem .5rem 1rem",
          background: appColors.secondaryCard,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: appTheme.fontSizeMedium,
              color: appColors.darkGray,
              marginBottom: ".6rem",
            }}
          >
            Mark Address as*
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "100%",
              gap: 2,
            }}
          >
            {/* Primary or Not Check Box */}
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  openAddressType === "Home"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  openAddressType === "Home"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    openAddressType === "Home"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    openAddressType === "Home"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("Home")}
            >
              Home
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  openAddressType === "Work"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  openAddressType === "Work"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    openAddressType === "Work"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    openAddressType === "Work"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("Work")}
            >
              Work
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  openAddressType !== "Home" && openAddressType !== "Work"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  openAddressType !== "Home" && openAddressType !== "Work"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    openAddressType !== "Home" && openAddressType !== "Work"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    openAddressType !== "Home" && openAddressType !== "Work"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("Other")}
            >
              Other
            </Button>
          </Box>
        </Box>
        <CustomInputTextField
          value={name}
          setValue={setName}
          placeholder="Full Name *"
          type={appMeta.textFieldTypes.FULL_NAME}
          setIsValid={setIsValidName}
          isValid={isValidName}
          isHintRequired={true}
        />
        <CustomInputTextField
          value={phone}
          setValue={setPhone}
          placeholder="Phone *"
          type={appMeta.textFieldTypes.PHONE}
          setIsValid={setIsValidPhone}
          isValid={isValidPhone}
          isHintRequired={true}
        />
        <CustomInputTextField
          value={addressLine1}
          setValue={setAddressLine1}
          placeholder="Address Line 1 *"
          type={appMeta.textFieldTypes.ADDRESS}
          setIsValid={setisValidAddressLine1}
          isValid={isValidAddressLine1}
          isHintRequired={true}
        />
        <CustomInputTextField
          value={addressLine2}
          setValue={setAddressLine2}
          placeholder="Address Line 2"
          type={appMeta.textFieldTypes.REFERAL_CODE}
          setIsValid={setisValidAddressLine2}
          isValid={isValidAddressLine2}
          isHintRequired={false}
        />
        <CustomInputTextField
          value={addressLine3}
          setValue={setAddressLine3}
          placeholder="Address Line 3"
          type={appMeta.textFieldTypes.REFERAL_CODE}
          setIsValid={setisValidAddressLine3}
          isValid={isValidAddressLine3}
          isHintRequired={false}
        />
        <CustomInputTextField
          value={landMark}
          setValue={setLandMark}
          placeholder="Landmark"
          type={appMeta.textFieldTypes.REFERAL_CODE}
          setIsValid={setIsValidLandMark}
          isValid={isValidLandMark}
          isHintRequired={false}
        />
        {openAddressType !== "Home" && openAddressType !== "Work" && (
          <CustomInputTextField
            value={addressType}
            setValue={setAddressType}
            placeholder="Address Type"
            type={appMeta.textFieldTypes.REFERAL_CODE}
            setIsValid={setIsValidAddressType}
            isValid={isValidAddressType}
            isHintRequired={false}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CustomInputTextField
            value={pinCode}
            setValue={setPinCode}
            placeholder="Pincode *"
            type={appMeta.textFieldTypes.PINCODE}
            setIsValid={setisValidPincode}
            isValid={isValidPincode}
            isHintRequired={true}
          />
          <FormControl
            size="small"
            variant="outlined"
            sx={{ fontSize: "12px", marginLeft: ".5rem", width: "100%" }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: appColors.darkGray,
                bgcolor: appColors.secondaryCard,
              }}
            >
              Select Area
            </InputLabel>
            <Select
              value={serviceAreaObj.value}
              label="Select Area *"
              disabled={serviceAreaObj.error}
              onChange={onChangeServiceArea}
              sx={{
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    serviceAreaObj.value === "" ? "red" : appColors.darkGray,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: appColors.secondaryCard,
                  },
                },
              }}
            >
              <MenuItem
                disabled
                value="none"
                sx={{
                  fontSize: appTheme.fontSizexl,
                  color: appColors.darkGray,
                  bgcolor: appColors.secondaryCard,
                }}
              >
                Select Service Area *
              </MenuItem>
              {postOffice?.length > 0
                ? postOffice.map((po, key) => (
                    <MenuItem
                      value={po.Name}
                      key={key}
                      sx={{
                        bgcolor: appColors.secondaryCard,
                        color: appColors.darkGray,
                        "&.Mui-selected": {
                          bgcolor: appColors.lightblue,
                          color: "black",
                        },
                        "&.Mui-selected:hover": {
                          bgcolor: appColors.lightblue,
                          color: "black",
                        },
                        "&:hover": {
                          bgcolor: appColors.lightblue,
                          color: "black",
                        },
                      }}
                    >
                      {po.Name}
                    </MenuItem>
                  ))
                : ""}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ backgroundColor: appColors.Failed, borderRadius: ".5rem" }}>
          <Typography
            style={{
              color: appColors.white,
              textAlign: "center",
              fontSize: appTheme.fontSizeMedium,
            }}
          >
            {errorText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            // marginBottom: "1rem",
            flexDirection: "row",
            gap: 1,
            justifyContent: { md: "flex-end", xs: "center" },
          }}
        >
          {/* Primary or Not Check Box */}
          <FormControlLabel
            sx={{ marginTop: "2px", color: appColors.darkGray }}
            control={<Checkbox />}
            label={
              <Typography sx={{ fontSize: appTheme.fontSizexs }}>
                Mark as Primary
              </Typography>
            }
            onChange={() => setIsPrimary(!isPrimary)}
          />
          {/* SubMit Botton */}

          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: appColors.textColorNew,
              width: "40%",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: appColors.textColorNew,
              },
            }}
            onClick={handelSubmitAddAddress}
          >
            Save
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "1rem",
            flexDirection: "row",
            justifyContent: { xs: "center" },
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: appColors.Failed,
              marginTop: "10px",
              "&:hover": {
                backgroundColor: appColors.Failed,
              },
            }}
            onClick={handelClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </>
  );
}
