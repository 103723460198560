/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

export const httpStatus = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
};
export const orderTypes = {
  LONG_TERM_BOOKING: 100,
  SINGLE_BOOKING: 200,
  INSTANT_ORDER: 700,
};
export const orderStatus = {
  NOT_CONFIRMED: 100,
  CONFIRMED: 200,
  REJECTED: 300,
  CANCELLED: 400,
  DELIVERED: 500,
  COMPLETED: 999,
};
export const deliveryStatus = {
  SCHEDULED: 100,
  DELIVERING: 200,
  DELIVERED: 300,
  CANCELLED: 400,
};
export const orderType = {
  LONG_TERM_BOOKING: 100,
  SINGLE_BOOKING: 200,
};
export const orderTerm = {
  SINGLE: 0,
  WEEKLY: 100,
  FORTNIGHTLY: 200,
  MONTHLY: 300,
};
export const tankerType = {
  WATER: 1,
  SEWAGE: 2,
  PACKAGED_DRINKING_WATER: 3,
};
export const paymentStatus = {
  PAID: 0,
  PAYMENT_PENDING: 100,
  PAYMENT_MISMATCH: 200,
  FAILED: 400,
};
export const paymentType = {
  PREPAID: 100,
  CASH_ON_DELIVERY: 200,
  POSTPAID: 300,
};
export const paysharpStatus = {
  PAYSHARP_DISABLE: 0,
  PAYSHARP_ENABLE: 1,
};
export const postpaidStatus = {
  REQUESTED: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const deliveryTimingOptions = {
  SIX_SIX: 100,
  ANY_TIME: 200,
};

export const accountTypes = {
  SAVINGS: 500,
  CURRENT: 600,
};

//Only defined for Web app usage
export const orderOrDeliverCheck = {
  ORDER: 100,
  DELIVERY: 200,
};

export const influencerType = {
  USER_TYPE_INFLUENCER: 111,
  USER_TYPE_CONSUMER: 0,
  USER_TYPE_INFLUENCER_REQUESTED: 110,
  USER_TYPE_INFLUENCER_REJECTED: 112,
  USER_TYPE_INFLUENCER_DISABLED: 113,
};

export const transactionType = {
  WATER_DROP_REFERRER_REWARDED: 1001, //applies for both infulencer and customer referrer rewarded
  WATER_DROP_REFERREE_REWARDED: 1002, //applies only for referree
  WATER_DROP_PROMO_DISCOUNT: 1003,
  WATER_DROP_PDW_ORDER_CONSUMER_PAID: 1004,
  WATER_DROP_CASHBACK_REWARDING: 0,
  WATER_DROP_REFUND_ORDER_CANCEL: 1005,
  ADMIN_ADDED_WATER_DROPS: 1011,
  CAMPAIGN_ADDED_WATER_DROPS: 1111,
  WATERDROPS_REDEMED: 1010,
};

export const screenSize = {
  MOBILE_SCREEN: 600,
};

export const bannerRedirect = {
  HOME: 1,
  BOOK_NOW: 2,
  MY_ORDERS: 3,
  PAYMENTS: 4,
  ACCOUNTS: 5,
  DEPOSITS: 6,
  WALLET: 7,
  SUBSCRIPTION: 8,
  REFER_EARN: 9,
  SUPPORT: 10,
  PROFILE: 11,
};

export const returnCanOrderStatus = {
  CONSUMER_ACCEPTED: 800,
  REFUND: 600,
  CONSUMER_REJECTED: 900,
  DELIVERED: 500,
  REJECTED: 700,
  PENDING: 0,
  APPROVED: 200,
  CANCELLED: 400,
};

export const replacementCanOrdersStatus = {
  CAN_REPLACE_ORDER_REQUESTED: 100,
  CAN_REPLACE_ORDER_ADMIN_ACCEPTED: 200,
  CAN_REPLACE_ORDER_ADMIN_REJECTED: 300,
  CAN_REPLACE_ORDER_STARTED: 400,
  CAN_REPLACE_ORDER_FINISHED: 500,
};
