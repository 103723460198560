/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import ShareIcon from "@mui/icons-material/Share";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import pendingIcon from "../../../Assets/ordersPage/Pending - Selected Menu.svg";
import CardMedia from "@mui/material/CardMedia";
import onGoingIcon from "../../../Assets/ordersPage/Ongoing - Selected Menu.svg";
import Button from "@mui/material/Button";
import { Typography, Box, Link, Backdrop } from "@mui/material";
import { orderTypes } from "../../../Assets/Constants/AppMetaData";
import * as MetaFunctions from "../../../Components/CommonComponents/MetaFunctions";
import * as AppMetaData from "../../../Assets/Constants/AppMetaData";
import BiconLoader from "../../../Assets/Animations/preloader.gif";
import moment from "moment";
import CompletedIcon from "../../../Assets/ordersPage/Completed - Selected Menu.svg";
import cardDesign from "../../../Assets/HeaderBg.svg";
import closeIcon from "../../../Assets/ordersPage/close-64.svg";
import appColors from "../../../Assets/AppTheme/appColors";
import {
  GetPdwAllOrders,
  GetSingleOrderDetails,
} from "../../../Redux/actions/ordersAction";
import zIndex from "@mui/material/styles/zIndex";
import appTheme from "../../../Assets/AppTheme/appTheme";
const styles = {
  orderCardBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "1rem",
  },
  orderCardHearder: {
    height: "4.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // position: "relative",
    overflow: "hidden",
  },
  orderCard: {
    width: { xs: 300 },
    borderRadius: ".9rem",
    boxShadow: 5,
    backgroundColor: appColors.cardColor,
  },
  cardImageStyle: { marginRight: ".5rem", marginLeft: "1rem", width: "2rem" },
  BoxInsideContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  labelDesign: {
    backgroundColor: "white",
    width: "25px",
    height: "9.3rem",
    // zIndex: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(-53deg)",
    // boxShadow: 0.1,
    borderRadius: "0px 10px 10px 10px ",
    overFlow: "hidden",
  },
  labelText: {
    whiteSpace: "nowrap",
    transform: "rotate(90deg)",
    fontSize: appTheme.fontSizexs,
    fontWeight: "bold",
    marginBottom: ".8rem",
    color: "#152057",
    marginRight: ".2rem",
  },
  AddressBox: {},
  TypograpyCommon1: {
    color: appColors.textColorNew,
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  TypograpyCommon2: {
    color: appColors.Textgray,
    fontSize: appTheme.fontSizeSmall,
  },
  payButton: {
    backgroundColor: appColors.blue,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderRadius: ".5rem",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  cancelButton: {
    backgroundColor: appColors.Failed,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderRadius: ".5rem",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  viewButton: {
    backgroundColor:
      window.innerWidth > 600 ? appColors.darkBlue : appColors.themeColor,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderRadius: ".5rem",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  orderFixed: {},
};

export default function OrderDetailsCard({
  orderDetails,
  setOpenOrderDetailsDialog,
  handelPaymentFlag,
  handleCancelOrder,
  handelReorderData,
  pendingFlag,
  setIsOrderPaymentPending,
  shareContent,
}) {
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const dispatch = useDispatch();
  //function for getting background color
  const getOrderCardBackgroundColor = (order) => {
    const { order_status, payment_type, is_settled } = order;
    if (order_status === 400) return "#fe9000";
    if (order_status === 100 || order_status === 300) return appColors.Failed;
    if (payment_type === 300 && !is_settled) return appColors.Failed;
    if (order_status === 500) return appColors.Sucsess;
    return "#152057";
  };
  //function for opening order details dialog box
  const handelDetailClick = (data) => {
    dispatch(GetSingleOrderDetails(data));
    setOpenOrderDetailsDialog(true);
    setIsOrderPaymentPending(false);
  };

  //functon for handling payment details
  const handelPaymentDetails = (data) => {
    handelPaymentFlag(data);
  };

  return (
    <Box sx={styles.orderCardBox}>
      {orderDetails?.orders?.map((data) => (
        <Card sx={styles.orderCard}>
          <Box
            sx={[
              styles.orderCardHearder,
              {
                backgroundColor: getOrderCardBackgroundColor(data),
              },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span>
                <img
                  src={
                    data.order_status === 100
                      ? pendingIcon
                      : data.order_status === 200
                      ? onGoingIcon
                      : data.order_status === 400 || data.order_status === 300
                      ? closeIcon
                      : CompletedIcon
                  }
                  style={styles.cardImageStyle}
                />
              </span>
              <Box sx={styles.BoxInsideContent}>
                <Typography
                  sx={{ color: "white", fontSize: appTheme.fontSizexs }}
                >
                  {data.order_status === 100
                    ? "Payment Failed"
                    : data.order_status === 200
                    ? "Expected Delivery"
                    : data.order_status === 400 || data.order_status === 300
                    ? "Order Cancelled "
                    : data.payment_type === 300 && data.is_settled === false
                    ? "Payment Pending"
                    : "Order Completed On"}
                </Typography>
                <Typography
                  sx={{ color: "white", fontSize: appTheme.fontSizeMedium }}
                >
                  <span></span>
                  {moment(data.delivery_date).format("DD MMM YYYY")}
                </Typography>
                <Typography
                  sx={{ color: "white", fontSize: appTheme.fontSizexs }}
                >
                  {data?.delivery_address?.service_area}{" "}
                  {data?.delivery_address?.pincode}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.labelDesign}>
              <Typography sx={styles.labelText}>
                {" "}
                {data.packaged_drinking_water.order_type ===
                  orderTypes.INSTANT_ORDER && data.order_status !== 100
                  ? "Same Day"
                  : null}
                {data.payment_type === 300 && data.order_status !== 100
                  ? "Post Paid"
                  : null}
                {data.order_status === 100 ? "Pending" : null}
                {data.packaged_drinking_water.is_subscribed_order === true &&
                data.order_status !== 100
                  ? "Subscribed"
                  : null}
                {data.packaged_drinking_water.order_type !==
                  orderTypes.INSTANT_ORDER &&
                data.payment_type !== 300 &&
                data.packaged_drinking_water.is_subscribed_order !== true &&
                data.order_status !== 100
                  ? "Regular"
                  : null}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            </Box>
            {/* {data?.packaged_drinking_water?.twentyltr_capacity_qty > 0 && ( */}
              <Box>
                <Typography
                  sx={styles.labelText}
                  onClick={() => shareContent(data)}
                >
                  <ShareIcon
                    style={{
                      color: appColors.white,
                      transform: "rotate(270deg)",
                    }}
                  />
                </Typography>
              </Box>
            {/* )} */}
          </Box>
          <CardContent
            //   onClick={() => handelDetailClick()}
            sx={{ cursor: "pointer" }}
          >
            <Box
              sx={{
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
              }}
            >
              <Box>
                <Typography sx={styles.TypograpyCommon1}>Products</Typography>
                {data?.packaged_drinking_water?.twentyltr_capacity_qty > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography sx={styles.TypograpyCommon2}>
                        {data?.packaged_drinking_water?.twentyltr_capacity_qty}{" "}
                        x{" "}
                        {data?.packaged_drinking_water?.name === undefined
                          ? data?.packaged_drinking_water
                              ?.selected_container_capacity
                          : data?.packaged_drinking_water?.name}
                      </Typography>
                      {/* <Typography sx={styles.TypograpyCommon2}>
                        Purchasing without crate
                      </Typography> */}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={styles.TypograpyCommon2}>
                        <Typography sx={styles.TypograpyCommon2}>
                          ₹
                          {data?.packaged_drinking_water?.final_price ===
                          undefined
                            ? data?.packaged_drinking_water
                                ?.twenty_ltr_capacity_totamt
                            : data?.packaged_drinking_water?.final_price}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {data?.products?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography sx={styles.TypograpyCommon2}>
                        {data?.products[0].quantity} x{" "}
                        {data?.products[0].name === undefined
                          ? data?.products[0].capacity
                          : data?.products[0].name}
                      </Typography>
                      {/* <Typography sx={styles.TypograpyCommon2}>
                        Purchasing without crate
                      </Typography> */}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={styles.TypograpyCommon2}>
                        ₹ {data?.products[0].final_price}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              {data?.products?.length > 1 && (
                <Link
                  sx={{
                    fontSize: appTheme.fontSizeSmall,
                    color: appColors.headerTextColor,
                  }}
                  onClick={() => handelDetailClick(data?.order_id)}
                >
                  View {data?.products?.length - 1} Products
                </Link>
              )}
            </Box>
            <br />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: ".5rem",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
              }}
            >
              <Box>
                <Typography sx={styles.TypograpyCommon1}>Order Id</Typography>
                <Typography sx={styles.TypograpyCommon2}>
                  {data?.order_id}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography sx={styles.TypograpyCommon1}>
                  Total Price
                </Typography>
                <Typography sx={styles.TypograpyCommon2}>
                  {data?.final_price}
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "center", gap: 1 }}
          >
            {data?.order_status === 200 ||
            data?.order_status === 400 ||
            data?.order_status === 500 ? (
              <>
                <Button
                  variant="contained"
                  sx={styles.payButton}
                  size="small"
                  onClick={() => handelReorderData(data, data.order_id)}
                >
                  <span style={{ fontSize: appTheme.fontSizexs }}>
                    Repeat Order
                  </span>
                </Button>
              </>
            ) : (
              ""
            )}
            {data.payment_type !== 300 && data.order_status === 100 ? (
              <Button
                variant="contained"
                sx={styles.payButton}
                size="small"
                onClick={() => handelPaymentDetails(data)}
              >
                <span style={{ fontSize: appTheme.fontSizexs }}>Pay</span>
              </Button>
            ) : (
              ""
            )}
            <Button
              sx={[styles.viewButton, { backgroundColor: "#152057" }]}
              size="small"
              onClick={() => handelDetailClick(data?.order_id)}
            >
              <span
                style={{
                  color: appColors.white,
                  fontSize: appTheme.fontSizexs,
                }}
              >
                View
              </span>
            </Button>

            {data?.order_status === 200 || data?.order_status === 100 ? (
              <>
                <Button
                  variant="contained"
                  sx={styles.cancelButton}
                  size="small"
                  onClick={() => handleCancelOrder(data)}
                >
                  <span style={{ fontSize: appTheme.fontSizexs }}>Cancel</span>
                </Button>
              </>
            ) : (
              ""
            )}
          </CardActions>
        </Card>
      ))}
    </Box>
  );
}
